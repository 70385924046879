interface HeadlessColumn {
    // TODO: Support JSX/html?
    header: string // 'Column 1',
    accessor: (...any) => any 
    sortAccessor?: (...any) => number // 'col1',
    getDisplayValue?: (...any) => any
    annotations: string[]
}

export type {
  HeadlessColumn,
  HeadlessTableProps,
  HeadlessTableState,
  HeadlessTableSort,
}

export {
    HeadlessTable,
}

interface HeadlessTableSort {
    direction: -1 | 1,
    column: HeadlessColumn
}

interface HeadlessTableSortState<DataType> extends HeadlessTableSort {
    values?: DataType[]
}


interface HeadlessTableProps<DataType> {
    columns: HeadlessColumn[]
    values: DataType[]
    sort?: HeadlessTableSort
}

interface HeadlessTableState<DataType> {
    columns: HeadlessColumn[]
    values: DataType[]
    sort?: HeadlessTableSortState<DataType>

    actions: {
        sort: (column: HeadlessColumn) => HeadlessTableSort
    }
}

function HeadlessTable<DataType>(props: HeadlessTableProps<DataType>): HeadlessTableState<DataType> {
    const { values } = props

    if (props.sort && !props.sort.column) {
        throw new Error('Column has not been correctly supplied!')
    }
    
    return {
        columns: props.columns,
        values: props.values,
        sort: props.sort ? {
            direction: props.sort.direction,
            column: props.sort.column,
            values: values.slice().sort((a, b) => {
                const { sort } = props
                if (sort.direction === -1) {
                    [a, b] = [b, a]
                }

                if (sort.column.sortAccessor) {
                    return sort.column.sortAccessor(sort.column.accessor(a), sort.column.accessor(b))
                }

                const numberSortResult = numberSort(a, b)
                return isNaN(numberSortResult) ? stringSort(a, b) : numberSortResult
            }),
        } : null,
        actions: {
            sort,
        }
    }
    function numberSort(a: DataType, b: DataType): number {
        return props.sort.column.accessor(a) - props.sort.column.accessor(b)
    }

    function stringSort(a: DataType, b: DataType): number {
        return props.sort.column.accessor(a).localeCompare(props.sort.column.accessor(b))
    }

    function sort(column): HeadlessTableSort {
        return {
            // @ts-ignore
            direction: (
                props.sort.column === column ? props.sort.direction * -1 : -1
            ),
            column,
        }

    }

    function getSortFn(sort) {
        return (a, b) => {
            if (sort.direction === -1) {
                [a, b] = [b, a]
            }
            
            return sort.column.sortAccessor ? sort.column.sortAccessor(sort.column.accessor(a), sort.column.accessor(b))
                                            : sort.column.accessor(a) - sort.column.accessor(b)
        }
        
    }

    


}



// function sort(column) {
//     if (props.sortedColumn === column) {
//         sortDirection.value *= -1
//     }
//     else {
//         sortDirection.value = -1
//         // sortedColumn = column
//     }
// }