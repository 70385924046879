<template>
    <canvas
        ref="canvas"
        :width="scaledWidth"
        :height="scaledHeight"
        :style="{
            width: `${width}px`,
            height: `${height}px`,
        }"></canvas>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue'

export default defineComponent({
    props: {
        width: { type: Number as () => number, required: true },
        height: { type: Number as () => number, required: true },
    },
    setup(props) {
        const canvas = ref<HTMLCanvasElement>()
        const devicePixelRatio = window.devicePixelRatio || 1
        
        const scaledWidth = computed(() => props.width * devicePixelRatio)
        const scaledHeight = computed(() => props.height * devicePixelRatio)
        
        onMounted(() => {
            // TODO: Is there cost to doing this after mount?
            canvas.value.getContext('2d').scale(
                devicePixelRatio, // Horizontal scaling
                devicePixelRatio, // Vertical scaling
            )
        })

        return {
            canvas,
            devicePixelRatio,
            scaledWidth,
            scaledHeight,
        }
    }
})
</script>

<style scoped>

</style>
