<template>
    
    <div class="chartContainer">

        <SymbolInput 
            :symbol="inputSymbol"
            @change="onSymbolChanged"
            @commit="updateChart"
        />

        <!-- TODO: Add support for no data -->
        <CustomChart 
            v-if="series?.length"
            :class="{ loading: isLoading }"
            :series="series"
            :width="800"
            :height="600"
            seriesName="Fundamentals"
            tickSize="1d"
        />

    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted, shallowRef } from 'vue'
import CustomChart, { Series } from '@/client/charts/CustomChart.vue'
import SymbolInput from '@/client/components/SymbolInput.vue'
import { fetchStock, fetchSymbolToStockMeta } from '@/client/api'
import { DateTime } from 'luxon'
import Fuse from 'fuse.js'
import { Stock } from '@/common/financials/models'


export default defineComponent({
    components: {
        CustomChart,
        SymbolInput,
    },
    setup() {
        
        const isLoading = ref(true)
        const inputSymbol = ref('SPG')
        const symbols = ref([])
        const stock = ref<Stock>()
        const series = shallowRef<Series[]>([])
        const validSymbolSet = computed(() => new Set(symbols.value.map(s => s.symbol)))
        

        fetchSymbolToStockMeta().then(v => symbols.value = Object.values(v))

        const matchedSymbols = computed(() => {
            return new Fuse(
                symbols.value,
                {
                    keys: [
                        {
                            name: 'symbol',
                            weight: 4,
                        },
                        'company.companyName',
                    ]
                }

            ).search(inputSymbol.value)
            .slice(0, 10)
            .map(v => v.item)
        })

        onMounted(updateChart)

        return {
            isLoading,
            series,
            inputSymbol,
            updateChart,
            matchedSymbols,
            onSymbolChanged,
        }

        async function onSymbolChanged(newSymbol: string) {
            inputSymbol.value = newSymbol
        }

        

        async function updateChart() {
            // const isValidInput = validSymbolSet.value.has(inputSymbol.value)
            const doesCurrentMatch = stock.value && (stock.value.symbol === inputSymbol.value)
            if (doesCurrentMatch) {
                return
            }

            isLoading.value = true
            
            try {
                stock.value = await fetchStock({ symbol: inputSymbol.value })                
                console.log(
                    stock.value.historicalPrices.map(v => ({
                                timestamp: DateTime.fromISO(v.date).toMillis(),
                                open: v.openPrice,
                                high: v.highPrice,
                                low: v.lowPrice,
                                close: v.closePrice,
                            }))
                )
                series.value = [
                    // Candlestick
                    {
                        type: 'candlestick',
                        ticks: stock.value.historicalPrices.map(v => ({
                                timestamp: DateTime.fromISO(v.date).toMillis(),
                                open: v.openPrice,
                                high: v.highPrice,
                                low: v.lowPrice,
                                close: v.closePrice,
                            }))
                    },
                    // {
                    //     // TODO: Add scale padding
                    //     type: 'line',
                    //     scaleGroupId: 'fundamentals',
                    //     ticks: (stock?.value?.financials?.incomeStatementsQuarterly || [])
                    //         .filter(v => v?.supplementalItems?.affoPayoutRatio)
                    //         .map(v => {
                    //             // console.log(v.date, DateTime.fromISO(v.date).toMillis())
                    //             //@ts-ignore
                    //             window.DateTime = DateTime
                    //             return {
                    //                 timestamp: DateTime.fromISO(v.date).toMillis(),
                    //                 value: v.supplementalItems.affoPayoutRatio,
                    //             }
                    //         })
                    // },
                    // {
                    //     type: 'circle',
                    //     scaleGroupId: 'fundamentals',
                    //     ticks: (stock?.value?.financials?.incomeStatementsQuarterly || [])
                    //         .filter(v => v?.supplementalItems?.affoPayoutRatio)
                    //         .map(v => {
                    //             // console.log(v.date, DateTime.fromISO(v.date).toMillis())
                    //             //@ts-ignore
                    //             window.DateTime = DateTime
                    //             return {
                    //                 timestamp: DateTime.fromISO(v.date).toMillis(),
                    //                 value: v.supplementalItems.affoPayoutRatio,
                    //             }
                    //         })
                    // },
                    // {
                    //     type: 'candlestick',
                    //     ticks: await getTicks({
                    //             symbol: 'O',
                    //             start: DateTime.now().minus({ days: 7 }),
                    //             end: DateTime.now(),
                    //             timeframe: '1Min',
                    //         })
                    // },
                    // Line
                    // {
                    //     type: 'line',
                    //     ticks: stock.historicalPrices.map(v => ({
                    //             timestamp: DateTime.fromISO(v.date).toMillis(),
                    //             value: v.closePrice,
                    //         }))
                    // },

                ]
            }
            catch (e) {
                console.error(e)
            }
            finally {
                isLoading.value = false
            }
            
        }

    }
})
</script>

<style scoped>
.chartContainer {
    display: grid;
    justify-content: center;
}
.loading {
    justify-self: left;
    opacity: .75;
}
</style>