import { Vector } from '@/client/render/utils'

export {
    getNextDivisibleBy,
    getPrevDivisibleBy,
    getClosestDivisibleBy,
    isDivisibleBy,
    getValuesBetween,
    getMidpoint,
    getStandardDeviation,
    getSum,
    getSma,
    getMedian,
    
    // TODO: Define more ergonomic API
    computeOverWindow,
    computeMovingAverage,
    computeMovingStddev,
    computeBollingerBands,

    getSubset,
    findClosest,
    findClosestIndex,

    // Experiment with generator
    computeMovingAverageGen,
}

function getClosestDivisibleBy(value, step) {
    const prevDivisible = getPrevDivisibleBy(value, step)
    const nextDivisible = getNextDivisibleBy(value, step)
    const prevDistance = Math.abs(value - prevDivisible)
    const nextDistance = Math.abs(value - nextDivisible)

    return prevDistance <= nextDistance ? prevDivisible : nextDivisible 

}

function getNextDivisibleBy(value, step) {
    // TODO: is this check actually needed or is it implicit on calculation?
    if (isDivisibleBy(value, step)) {
        return value
    }
    const nextDivisibleBy = (Math.floor(value / step) + 1) * step;
    return nextDivisibleBy
}

function getPrevDivisibleBy(value, step) {
    if (isDivisibleBy(value, step)) {
        return value
    }
    let prevDivisibleBy = (Math.floor(value / step)) * step;
    if (prevDivisibleBy < 0) {
        prevDivisibleBy = 0;
    }
    return prevDivisibleBy
}

function isDivisibleBy(value, divisor) {
    return value % divisor === 0;
}

// NOTE: Assumes ticks are evenly spaced
// TODO: Don't rely on above assumption!
function getValuesBetween(startValue, endValue, step) {
    const ticks = [];

    for (let nextTick = startValue; nextTick <= endValue; nextTick += step) {
        ticks.push(nextTick)
    }

    return ticks;
}

// TODO: Make it actually work
function truncateTo(value, decimal) {
    return value.toFixed(decimal);
}

function getMidpoint(from: Vector, to: Vector) {
    return {
        x: (from.x + to.x) / 2,
        y: (from.y, + to.y) / 2,

    }
}

function getStandardDeviation(values) {
    const n = values.length
    const mean = values.reduce((a, b) => a + b) / n
    return Math.sqrt(values.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}

function getSum(values) {
    return values.reduce((a, b) => a + b, 0)
}

function getSma(values) {
    return getSum(values) / values.length   
}


function computeMovingAverage({
    values,
    windowSize,
}) {
    return computeOverWindow({
        values,
        windowSize,
        computeFn(windowedValues) {
            const sum = windowedValues.reduce((a, b) => a + b, 0)
            return (sum / windowSize)
        }
    })
}

function computeMovingStddev({
    values,
    windowSize,
}) {
    return computeOverWindow({
        values,
        windowSize,
        computeFn: getStandardDeviation,
    })
}

function computeBollingerBands({
    values,
    windowSize,
    numberOfStddev = 2
}) {
    const sma = computeMovingAverage({ values, windowSize })
    const stddev = computeMovingStddev({ values, windowSize })
    return computeOverWindow({
        values,
        windowSize,
        computeFn(_, index) {
            return [
                sma[index] - (numberOfStddev * stddev[index]),
                sma[index] + (numberOfStddev * stddev[index])
            ]
        }
    })
}

function computeOverWindow({
    values, // [1, 2, 3, 4, 5]
    windowSize, // 4
    computeFn
}) {
    const windowedValues = []
    const result = []
    let index = 0
    for (const value of values) {
        windowedValues.push(value)

        if (windowedValues.length < windowSize) {
            result.push(null)
        }
        else {
            result.push(
                computeFn(windowedValues, index) // TODO: Not performant, will lead to n^2 problem. Optimize later
            )
            windowedValues.shift()
        }
        index++
    }

    return result
}


function* computeMovingAverageGen({
    values,
    windowSize,
}) {
    yield computeOverWindowGen({
        values,
        windowSize,
        computeFn(windowedValues) {
            const sum = windowedValues.reduce((a, b) => a + b, 0)
            return (sum / windowSize)
        }
    })
}

function* computeOverWindowGen({
    values, // [1, 2, 3, 4, 5]
    windowSize, // 4
    computeFn
}) {
    const windowedValues = []
    const result = []
    let index = 0
    for (const value of values) {
        windowedValues.push(value)

        if (windowedValues.length < windowSize) {
            result.push(null)
        }
        else {
            result.push(
                computeFn(windowedValues, index) // TODO: Not performant, will lead to n^2 problem. Optimize later
            )
            windowedValues.shift()
        }
        yield result
        index++
    }

    return result
}

// TODO: Add may tests
// TODO: Fix padding weirdness
// TODO: Add tests, check for off by 1
function getSubset<T>({
    values, 
    range,
    getComparable, 
    padding = 0
}: {
    values: T[]
    range: number[]
    getComparable: (value: T) => number
    padding?: number
}): T[] {
    if (!values.length) {
        return []
    }

    const [startIndex, endIndex] = range.map(valueToFind => 
                                        findClosestIndex<T>({
                                            values,
                                            valueToFind,
                                            getComparable,
                                        })
                                    )
    
    return values.slice(
        Math.max(startIndex - padding, 0),
        Math.min(endIndex + padding + 1, values.length), // Add 1 to be inclusive
    )
}

function findClosest<T, C = number>(params: {
    values: T[]
    valueToFind: C
    getComparable?: (v: T) => C
}) {
    const closestIndex = findClosestIndex<T, C>(params)
    return params.values[closestIndex]
}

// Assumes sorted ascending
function findClosestIndex<T, C = number>({
    values, 
    valueToFind,
    // @ts-ignore
    getComparable = (v => v),
}: {
    values: T[]
    valueToFind: C
    getComparable?: (v: T) => C
}): number {
    if (!values.length) {
        return null
    }

    let start = 0;
    let end = values.length - 1;
    let mid;

    while (start <= end) {
        mid = Math.floor((start + end) / 2);
        const comparableValue = getComparable(values[mid])

        if (comparableValue === valueToFind) {
            return mid
        }
        if (valueToFind < comparableValue) {
            end = mid - 1
        } else {
            start = mid + 1
        }
    }
    
    // TODO: this check?
    return mid
    // return (mid && getComparable(values[mid]) > valueToFind) ? (mid - 1) : mid 
}

function getMedian(arr) {
    const middle = Math.floor(arr.length / 2)
    arr = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? arr[middle] : (arr[middle - 1] + arr[middle]) / 2;
}
