<template>
    <!-- TODO: Row slots? -->
    <slot 
        :columns="columns" 
        :values="values"
        >
        <!-- :getRowClasses="getRowClasses"
        :getHeaderClasses="getHeaderClasses" -->
    </slot>
</template>

<script lang="ts">
import { computed, ref } from '@vue/reactivity'
import { defineComponent, PropType } from 'vue'

interface HeadlessColumn {
    // TODO: Support JSX/html?
    header: string // 'Column 1',
    accessor: (...any) => any 
    sortAccessor: (...any) => any // 'col1',
    getDisplayValue?: (...any) => any
    annotations: string[]
}

export type {
  HeadlessColumn,
}

export default defineComponent({
  name: 'HeadlessTable',
  components: {},
  props: {
    columns: { type: Array as PropType<HeadlessColumn[]>, required: true, },
    values: { type: Array as PropType<any[]>, required: true },
    sortedColumn: { type: Object as PropType<HeadlessColumn> },
  },
})
</script>

<style>
</style>
