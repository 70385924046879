
import { DateTime } from 'luxon'
import { AlpacaSnapshot, AlpacaCalendar, AlpacaBar } from '@/common/alpacaApi'
import { FuturesQuote } from '@/common/financials/cmeApi'

import {
    CefMeta,
    News,
    PricePoint,
    Stock,
    SymbolToStockMeta,
} from '@/common/financials/models'

import { 
    Institution, 
    InvestmentsHoldingsGetResponse,
} from 'plaid'

import {
    AppUser,
} from '@/common/application/models'

import {
    tryParseJsonResponse,
} from '@/common/metaUtils'

import createAuth0Client from '@auth0/auth0-spa-js'

// @ts-ignore - Temp for testing
window.utils = {
    fetchHistoricalYield,
    fetchStock,
    fetchSymbols,
    fetchSymbolToStockMeta,
    fetchSymbolToLatestTick,
    fetchTicks,
    fetchCalendar,
    fetchNews,
    runBacktest,
    fetchFuturesQuotes,
}

export {
    fetchHistoricalYield,
    fetchStock,
    fetchSymbols,
    fetchSymbolToStockMeta,
    fetchSymbolToCefMeta,
    fetchSymbolToLatestTick,
    fetchFuturesQuotes,
    fetchTicks,
    fetchCalendar,
    fetchNews,
    runBacktest,

    fetchUser,
    updateUser,

    fetchInvestments,
    fetchBroker,
    fetchPlaidLinkToken,
    fetchPlaidAccessToken,
}


async function fetchHistoricalYield({ symbol }): Promise<PricePoint[]> {
    const stock = await fetchStock({ symbol })
    return stock.historicalPrices.reverse() // Ascending
}

// async function fetchStock({ symbol }): Promise<Stock> {
//     return fetch(`https://api.macrotakes.com/data/stocks/${symbol}.json`).then(tryParseJsonResponse)
// }

async function fetchStock({ symbol }): Promise<Stock> {
    return fetch(`/data/stocks/${symbol}.json`).then(tryParseJsonResponse)
}

async function fetchSymbolToCefMeta(): Promise<Record<string, CefMeta>> {
    return fetch(`/data/symbolToCefMeta.json`).then(tryParseJsonResponse)
}

async function fetchFuturesQuotes(): Promise<FuturesQuote[]> {
    return fetch(`/api/futures/quote`).then(tryParseJsonResponse)
}

async function fetchSymbols(): Promise<string[]> {
    return fetch(`/data/symbols.json`).then(tryParseJsonResponse)
}

async function fetchSymbolToStockMeta(): Promise<SymbolToStockMeta> {
    return fetch(`/data/symbolToStockMeta.json`).then(tryParseJsonResponse)
}

async function fetchSymbolToLatestTick(): Promise<Record<string, RawPoint>> {
    return fetch('/api/symbolToLatestTick').then(tryParseJsonResponse)
}

async function fetchTicks({ 
    symbol,
    start,
    end,
    timeframe,
}: {
    symbol: string
    start: DateTime
    end: DateTime,
    timeframe: '1Sec' | '1Min' | '1Hour' | '1Day'
}): Promise<OhlcTick[]> {
    const rawResponse: RawTickResponse = await fetch(`/api/ticks/${symbol}?start=${start.toISO()}&end=${end.toISO()}&timeframe=${timeframe}`)
                                            .then(tryParseJsonResponse)
    return rawResponse.ticks.map(t => ({
        timestamp: new Date(t.t).getTime(),
        open: t.o,
        high: t.h,
        low: t.l,
        close: t.c,
        volume: t.v,
    }))
}

async function fetchCalendar({ 
    start, 
    end,
}: {
    start: DateTime
    end: DateTime
}): Promise<CalendarResponse[]> {
    const calendar: AlpacaCalendar[]  = await fetch(`/api/calendar?start=${start.toISO()}&end=${end.toISO()}`)
                                                .then(tryParseJsonResponse)

    return calendar.map(v => ({
        timestamp: DateTime.fromISO(v.date).toMillis(),
        open: DateTime.fromISO(`${v.date}T${v.open}`).toMillis(),
        close: DateTime.fromISO(`${v.date}T${v.close}`).toMillis(),
    }))
}

async function fetchNews({ 
    symbol 
}: {
    symbol: string
}): Promise<News[]> {
    return fetch(`/api/news/${symbol}`)
            .then(tryParseJsonResponse)
}

function parseBar(bar) {
    return {
        timestamp: bar.t,
        open: bar.o,
        high: bar.h,
        low: bar.l,
        close: bar.c,
        volume: bar.v,
    }
}

interface SnapshotResponse {
    symbol: string
    latestQuote: {
        timstamp: string // Timestamp in RFC-3339 format with nanosecond precision.
        askExchange: string // Ask exchange.
        askPrice: number // Ask price.
        askSize: number // Ask size.
        bidExchange: string // Bid exchange.
        bidPrice: number // Bid price.
        bidSize: number // Bid size.
        conditions?: string[] | null // Quote conditions.
    }
    latestTrade: {
        timestamp: string // Timestamp in RFC-3339 format with nanosecond precision.
        exchange: string // Exchange where the trade happened.
        price: number // Trade price.
        size: number // Trade size.
        id: number // Trade ID.
        tape: string // Tape. TODO: what is tape?
        conditions?: string[] | null // Trade conditions.
    }
    minuteTick: OhlcTick
    dailyTick: OhlcTick
    prevDailyTick: OhlcTick
}

interface CalendarResponse {
    timestamp: number
    open: number
    close: number
}

interface OhlcTick {
    timestamp: number
    open: number
    high: number
    low: number
    close: number
    volume: number
}

// interface TickResponse {
//     symbol: string
//     ticks: OhlcTick[]
// }
interface RawTickResponse {
    symbol: string
    ticks: AlpacaBar[]
}

async function runBacktest({ code }) {
    return fetch('/api/backtest', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            code,
        })
    })
    .then(tryParseJsonResponse)
}

// TODO: Make it rest oriented
async function fetchUser({ userId }): Promise<AppUser | null> {
    const r = await fetch(`/api/user?userId=${encodeURIComponent(userId)}`)

    return r.ok ? (await tryParseJsonResponse(r)) : null
}

async function fetchInvestments({ plaidAccessToken }): Promise<InvestmentsHoldingsGetResponse> {
    return fetch(`/api/broker/investments?plaidAccessToken=${plaidAccessToken}`).then(tryParseJsonResponse)
}

async function updateUser({ user }): Promise<void> {
    await fetch(`/api/user?userId=${encodeURIComponent(user.userId)}`, { 
        method: 'POST',
        body: JSON.stringify(user) // Backend will pick valid values
    })
}

async function fetchPlaidLinkToken({ plaidUserId }): Promise<{ linkToken: string }> {
    // @ts-ignore
    const { link_token } = await fetch(`/api/broker/link?userId=${plaidUserId}`).then(tryParseJsonResponse)
    return {
        linkToken: link_token,
    }
}

async function fetchPlaidAccessToken({ publicToken, plaidUserId, }): Promise<{ plaidAccessToken: string }> {
    return fetch(`/api/broker/exchange?publicToken=${encodeURIComponent(publicToken)}&userId=${encodeURIComponent(plaidUserId)}`).then(tryParseJsonResponse)
}

async function fetchBroker({ plaidAccessToken }): Promise<Institution> {
    return fetch(`/api/broker/get?plaidAccessToken=${plaidAccessToken}`)
            .then(tryParseJsonResponse)
            // @ts-ignore
            .then(r => r.institution) // TODO: Do on the backend
}



// TODO: Don't block unless needed
// https://auth0.com/docs/libraries/auth0-single-page-app-sdk
export const auth = await createAuth0Client({
    domain: 'dev-hjkyk0aw.us.auth0.com',
    client_id: 'SUWkUxX6kQ8XoWivHfVAO6oJsGOB28Ln'
})

interface RawPoint {
    S: string // symbol
    o: number // symbol
    h: number // symbol
    l: number // symbol
    c: number // symbol
    v: number // symbol
    t: string // symbol
}
