import { interpolateNumber, interpolateRgb } from 'd3'

export {
    Tween,
    getMouseCoordinates,
}

export type {
    Scale,
    DragEvent,
    VisibleRange,
    Range,
    Vector,
    RenderNode,
    ShapeEvents,
    CanvasEvent,
    CanvasContext,
    RenderContext,
}

function getMouseCoordinates(event: MouseEvent, canvasElement: HTMLCanvasElement) {
    const canvasRect = canvasElement.getBoundingClientRect();
    return {
        x: event.clientX - canvasRect.left,
        y: event.clientY - canvasRect.top
    }
}


interface Scale {
    (value: number): number;
    invert: (value: number) => number;
    domain: Range;
    range: Range;
}

interface DragEvent {
    startPosition: Vector;
    relativePosition: Vector;
    distanceDragged: Vector;
}

interface Vector {
    x: number;
    y: number;
}

type Range = [number, number];
interface VisibleRange {
    x: Range,
    y: Range,
}
// @ts-ignore
type CanvasContext = CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D
interface RenderContextInfo {
    hitContext: CanvasContext
    // spriteSheetContext: CanvasContext
    hoveredNodeId?: string
    eventState: {
        lastMousedownNodeId: string
        draggingState?: { // For dragging nodes
            startPosition: Vector
            nodeId: string
            hasMoved: boolean
        }
    }

    // TODO: see if we can clean this up, too many disparate maps
    colorToNodeId: Map<string, string>
    
    // TODO: Memory leak
    // Note, data may be stale
    nodeIdToNode: Map<string, RenderNode> 
    removeEventHandlers: () => void
}

// TODO: Should put this on canvas rather than context?
type RenderContext = CanvasRenderingContext2D & {
    __render?: RenderContextInfo
}

interface RenderNode {
    id?: string // Can be used to share properties when referential equality does not match
    type: string
    angle?: number // Expressed in Radians
    events?: ShapeEvents<RenderNode>
    style?: { [key: string]: any }
    children?: RenderNode[]
    // Optional node to represent the mouse interaction area, will not be visually rendered
    // TODO: Define HitNode, slimmer RenderNode
    
    hitArea?: RenderNode
    hitSizeMultiplier?: number // TODO: Simplify, kid of badly designed. Makes it a lot easier, but non-sensical

    // Defines a "transition" object that can be called to start a transitions
    // Not sure it really makes sense to be renderTree aware...
    transition?: Tween // TODO: Should it really be modeled this way?
    __render?: {
        hitDetectionColor?: string
    }

    // Temp for testing
    spriteType?: string
}


interface CanvasEvent {
    x: number
    y: number
    draggingState?: {
        nodeId: string
        startPosition: Vector
        hasMoved: boolean
    }
}

interface ShapeEvents<T extends RenderNode> {
    mousemove?: (event: CanvasEvent, node: T) => any
    mouseenter?: (event: CanvasEvent, node: T) => any
    mouseleave?: (event: CanvasEvent, node: T) => any
    mousedown?: (event: CanvasEvent, node: T) => any
    mouseup?: (event: CanvasEvent, node: T) => any
    click?: (event: CanvasEvent, node: T) => any
    dragStart?: (event: CanvasEvent, node: T) => any
    drag?: (event: DragEvent, node: T) => any
    dragEnd?: (event: DragEvent, node: T) => any
}


/*
    Tween(1, 2) // d3.transition()
        .delay(1000) // .delay(750)
        .duration(1000) // .duration(750)
        .interpolate('linear') // ..ease(d3.easeLinear)
        .start()
        // TODO: events, onStart, onEnd etc
*/
// TODO: Make API more flexible
// TODO: Performance implication of doing this outside render loop?
// May not be smooth due to setInterval triggering at different times than animation
function Tween(startValue: any, endValue: any): Tween {

    const createInterpolateFn = (typeof startValue === 'string') ? interpolateRgb : interpolateNumber
    const state: TweenState<any> = {
        start: startValue,
        end: endValue,
        current: startValue,
        // delay: 0,
        duration: 250, // TODO: throw if not set? Is fluid better than object?
        frameDuration: 1000 / 60,// TODO: Step interval? Default to 60 fps for now
        transitionPoint: 0,
        transitionStartTime: null,
        onTickFn: () => {},
        // @ts-ignore
        interpolateFn: createInterpolateFn(startValue, endValue),
    }

    const transition = {
        duration,
        start,
        onTick, // TODO: Function vs string?
        getState, // TODO: Should this be function?
    }

    return transition

    function duration(value: number) {
        state.duration = value
        return transition
    }

    function start() {
        state.transitionStartTime = Date.now()
        tickTween()
        return transition
    }

    function tickTween() {
        // TODO: Should use requestAnimationFrame most likely
        const timeSinceTransitionStart = Date.now() - state.transitionStartTime
        state.transitionPoint = Math.min(timeSinceTransitionStart / state.duration, 1)

        state.onTickFn(state.current)

        // state.transitionPoint += state.frameDuration
        state.current = state.interpolateFn(state.transitionPoint)
        
        // NOTE: Assumes no pausing. Need to implement delay/pause
        if (state.transitionPoint < 1) {
            requestAnimationFrame(tickTween)
        }
    }

    function onTick(onTickFn) {
        state.onTickFn = onTickFn
        return transition
    }

    function getState() {
        return state
    }

}

interface Tween {
    duration: (value: number) => Tween
    start: () => Tween
    onTick: (onTickFn: (currentValue: any) => any) => Tween // TODO: pass more state
    getState: () => TweenState<any>
}
interface TweenState<T> {
    start: T
    end: T
    current: T
    // delay: 0,
    duration: number
    frameDuration: number
    transitionPoint: number
    transitionStartTime: number | null
    onTickFn: (currentValue: T) => any, // TODO: Pass more state
    interpolateFn: any
}
