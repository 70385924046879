<template>
    <div class="app h-100">
        <div class="row">
            <div
                ref="controls" 
                class="controls col">
            <input 
                type="text"
                :value="symbol"
                @keyup.enter="symbol = ($event.target as HTMLInputElement).value">
            </div>
        </div>

        <div  class="row h-50 mt-2">
            <div
                ref="chartContainer" 
                class="chart col-10">
                <CustomChart
                    v-if="series"
                    :class="{ loading: isLoading }"
                    :width="width"
                    :height="height"
                    :seriesName="seriesName"
                    :series="series"
                    tickSize="1d" />
            </div>

            <div
                ref="news" 
                class="news col p-3 pt-0">
                <div 
                    class="news-item"
                    v-for="article of news"
                    :key="article.url">
                        <a
                            :href="article.url"
                            target="_blank">
                        <img :src="article.image">
                        {{ article.headline }}
                    </a>
                </div>
            </div>
        </div>

        <div class="row h-50">
            <CodeEditor 
                class="col-10"
                :code="code" 
                :onCodeChanged="newCode => code = newCode" />

            <div class="backtestResultsPanel col">
                Backtest Results
                
                <div>
                <button
                    class="backtestRun"
                    @click="runBacktest"
                    >Run</button>
                </div>
                
                <div 
                    v-if="bestBacktestStrategy"
                    class="backtestResults">
                    <div>{{ bestBacktestStrategy.dates.join(' - ') }}</div>
                    <div>{{ bestBacktestStrategy.description }}</div>
                    <div>Return (Annualized) {{ (bestBacktestStrategy.annualizedReturn * 100).toFixed(2) }}%</div>
                    <div>Return (Total %) {{ (bestBacktestStrategy.totalReturn * 100).toFixed(2) }}%</div>
                </div>
            </div>
        </div>
        
        <!-- <div class="stock-list">
            <div
                v-for="stock of stocks"
                :key="stock.symbol">
                <div class="stock">
                    <div>
                    <div>{{ stock.id }}</div>
                    <div class="stock-name">{{ stock.name }}</div>
                    </div>
                    <div></div>
                    <div>
                    <div>{{ stock.yield }}%</div>
                    <div 
                        class="yield-change"
                        :class="[stock.latestChange >= 0 ? 'up' : 'down']">{{ stock.latestChange }}%</div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { fetchStock } from '@/client/api'
import { backtest } from '@/common/backtest/utils'
import CodeEditor from './CodeEditor.vue'

import CustomChart from '@/client/charts/CustomChart.vue'
import { DateTime, Duration } from 'luxon';
import vm from 'vm-browserify'

interface Stock {
    id: string
    name: string
    yield: number
    latestChange: number
}

export default defineComponent({
    name: 'Backtest',
    components: {
        CustomChart,
        CodeEditor,
    },
    data() {
        return {
            symbol: 'SPY',
            stocks: [
                // { id: 'PINE', name: 'Alpine Income', yield: 5.7, latestChange: -0.1 },
                // { id: 'ABR', name: 'Arbor Realty', yield: 7.7, latestChange: 0.15 },
                { symbol: 'O', yield: 5.7, latestChange: -0.1 },
                { id: 'TWO', name: 'ADC', yield: 7.7, latestChange: 0.15 },
                // { id: 'CWH', name: 'Camping World', yield: 5.1, latestChange: -0.04 },
            ] as Stock[],

            seriesName: null,
            series: null,
            news: [
                {
                    "date": "2021-09-24T12:41:31.000-05:00",
                    "relatedSymbols": [
                        "SPY",
                        "IVV",
                        "VOO",
                        "SPLG",
                        "SSO",
                        "UPRO"
                    ],
                    "headline": "S&P 500 Weekly Price Forecast  Stock Markets Recover After Initial Plunge for the Week",
                    "source": "FX Empire",
                    "url": "https://cloud.iexapis.com/v1/news/article/NRSapx8aeq969gJQjnP4Lvj1AHX7jue5FPUILy2SxLW",
                    "summary": "The S&P 500 has fallen significantly during the course of the week only to turn around and show signs of life again. Because of this, we are hanging around a significant uptrend line.",
                    "image": "https://cloud.iexapis.com/v1/news/image/NRSapx8aeq969gJQjnP4Lvj1AHX7jue5FPUILy2SxLW",
                    "hasPaywall": false
                },
                {
                    "date": "2021-09-24T12:40:08.000-05:00",
                    "relatedSymbols": [
                        "SPY",
                        "IVV",
                        "VOO",
                        "SPLG",
                        "SSO",
                        "UPRO"
                    ],
                    "headline": "These are Goldman''s favorite turnaround stories in the energy sector",
                    "source": "CNBC",
                    "url": "https://cloud.iexapis.com/v1/news/article/1RPYxSzUNXUsfwq75LmdjxMZAjrJCkttmZHiekvVD3ii",
                    "summary": "Energy is the S&P 500 sector this year, but it''s coming off years of underperformance. Goldman Sachs has a list of its top turnaround names.",
                    "image": "https://cloud.iexapis.com/v1/news/image/1RPYxSzUNXUsfwq75LmdjxMZAjrJCkttmZHiekvVD3ii",
                    "hasPaywall": false
                },
                // {
                //     "date": "2021-09-24T11:58:37.000-05:00",
                //     "relatedSymbols": [
                //         "SPY",
                //         "IVV",
                //         "VOO",
                //         "SPLG",
                //         "SSO",
                //         "UPRO"
                //     ],
                //     "headline": "Chart Talk: S&P 500 looks to hold onto weekly gains after a difficult start",
                //     "source": "Seeking Alpha",
                //     "url": "https://cloud.iexapis.com/v1/news/article/Anrrf0hL7ytM15psAHSBpaFzpk5uSghPvEefdjiUpXn",
                //     "summary": "No summary available.",
                //     "image": "https://cloud.iexapis.com/v1/news/image/Anrrf0hL7ytM15psAHSBpaFzpk5uSghPvEefdjiUpXn",
                //     "hasPaywall": false
                // }
            ],
            isLoading: false,
            // TODO: Infer width/height from the chart itself?
            width: null,
            height: null,
            backtestResult: null,
            code: `backtest({
    symbols: ['SPY'],
    dates: ['2014-01-01', '2020-01-03'],
    strategy: {
        description: 'SPY - Buy and Hold',
        startingBalance: 10000,
        onTick({ state, buyAll }) {
            if (!state.holdings.length) {
                buyAll({ symbol: 'SPY' })
            }
        }
    },
})`
        }
    },
    watch: {
        symbol() {
            this.updateChart()
        }
    },
    computed: {
        bestBacktestStrategy() {
            // @ts-ignore
            return this.backtestResult ? this.backtestResult.bestStrategy : null  
        },
        controls(): HTMLDivElement {
            // @ts-ignore
            return this.$refs.controls
        },
        chartContainer(): HTMLDivElement {
            // @ts-ignore
            return this.$refs.chartContainer
        },
        // dataRow(): HTMLDivElement {
        //     // @ts-ignore
        //     return this.$refs.dataRow
        // },
    },
    async mounted() {
        this.width = this.chartContainer.clientWidth
        // this.height = 400
        // debugger
        // this.height = this.dataRow.offsetHeight
        this.height = this.chartContainer.offsetHeight
        this.updateChart()
    },
    methods: {
        async runBacktest() {
            this.backtestResult = await vm.runInNewContext(this.code, { 
                async backtest({ symbols, dates, strategy }) {
                    const stocks = await Promise.all(symbols.map(symbol => fetchStock({ symbol })))
                    return backtest({
                        stocks,
                        dates,
                        strategies: [strategy],
                    })
                },
            })


            // this.backtestResult = backtest({ 
            //     stocks,
            //     dates,
            //     strategies,
            // })
        },
        async updateChart() {
            this.isLoading = true
            const start = DateTime.now().minus({ days: 7 })
            const end = DateTime.now()
            // const calendarRegions = (await getCalendar({ start, end }))
            //     .map(v => ({ ...v, start: v.open, end: v.close }))
            //     // Convert trading days to PM and AH
            //     .map(v => ([
            //         // Premarket 4am-9:30am
            //         {
            //             ...v,
            //             start: v.start - Duration.fromObject({ hours: 6, minutes: 30 }).toMillis(),
            //             end: v.start,
            //             color: '#272422',
            //         },

            //         // Afterhours 4pm-8:00pm
            //         {
            //             ...v,
            //             start: v.end,
            //             end: v.end + Duration.fromObject({ hours: 4 }).toMillis(),
            //             color: '#1C2136',
            //         },
            //     ]))
            //     .flat()
        
                // TODO: How can we freeze internally/without consumer requiring?
                this.series = Object.freeze([
                    {
                        type: 'candlestick',
                        ticks: (await fetchStock({ symbol: this.symbol })).historicalPrices.map(v => ({
                            timestamp: DateTime.fromISO(v.date).toMillis(),
                            open: v.openPrice,
                            high: v.highPrice,
                            low: v.lowPrice,
                            close: v.closePrice,
                        }))
        
                        // shouldReplace ? this.series[0].ticks.slice(0, -1).concat(snapshot.minuteTick) : this.series[0].ticks.concat(snapshot.minuteTick),
                    },
                    // {
                    //     type: 'region',
                    //     ticks: calendarRegions
                    // }
                ])
                this.seriesName = this.symbol
                this.isLoading = false
        }

    },
});



// Lower Bollinger
// {
//   type: 'line',
//   ticks: ticks.map((t, i) => {
//       return {
//           timestamp: t.timestamp,
//           value: bollingerBands[i] ? bollingerBands[i][0] : null,
//       }
//   }).filter(v => v.value != null)
// },
// // Upper Bollinger
// {
//   type: 'line',
//   ticks: ticks.map((t, i) => {
//       return {
//           timestamp: t.timestamp,
//           value: bollingerBands[i] ? bollingerBands[i][1] : null,
//       }
//   }).filter(v => v.value != null)
// }, 
// {
//   type: 'annotation',
//   ticks: tradeYieldSpreadBollinger({
//           stocks,
//           startingAmount: 10000,
//           bollingerBands: ticks.map((t, i) => {
//                       return {
//                           date: new Date(t.timestamp),
//                           low: bollingerBands[i] ? bollingerBands[i][0] / 100 : null,
//                           high: bollingerBands[i] ? bollingerBands[i][1] / 100 : null,
//                       }
//                   }),
//         }).annotations.map(a => {
//             return {
//                 timestamp: new Date(a.date).getTime(),
//                 value: a.value * 100,
//                 text: a.descriptionShort,
//             }
//         })
// }
</script>

<style>
@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.75;
    }
}

.chart.loading {
    animation: 150ms fadeOut forwards;
}


.stock {
  display: grid;
  background: rgb(25, 25, 25);
  grid-template-columns: repeat(3, 1fr);
  border-radius: 10px;
  padding: 5px 20px;
}

.stock-list .stock {  
  margin-top: 10px;
}

.stock-name {
  color: rgb(150, 150, 150);
  font-size: 14px;
}

.yield-change {
  font-size: 14px;
}
.yield-change.up {
  color: green;
}

.yield-change.down {
  color: red;
}
.app {
    /* display: grid; */
    /* width: 100vw; */
    /* height: 100vh; */
    /* grid-template-rows: 22px; */
    /* grid-template-rows: 22px calc(60vh - 11px) calc(40vh - 11px); */
}

.news {
    font-size: 10px;
    display: grid;
    align-content: start;
    grid-gap: 10px;
    padding: 0 10px;
    overflow: auto;
    overflow-x: hidden;
}
.news img {
    width: 100%;
    justify-self: center;
}

.news-item {
    display: grid;
    align-items: center;
    grid-gap: 5px;
}

/* .dataRow { */
    /* display: grid;
    grid-template-columns: 85% 15%;
    width: 100vw; */
/* } */

.backtestResultsPanel {
    background: #1A1B26;
    padding: 10px;
    font-size: 10px;
    text-align: center;
}
.backtestResults {
    text-align: left;
}
</style>
