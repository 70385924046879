<template>
    <div class="app h-100">
        <div class="row">
            <div class="container">
                <div 
                    v-for="quote of futures"
                    :key="quote.code"
                    class="row ms-5 py-2">
                    <TickerCard
                        class="m-0 w-25"
                        :symbol="productCodeToInfo[quote.productCode].symbol"
                        :name="productCodeToInfo[quote.productCode].name"
                        :price="quote.last"
                        :prevPrice="quote.previous"
                        :lastUpdated="quote.lastUpdated"
                    />
                </div>
            </div>
            
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onUnmounted } from 'vue'
import { fetchFuturesQuotes, fetchStock, fetchSymbolToCefMeta } from '@/client/api'
import { FuturesQuote } from '@/common/financials/cmeApi'
import TickerCard from '@/client/pages/FuturesPage/TickerCard.vue'

export default defineComponent({
    components: {
        TickerCard,
    },
    setup() {
        const futures = ref<FuturesQuote[]>()

        updateFutures()
        const updatingId = setInterval(updateFutures, 10 * 1000)

        onUnmounted(() => {
            clearInterval(updatingId)
        })
        
        return {
            futures,
            productCodeToInfo: {
                YM: {
                    symbol: 'DOW',
                    name: 'Dow 30',
                },
                ES: {
                    symbol: 'SPY',
                    name: 'S&P 500',
                },
                NQ: {
                    symbol: 'QQQ',
                    name: 'Nasdaq 100',
                },
            },
        }

        async function updateFutures() {
            const futuresQuotes = await fetchFuturesQuotes()
            futures.value = futuresQuotes
        }
    },
})
</script>

<style>

</style>
