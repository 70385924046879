
export {
    addDragEvent,
}

export type {
    Scale,
    DragEvent,
    VisibleRange,
    Range,
    Vector,
    Tick,
    OhlcTick,
    AnnotationTick,
    RegionTick,
}


interface Scale {
    (value: number): number;
    invert: (value: number) => number;
    domain: Range;
    range: Range;
}

interface DragEvent {
    startPosition: Vector;
    relativePosition: Vector;
    distanceDragged: Vector;
}

interface Vector {
    x: number;
    y: number;
}

type Range = [number, number];
interface VisibleRange {
    x: Range,
    y: Range,
}

interface AnnotationTick {
    timestamp: number
    value: number
    text: string
}

interface RegionTick {
    start: number
    end: number
    color?: string
}

interface Tick {
    timestamp: number
    value: number
}

interface OhlcTick {
    timestamp: number
    open: number
    high: number
    low: number
    close: number
}

// TODO: Figure out
type SeriesType = 'line' | 'candlestick' | 'annotation' | 'region'
type SeriesUnit = '%' | ''
interface Series<TickType> {
    type: string
    unit?: SeriesUnit
    ticks: TickType[]
}

interface VisibleSeries<TickType> extends Series<TickType> {
    visibleRange: number[]
}

interface Line extends Series<Tick> {
    type: 'line'
    ticks: Tick[]
}

interface Candlestick extends Series<OhlcTick> {
    type: 'candlestick'
    ticks: OhlcTick[]
}

interface Annotation extends Series<AnnotationTick> {
    type: 'annotation'
    ticks: AnnotationTick[]
}

interface Region extends Series<RegionTick> {
    type: 'region'
    ticks: RegionTick[]
}

// Returns coordinates relative to element's origin
function addDragEvent({ 
    element,
    onDragStart,
    onDrag,
    onDragEnd,
 }: { 
     element: HTMLElement;
     onDragStart?: () => void;
     onDrag?: (event: DragEvent) => void;
     onDragEnd?: () => void;
}) {
    
    element.addEventListener('mousedown', onMousedown);
    

    return () => {
        element.removeEventListener('mousedown', onMousedown);
        /// TODO: also remove mousemove/mouseup. Prevents race condition
    }

    function onMousedown(event: MouseEvent) {
        const startPosition = { x: event.offsetX, y: event.offsetY };
        let isDragging = true;
        
        if (onDragStart) {
            onDragStart();
        }
        window.addEventListener('mousemove', onMousemove); // TODO: Only add after mousedown?
        window.addEventListener('mouseup', onMouseup);

        function onMousemove(event) {
            if (isDragging) {
                
                // TODO: performance implication? Do something better
                const bounds = element.getBoundingClientRect();
        
                const relativePosition = {
                    x: event.clientX - bounds.left,
                    y: event.clientY - bounds.top,
                };

                if (onDrag) {
                    onDrag({
                        startPosition,
                        relativePosition,
                        distanceDragged: {
                            // TODO: Should these really be inverted?
                            x: -(startPosition.x - relativePosition.x),
                            y: -(startPosition.y - relativePosition.y),
                        }
                    });
                }
            }
        }
    
        function onMouseup() {
            isDragging = false;
            window.removeEventListener('mousemove', onMousemove);
            window.removeEventListener('mouseup', onMouseup);
            if (onDragEnd) {
                onDragEnd();
            }
        }
    }
}

// TODO: not performant. Optimize
// Samples elements uniformly in array
function getUniformlySpacedSubset(values, numberOfElements) {
    numberOfElements = Math.min(numberOfElements, values.length)
    const middleIndex = Math.floor(values.length / 2)
    if (numberOfElements === 0) {
        return []
    }
    else if (numberOfElements === 1) {
        return [values[middleIndex]]
    }

    const isEvenNumberOfElements = numberOfElements % 2 === 0
    
    if (isEvenNumberOfElements) {
        const elementsInEachHalf = Math.floor(numberOfElements / 2)
        return [
            ...getUniformlySpacedSubset(values.slice(0, middleIndex), elementsInEachHalf),
            ...getUniformlySpacedSubset(values.slice(middleIndex), elementsInEachHalf)
        ]    
    }

    const firstHalf = values.slice(0, middleIndex)
    const secondHalf = values.slice(middleIndex + 1)
    const elementsInFirstHalf = Math.floor(numberOfElements / 2)
    const elementsInSecondHalf = Math.ceil(numberOfElements / 2) - 1
    return [
        ...getUniformlySpacedSubset(firstHalf, elementsInFirstHalf),
        values[middleIndex],
        ...getUniformlySpacedSubset(secondHalf, elementsInSecondHalf)
    ] 
}
