<template>
    <div 
        ref="chartContainer"
        class="chartContainer"
        :style="{
            position: 'relative',
            width: `${width}px`,
            height: `${height}px`,
        }">
        <DeviceScaledCanvas 
            ref="canvas"
            :width="panels.data.width"
            :height="height"
            :style="{
                position: 'absolute',
                left: 0,
            }"
        />

        <DeviceScaledCanvas 
            ref="crosshairCanvas"
            :width="panels.crosshair.width"
            :height="panels.crosshair.height"
            :style="{
                cursor: 'none',
                position: 'absolute',
                left: 0,
            }"            
            @mouseleave="onMouseLeave"
            @mousemove="onMouseMove"
            @wheel="onMouseWheel"
        />
        

        <DeviceScaledCanvas 
            ref="yAxisCanvas"
            :width="panels.yAxis.width"
            :height="panels.yAxis.height"
            :style="{
                position: 'absolute',
                right: 0,
                cursor: 'n-resize',
            }"            
        />

        <span 
            v-if="seriesName"
            class="legend"
            :style="{
                position: 'absolute',
                display: 'inline',
                left: `10px`,
                top: `10px`,
                background: '#151722',
                padding: `4px`,
                borderRadius: `4px`,
                cursor: 'default',
                fontSize: `12px`,
            }">{{seriesName}}</span>
    </div>
</template>

<script lang="ts">
import { Range, addDragEvent } from './utils'
import constants from './defaultConfig'
import TickSize from './TickSize'
import DeviceScaledCanvas from './DeviceScaledCanvas.vue'

import { scaleLinear, ScaleLinear, scaleTime, ScaleTime } from 'd3-scale'
import * as rt from '@/client/render/renderTree'
import { getMouseCoordinates } from '@/client/render/utils'

import { computed, defineComponent, ref, onMounted, shallowRef, watch, PropType } from 'vue'
import { DateTime } from 'luxon'
import { findClosestIndex, getClosestDivisibleBy } from '@/common/math/utils'
import { groupBy } from 'lodash'


const seriesTypeToFn = {
    circle: {
        getLow: p => p.value,
        getHigh: p => p.value,
        getLatestTime: p => p.timestamp,
    },
    line: {
        getLow: p => p.value,
        getHigh: p => p.value,
        getLatestTime: p => p.timestamp,
    },
    candlestick: {
        getLow: p => p.low,
        getHigh: p => p.high,        
        getLatestTime: p => p.timestamp,
    },
    annotation: {
        getLow: p => p.value,
        getHigh: p => p.value,
        getLatestTime: p => p.timestamp,
    },
    region: {
        getLow: p => null,
        getHigh: p => null,
        getLatestTime: p => p.end,
    },
}

interface AnnotationTick {
    timestamp: number
    value: number
    text: string
}

interface RegionTick {
    start: number
    end: number
    color?: string
}

interface Tick {
    timestamp: number
    value: number
}


type SeriesType = 'circle' | 'line' | 'candlestick' | 'annotation' | 'region'
type SeriesUnit = '%' | ''

interface Circle {
    type: 'circle'
    ticks: Tick[]
    unit?: SeriesUnit
    scaleGroupId?: string
}


interface Line {
    type: 'line'
    ticks: Tick[]
    unit?: SeriesUnit
    scaleGroupId?: string
}

interface Candlestick {
    type: 'candlestick'
    ticks: OhlcTick[]
    unit?: SeriesUnit
    scaleGroupId?: string
}

interface Annotation {
    type: 'annotation'
    ticks: AnnotationTick[]
    unit?: SeriesUnit
    scaleGroupId?: string
}

interface Region {
    type: 'region'
    ticks: RegionTick[]
    unit?: SeriesUnit
    scaleGroupId?: string
}

type Series = Circle | Candlestick | Line | Annotation | Region

// interface Series<TickType = OhlcTick> {
//     type: SeriesType
//     unit?: SeriesUnit
//     ticks: TickType[]
// }

interface OhlcTick {
    timestamp: number
    open: number
    high: number
    low: number
    close: number
}

// interface VisibleSeries<TickType> extends Series<TickType> {
//     visibleRange: number[]
// }

type TickSize = '1m' | '1h' | '1d'
export type {
    SeriesType,
    SeriesUnit,
    Series,
    // Line,
    // Candlestick,
    // Annotation,
    // Region,
    Tick,
    OhlcTick,
    TickSize,
}

// TODO: For now support specific tick sizes. Is that right in longer run?

export default defineComponent({
name: 'CustomChart',
components: {
    DeviceScaledCanvas,
},
props: {
    width: { type: Number as () => number, required: true },
    height: { type: Number as () => number, required: true },
    seriesName: { type: String as () => string, required: true },
    tickSize: { type: String as PropType<TickSize>, required: true },
    series: { type: Array as PropType<Series[]>, required: true },
},
setup(props) {
    const chartContainer = ref<HTMLDivElement>()
    const canvas = ref<typeof DeviceScaledCanvas>()
    const crosshairCanvas = ref<typeof DeviceScaledCanvas>()
    const yAxisCanvas = ref<typeof DeviceScaledCanvas>()

    const mousePosition = ref<{ x: number, y: number }>()

    const tickSize = computed(() => {
        return {
            '1m': TickSize.OneMinute,
            '1h': TickSize.OneHour,
            '1d': TickSize.OneDay,
        }[props.tickSize]
    })

        const panels = computed(() => {
        return {
            canvas: {
                x: 0,
                y: 0,
                width: props.width,
                height: props.height,
            },
            data: {
                x: 0,
                y: 0,
                width: props.width - constants.yAxisWidth,
                height: props.height - constants.xAxisHeight,
            },
            crosshair: {
                x: 0,
                y: 0,
                width: props.width - constants.yAxisWidth,
                height: props.height,
            },
            // yAxis is its own canvas. TODO:
            yAxis: {
                x: 0,//props.width - constants.yAxisWidth,
                y: 0,
                width: constants.yAxisWidth,
                height: props.height,
            },
            xAxis: {
                x: 0,
                y: props.height - constants.xAxisHeight,
                width: props.width,
                height: constants.xAxisHeight,
            }
        }
    })
    
    const latestTime = computed(() => Math.max(...props.series.map(getLatestTime)))


    const visibleXExtent = ref(
        addExtentPadding([ // TODO: Is this best?
            latestTime.value - tickSize.value.defaultWindowSize,
            latestTime.value,
        ])
    )


    // Primary Y Scale - Maps from primarySeries to [0, height]
    // Series Y Scale - Maps from seriesMin, seriesMax to [0, height]
    // Is primary really necessary? 
    // Should it be inferred?
    const visibleYExtent = computed(() => {
        // TODO: is copy/sort faster than linear iterate?
        let minVisibleValue
        let maxVisibleValue
        for (const series of props.series) {
            const [low, high] = getVisibleExtent(series)

            if (low != null && (minVisibleValue == null || low < minVisibleValue)) {
                minVisibleValue = low
            }

            if (high != null && (maxVisibleValue == null || high > maxVisibleValue)) {
                maxVisibleValue = high
            }
        }

        return addExtentPadding([
            minVisibleValue,
            maxVisibleValue,
        ])
    })

    // Stage 1) Compute custom yScale for each series
    // Stage 2) Allow sharing groups, 
    const scaleGroupIdToYScale = computed<ScaleLinear<number, number>[]>(() => {
        const scaleGroupIdToSeries = groupBy(props.series, s => s.scaleGroupId || 'primary')
        
        return Object.fromEntries(
            Object.entries(scaleGroupIdToSeries).map(toScaleGroupEntry)
        )

        function toScaleGroupEntry([scaleGroupId, series]) {
            const domain = series.map(getVisibleExtent).reduce(([minSoFar, maxSoFar], [min, max]) => [Math.min(minSoFar, min), Math.max(maxSoFar, max)], [Infinity, -Infinity])
            return [
                scaleGroupId,
                scaleLinear()
                .domain(
                    addExtentPadding(domain)
                )
                .range([
                    panels.value.data.height,
                    0,
                ])    
            ]
        }
    })

    function getScale(scaleGroupId): ScaleLinear<number, number> {
        return scaleGroupIdToYScale.value[scaleGroupId || 'primary']
    }

    function getVisibleExtent(series) {
        const { getLow, getHigh } = seriesTypeToFn[series.type]
        let minVisibleValue
        let maxVisibleValue
        forEachVisibleTick(series.ticks, tick => {
            const low = getLow(tick)
            const high = getHigh(tick)
            if (low != null && (minVisibleValue == null || low < minVisibleValue)) {
                minVisibleValue = low
            }

            if (high != null && (maxVisibleValue == null || high > maxVisibleValue)) {
                maxVisibleValue = high
            }
        })

        return [
            minVisibleValue,
            maxVisibleValue,
        ]
    }

    const latestVisibleTick = computed<OhlcTick>(() => {
        const ticks = primarySeries.value.ticks
        
        const lastTickIndex = findClosestIndex({
            values: ticks,
            valueToFind: visibleXExtent.value[1],
            getComparable:  v => v.timestamp,
        })

        return ticks[lastTickIndex]
    })
    
    const xScale = computed<ScaleTime<number, number>>(() => {
        return scaleTime()
            .domain(visibleXExtent.value)
            .range([
                0,
                panels.value.data.width,
            ])
    })


    // const yScale = computed<ScaleLinear<number, number>>(() => {
    //     return scaleLinear()
    //         .domain(visibleYExtent.value)
    //         .range([
    //             panels.value.data.height,
    //             0,
    //         ])
    // })

    watch(() => props.series, () => {
        queueDrawChart()
        queueDrawYAxis()
        queueDrawCrosshair()
    })


    // The primary series is the one used for axis and latest tick etc
    // For now, represented as first series supplie
    // TODO: Figure out bes way to handle
    const primarySeries = computed<Candlestick>(() => {
        return props.series[0] as Candlestick
    })

    // @ts-ignore
    const lineSeries = computed<Line[]>(() => props.series.filter(s => s.type === 'line'))
    
    // @ts-ignore
    const circleSeries = computed<Circle[]>(() => props.series.filter(s => s.type === 'circle'))
    
    // @ts-ignore
    const candlestickSeries = computed<Candlestick[]>(() => props.series.filter(s => s.type === 'candlestick'))
    

    const yAxisUnit = computed<SeriesUnit>(() => {
        return primarySeries.value.unit || ''
    })
    
    // NOTE: assumes only one candlestick series. Fix assumption
    const latestTick = computed<OhlcTick>(() => {
        return primarySeries.value.ticks.at(-1)
    })

    const latestVisibleTickInfo = computed(() => {
        const maxValue = Math.max(latestVisibleTick.value.open, latestVisibleTick.value.close)
        const minValue = Math.min(latestVisibleTick.value.open, latestVisibleTick.value.close) 
        const isDownCandle = latestVisibleTick.value.open > latestVisibleTick.value.close
        const latestVisibleTickValue = isDownCandle ? maxValue : minValue
        return {
            value: latestVisibleTickValue,
            y: getScale('primary')(latestVisibleTickValue),
            color: isDownCandle ? constants.theme.downColor : constants.theme.upColor,
        }
    })

    const latestTickInfo = computed(() => {
        // TODO: Support neutral coloring
        const maxValue = Math.max(latestTick.value.open, latestTick.value.close)
        const minValue = Math.min(latestTick.value.open, latestTick.value.close) 
        const isDownCandle = latestTick.value.open > latestTick.value.close
        const latestTickValue = isDownCandle ? maxValue : minValue
        const latestTickY = getScale('primary')(latestTickValue)
        
        return {
            // TODO: Consider height
            color: isDownCandle ? constants.theme.downColor : constants.theme.upColor,
            value: latestTickValue,
            y: latestTickY,
            isAlsoLatestVisible: latestVisibleTick.value === latestTick.value,
            isOutOfBounds: (
                latestTickY < getScale('primary').range()[1] ||
                latestTickY > getScale('primary').range()[0]
            )
        }
    })


    // TODO: Make it actually work
    const closestTime = computed<number>(() => {
        // TODO: use visible? May require computation
        if (!mousePosition.value) {
            return null
        }
        const mousedOverTime = xScale.value.invert(mousePosition.value.x).getTime()
        
        
        return getClosestDivisibleBy(
            mousedOverTime,
            tickSize.value.unitSize,
        )
    })

    
    const queueDrawChart = createQueueDrawCall(drawChart)
    const queueDrawCrosshair = createQueueDrawCall(drawCrosshair)
    const queueDrawYAxis = createQueueDrawCall(drawYAxis)



    onMounted(async () => {
        let initialState;
        addDragEvent({ 
            element: crosshairCanvas.value.$el,
            onDragStart: () => {
                initialState = {
                    visibleXExtent: visibleXExtent.value,
                    xScale: xScale.value,
                }
            },
            onDrag: (event) => {
                visibleXExtent.value = initialState.visibleXExtent.map((v, i) => (
                    initialState.xScale.invert(
                        initialState.xScale.range()[i] - event.distanceDragged.x
                    ).getTime()
                ))
                queueDrawChart()
                queueDrawYAxis()
            },
        })

        chartContainer.value.insertBefore(canvas.value.$el, chartContainer.value.firstChild)
        
        queueDrawChart()
        queueDrawYAxis()
    })

    return {
        chartContainer,
        canvas,
        crosshairCanvas,
        yAxisCanvas,

        panels,
        onMouseLeave,
        onMouseMove,
        onMouseWheel,
    }

    function getLatestTime(series: Series) {
        const { getLatestTime } = seriesTypeToFn[series.type]
        const lastTick = series.ticks.at(-1)
        return lastTick ? getLatestTime(lastTick) : -Infinity
    }

    function forEachVisibleTick(ticks, fn: Function): void {

        let [startIndex, endIndex] = visibleXExtent.value.map(valueToFind => 
            findClosestIndex({
                values: ticks,
                valueToFind,
                // @ts-ignore
                getComparable:  v => v.timestamp,
            })
        )

        // Adding padding to ensure ticks run up to edge 
        // (TODO: Should be based on width of the object really, but this works without much adverse perf)
        startIndex = Math.max(startIndex - 2, 0)
        endIndex = Math.min(endIndex + 2, ticks.length)
        for (let i = startIndex; i < endIndex; i++) {
            fn(ticks[i], i)
        }
    // TODO: Filter out anything with no actual elements
    //   .filter(v => v.ticks.length)
    }

    function onMouseMove(event) {
        mousePosition.value = getMouseCoordinates(
                                event,
                                canvas.value.$el,
                            )
        queueDrawCrosshair()
        queueDrawYAxis()
    }

    function onMouseWheel(event) {

        preventDefault(event)
        event.stopPropagation()
        
        // Remove 10% of zoom on zoom
        const isScrollUp = event.deltaY >= 0;
        const zoomStep = isScrollUp ? -0.1 : 0.1;
        const visibleWidth = visibleXExtent.value[1] - visibleXExtent.value[0];
        
        visibleXExtent.value = [
            // Zooming only adjusts left side of domain atm
            visibleXExtent.value[0] + (visibleWidth * zoomStep),
            visibleXExtent.value[1],
        ]
        
        // TODO: will computed prop happen before this?
        queueDrawChart()
        queueDrawYAxis()
    }

    function onMouseLeave() {
        mousePosition.value = null
        queueDrawCrosshair()
        queueDrawYAxis()
    }
    
    function drawCrosshair() {
        const renderTree = rt.container([
            CrossHair(),
        ])
        
        rt.draw(
            renderTree, 
            crosshairCanvas.value.$el.getContext('2d', { alpha: false }) // TODO: Alpha false?
        )
    }

    
    function drawYAxis() {
        const renderTree = rt.container([
            YAxis(),
        ])
        
        rt.draw(
            renderTree, 
            yAxisCanvas.value.$el.getContext('2d', { alpha: false }) // TODO: Alpha false?
        )
    }

    
function drawChart() {
    // TODO: Does creating new context every time cause performance issues
    // TODO: Support "redux-like" mechanism for consistent state between draws. Will allow more efficient re-rendering 
    const renderTree = rt.container([
        Background(),
        Regions(),
        Grid(),
        TickBars(),
        Lines(),
        Circles(),
        Annotations(),
        XAxis(),
        // Latest Value Line
        rt.line({
            from: { x: 0, y: latestTickInfo.value.y },
            to: { x: panels.value.data.width, y: latestTickInfo.value.y },
            style: {
                lineDash: [1, 2],
                strokeStyle: latestTickInfo.value.color,
            }    
        }),

    ])                   
    
    rt.draw(
        renderTree, 
        canvas.value.$el.getContext('2d', { alpha: false }) // TODO: Alpha false?
    )
}


function Background() {
    return rt.rect({
        x: 0,
        y: 0,
        width: panels.value.data.width,
        height: panels.value.data.height,
        style: {
            fillStyle: constants.theme.backgroundColor,
        }
    })
}


function Grid() {
    const style = { 
        strokeStyle: constants.theme.gridColor 
    }
    return rt.container([
        // Vertical lines
        ...xScale.value.ticks(constants.numAxisTicks).map(tick => rt.line({
            from: { x: xScale.value(tick), y: 0 },
            to: { x: xScale.value(tick), y: panels.value.yAxis.height },
            style,
        })),
        // Horizontal lines
        ...getScale('primary').ticks(constants.numAxisTicks).map(tick => rt.line({
            from: { x: 0, y: getScale('primary')(tick) },
            to: { x: panels.value.data.width, y: getScale('primary')(tick) },
            style,
        }))
    ])
}



function TickBars() {
    const candleStickSeries = candlestickSeries?.value?.length ? candlestickSeries.value[0] : null
    const ohlcTicks: OhlcTick[] = candleStickSeries ? candleStickSeries.ticks : []
    if (!ohlcTicks.length) {
        return rt.container([])
    }

    const upStyle = { fillStyle: constants.theme.upColor }
    const downStyle = { fillStyle: constants.theme.downColor }

    // Bin by color for performance reasons
    const greenNodes = []
    const redNodes = []
    forEachVisibleTick(ohlcTicks, tick => {
        const didPriceIncrease = tick.open <= tick.close
        const maxPrice = Math.max(tick.open, tick.close)
        const minPrice = Math.min(tick.open, tick.close) 

        const startX = xScale.value(tick.timestamp - (tickSize.value.size / 2))
        const endX = xScale.value(tick.timestamp + (tickSize.value.size / 2))

        // Fat bar
        const nodes = didPriceIncrease ? greenNodes : redNodes
        nodes.push(
            rt.rect({
                // id: `FatBar_${tick.timestamp}`,
                x: startX,
                y: getScale('primary')(maxPrice),
                width: endX - startX,
                height: (getScale('primary')(minPrice) - getScale('primary')(maxPrice)) || 5,
                style: didPriceIncrease ? upStyle : downStyle,
                events: {
                    mouseenter() {
                        console.log('hit')
                    }
                }
            }),
                
            // ThinBar
            rt.rect({
                // id: `ThinBar_${tick.timestamp}`,
                x: xScale.value(tick.timestamp),
                y: getScale('primary')(tick.high),
                width: 1,
                height: getScale('primary')(tick.low) - getScale('primary')(tick.high),
                style: didPriceIncrease ? upStyle : downStyle,
                events: {
                    mouseenter() {
                        console.log('hit')
                    }
                }
            })
        )
    })


    // TODO: Is this really faster than sorting?
    // TODO: Pre-create array and fill by index for perf
    redNodes.push(...greenNodes) // Push to avoid GC pressure
    return rt.container(redNodes)
}

function Lines() {
    
    // @ts-ignore
    const paths = []
    for (const [lineIdx, s] of lineSeries.value.entries()) {
        const points = []
        forEachVisibleTick(s.ticks, tick => {
            // TODO: perf... can we optimize somehow? Avoid recreating every time?
            // Ideas: SIMD/webassembly to scale values in one operation?
            // Clone values at start, keep a computed copy of scaled values...
            // ...mutate rather than create new. Still requires iteration
            points.push({
                x: xScale.value(tick.timestamp),
                y: getScale(s.scaleGroupId)(tick.value)
            })
        })
        if (points.length < 2) {
            continue
        }

        paths.push(
            rt.path({
                // id: `Path${index}`,
                points,
                style: {
                    lineWidth: 1,
                    // TODO: Make it work across all series types
                    strokeStyle: constants.theme.colorScale(lineIdx),
                },
            })
        )
    }
    
    return rt.container(paths)
}

// TODO: assess perf of creating one circle chain instead
function Circles() {
    
    // @ts-ignore
    const circles = []
    for (const [circleIdx, s] of circleSeries.value.entries()) {
        
        const style = {
            strokeStyle: constants.theme.colorScale(circleIdx),
            fillStyle: constants.theme.colorScale(circleIdx),
        }

        forEachVisibleTick(s.ticks, tick => {
            circles.push(rt.circle({
                x: xScale.value(tick.timestamp),
                y: getScale(s.scaleGroupId)(tick.value),
                radius: 2,
                style,
            }))
        })
    }
    
    return rt.container(circles)
}

function Annotations() {

    // @ts-ignore
    const lines: Annotation[] = props.series.filter(s => s.type === 'annotation')
    
    const annotations = []
    for (const s of lines) {
        forEachVisibleTick(s.ticks, tick => {
            annotations.push(
                rt.text({
                    // id: `Annotation${annotationIndex}_${i}_${tick.timestamp}`,
                    x: xScale.value(tick.timestamp),
                    y: getScale(s.scaleGroupId)(tick.value),
                    text: tick.text,
                    style: {
                        font: constants.annotationFont,
                        textAlign: 'center',
                        textBaseline: 'middle',
                        fillStyle: constants.theme.textColor,
                    }
                })
            )
        })
    }


    return rt.container(annotations)
}

function Regions() {

    // @ts-ignore
    const regions: Region[] = props.series.filter(s => s.type === 'region')
    
    const regionNodes = []
    for (const s of regions) {
        forEachVisibleTick(s.ticks, tick => {
            regionNodes.push(
                rt.rect({
                    // id: `Region${regionIndex}_${i}_${tick.start}_${tick.end}`,
                    x: xScale.value(tick.start),
                    y: 0,
                    width: xScale.value(tick.end) - xScale.value(tick.start),
                    height: panels.value.data.height,
                    style: {
                        // TODO: Sort by fillStyle for perf
                        fillStyle: tick.color || constants.theme.regionColor,
                    }
                })
            )
        })
    }
    
    return rt.container(regionNodes)
}

function XAxis() {

    // NOTE: Drawing underneath this then masking with white. TODO: Optimize
    return rt.container([
        // Background
        rt.rect({
            ...panels.value.xAxis,
            style: {
                fillStyle: constants.theme.backgroundColor,
            }
        }),

        // Axis Separator
        rt.line({
            from: panels.value.xAxis,
            to: { x: panels.value.xAxis.width, y: panels.value.xAxis.y },
            style: {
                strokeStyle: constants.theme.axisSeparatorColor,
            }
        }),

        // Axis Labels
        ...xScale.value.ticks(constants.numAxisTicks).map(tick => rt.text({
            x: xScale.value(tick),
            y: panels.value.xAxis.y + (panels.value.xAxis.height/2),
            text: getXAxisTickString(tick),
            style: {
                font: constants.axisFont,
                textAlign: 'center',
                textBaseline: 'middle',
                fillStyle: constants.theme.textColor,
            }
        }))
    ])        
}

function YAxis() {

    // NOTE: Drawing underneath this then masking with white. TODO: Optimize
    return rt.container([
        // Background
        rt.rect({
            ...panels.value.yAxis,
            style: {
                fillStyle: constants.theme.backgroundColor,
            }
        }),
        
        // Axis Separator
        rt.line({
            from: panels.value.yAxis,
            to: { x: panels.value.yAxis.x, y: panels.value.yAxis.height },
            style: {
                strokeStyle: constants.theme.axisSeparatorColor,
            }    
        }),

        // Axis Labels
        ...getScale('primary').ticks(constants.numAxisTicks).map(tick => rt.text({
            x: panels.value.yAxis.width/2,
            y: getScale('primary')(tick),
            text: `${tick.toFixed(2)}${yAxisUnit.value}`,
            style: {
                font: constants.axisFont,
                textAlign: 'center',
                textBaseline: 'middle',
                fillStyle: constants.theme.textColor,
            }
        })),

        LatestVisibleValueIndicator(),
        LatestValueIndicator(),
        HoveredPriceIndicator(),
    ])

    function HoveredPriceIndicator() {
        if (!mousePosition.value) {
            return rt.container([])
        }

        return rt.container([
            rt.rect({
                x: 0,
                y: mousePosition.value.y - (constants.hoveredValueHeight /2),
                width: panels.value.yAxis.width * 0.9,
                height: constants.hoveredValueHeight,
                style: {
                    fillStyle: constants.theme.overlayColor,
                }
            }),
            rt.text({
                x: 3, // Padding... 
                y: mousePosition.value.y,
                text: String(getScale('primary').invert(mousePosition.value.y).toFixed(2)),
                style: {
                    font: constants.dateFont,
                    fillStyle: 'white',
                    textAlign: 'left',
                    textBaseline: 'middle',
                }
            }),
        ])
    }

    function LatestValueIndicator() {
        if (latestTickInfo.value.isOutOfBounds) {
            return rt.container([])
        }

        return rt.container([
            // Latest Value Box
            rt.rect({
                x: panels.value.yAxis.x, 
                y: latestTickInfo.value.y - (constants.latestValueHeight/2),
                width: panels.value.yAxis.width * 0.9,
                height: constants.latestValueHeight,
                style: {
                    fillStyle: latestTickInfo.value.color,
                }    
            }),

            // Latest Value Text
            rt.text({
                x: panels.value.yAxis.x + 5, 
                y: latestTickInfo.value.y,
                text: `${latestTickInfo.value.value.toFixed(2)}${yAxisUnit.value}`,
                style: {
                    font: constants.latestValueFont,
                    textAlign: 'left',
                    textBaseline: 'middle',
                    fillStyle: 'white',
                }
            }),
        ])
    }

    function LatestVisibleValueIndicator() {

        
        if (latestTickInfo.value.isAlsoLatestVisible) {
            return rt.container([])
        }

        return rt.container([
            // Latest Value Box
            rt.rect({
                x: panels.value.yAxis.x, 
                y: latestVisibleTickInfo.value.y - (12/2),
                width: panels.value.yAxis.width * 0.9,
                height: 12,
                style: {
                    fillStyle: latestVisibleTickInfo.value.color,
                }    
            }),

            // Latest Value Text
            rt.text({
                x: panels.value.yAxis.x + 5, 
                y: latestVisibleTickInfo.value.y,
                text: `${latestVisibleTickInfo.value.value.toFixed(2)}${yAxisUnit.value}`,
                style: {
                    font: constants.latestValueFont,
                    textAlign: 'left',
                    textBaseline: 'middle',
                    fillStyle: 'white',
                }
            }),
        ])

    }
}

function CrossHair() {
    
    if (!mousePosition.value || mousePosition.value.x > props.width || mousePosition.value.y > props.height) {
        return rt.container([])
    }

    const crossHair = {
        width: 14,
    }

    const dashedLineStyle = {
        lineDash: [9, 11],
        strokeStyle: constants.theme.crossHairBarColor,
        lineWidth: 1
    }

    const crossHairStyle = {
        lineWidth: 2,
        strokeStyle: constants.theme.crossHairColor,
    }

    return rt.container([
        // Horizontal Line
        rt.line({
            from: { x: 0, y: mousePosition.value.y },
            to: { x: panels.value.crosshair.width, y: mousePosition.value.y },
            style: dashedLineStyle,
        }),

        // Vertical Line
        rt.line({
            from: { x: xScale.value(closestTime.value), y: 0 },
            to: { x: xScale.value(closestTime.value), y: panels.value.canvas.height },
            style: dashedLineStyle,
        }),

        // Crosshair Vertical Line
        rt.line({
            from: { x: mousePosition.value.x - crossHair.width, y: mousePosition.value.y },
            to: { x: mousePosition.value.x + crossHair.width, y: mousePosition.value.y },
            style: crossHairStyle,
        }),
        

        // Crosshair Horizontal Line
        rt.line({
            from: { x: mousePosition.value.x, y: mousePosition.value.y - crossHair.width },
            to: { x: mousePosition.value.x, y: mousePosition.value.y  + crossHair.width },
            style: crossHairStyle,
        }),

        // TODO: Make width and text contingent on tickSize
        // Crosshair Date Indicator
        rt.rect({
            x: xScale.value(closestTime.value) - (tickSize.value.dateWidth /2),
            y: panels.value.xAxis.y,
            width: tickSize.value.dateWidth,
            height: panels.value.xAxis.height * 0.85,
            style: {
                fillStyle: constants.theme.overlayColor,
            }
        }),

        rt.text({
            x: xScale.value(closestTime.value),
            y: panels.value.xAxis.y + (panels.value.xAxis.height / 2),
            text: DateTime.fromJSDate(xScale.value.invert(xScale.value(closestTime.value)))
                            .toFormat(tickSize.value.dateFormat),
            style: {
                font: constants.dateFont,
                fillStyle: 'white',
                textAlign: 'center',
                textBaseline: 'middle',
            }
        }),
    ])
}
}
})



function getXAxisTickString(tick): string {
    const date = DateTime.fromMillis(tick.getTime())
    const isFirstMonth = date.month === 1
    const isFirstDay = date.day === 1
    const isFirstHour = date.hour === 0
    const isFirstMinute = date.minute === 0

    const shouldShowDay = (
        isFirstHour &&
        isFirstMinute
    )
    const shouldShowMonth = (
        shouldShowDay &&
        isFirstDay
    )
    const shouldShowYear = (
        shouldShowMonth &&
        isFirstMonth
    )

    const shouldShowShortenedHour = (
        isFirstMinute &&
        date.hour === 11
    )

    if (shouldShowYear) {
        // 2014
        return `${date.year}`
    }
    else if (shouldShowMonth) {
        // Aug
        return date.monthShort
    }
    else if (shouldShowDay) {
        return `${date.day}`
    }
    // else if (shouldShowShortenedHour) {
    //     // 12
    //     return date.hour + 1
    // }

    // 14:30
    // `${date.hour + 1}:${date.minute + 1}`
    return date.toFormat('H:mm')
}

function createQueueDrawCall(drawFn) {
    let isDrawQueued = false
    return () => {
        if (isDrawQueued) {
            return
        }
        isDrawQueued = true
        requestAnimationFrame(() => {
            drawFn()
            isDrawQueued = false
        })
    }
}

function preventDefault(event) {
    event = event || window.event
    if (event.preventDefault) {
        event.preventDefault()
    }
    event.returnValue = false
}




function addExtentPadding(extent): Range {
    // TODO: Allow customization
    const isSingleValueVisible = extent[0] === extent[1];
    if (isSingleValueVisible) { // TODO: Do something smarter
        return [
            extent[0] - 1,
            extent[1] + 1,
        ];
    }
    else {
        const padding = 0.1 * (extent[1] - extent[0]);
        return [
            extent[0] - padding,
            extent[1] + padding,
        ]
    }
}
</script>

<style></style>
