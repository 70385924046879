import { DateAlignedHistoricalData, PricePoint, YieldSpread } from '@/common/financials/models'
import { fetchStock, fetchSymbolToLatestTick } from '@/client/api'

export {
    fetchDateAlignedHistoricalData,
    fetchYieldSpread,
    watchSymbols,
    getAdamPortfolioSymbols,
}

async function fetchDateAlignedHistoricalData({ symbol, otherSymbol }): Promise<DateAlignedHistoricalData> {  
    const [stock, otherStock] = await Promise.all([
                                    fetchStock({ symbol }),
                                    fetchStock({ symbol: otherSymbol })
                                ])

    const data: DateAlignedHistoricalData = {
        stocks: [stock, otherStock],
        dateAlignedValues: [],
    }
    for (let i = 0; i < (stock.historicalPrices.length || i < otherStock.historicalPrices.length); i++) {
        const point = stock.historicalPrices[i]
        const otherPoint = otherStock.historicalPrices[i]
        if (!point || !otherPoint) {
            // Dates no longer align
            // TODO: Ensure dates align on ingestion
            break
        }

        // TODO: Ensure dates actually align. Just assuing they do here
        data.dateAlignedValues.push({
            date: point.date,
            values: [point, otherPoint],
        })
    }

    // Return as ascending
    data.dateAlignedValues.reverse()

    return data
}

async function fetchYieldSpread({ symbol, otherSymbol }): Promise<YieldSpread> {
    const [stock, otherStock] = await Promise.all([
                                    fetchStock({ symbol }),
                                    fetchStock({ symbol: otherSymbol })
                         ])

    const yieldSpread: YieldSpread['values'] = []
    for (let i = 0; i < (stock.historicalPrices.length || i < otherStock.historicalPrices.length); i++) {
        const point = stock.historicalPrices[i]
        const otherPoint = otherStock.historicalPrices[i]

        // TODO: Ensure dates actually align. Just assuing they do here
        yieldSpread.push({
            date: point.date,
            openYield: point.openYield - otherPoint.openYield,
            closeYield: point.closeYield - otherPoint.closeYield,
            highYield: point.highYield - otherPoint.highYield,
            lowYield: point.lowYield - otherPoint.lowYield,
        })
    }

    return {
        symbols: [stock.symbol, otherStock.symbol],
        values: yieldSpread.reverse()
    }
}

// TODO: inputs
async function watchSymbols({
    watchedSymbols,
    onStockTick,
}: {
    watchedSymbols?: string[]
    onStockTick: (stock: PricePoint & { symbol: string }) => any
}) {

    const watchedSymbolSet = watchedSymbols?.length ? new Set(watchedSymbols) : null
    await updateSymbols()
    setInterval(updateSymbols, 10 * 1000)

    async function updateSymbols() {
        const symbolToLatestTick = await fetchSymbolToLatestTick()
        for (const [symbol, point] of Object.entries(symbolToLatestTick)) {
            if (watchedSymbolSet && !watchedSymbolSet.has(symbol)) {
                continue
            }

            //@ts-ignore
            onStockTick({ // TODO: Should try to avoid creating new? Or doesn't matter?
                symbol,
                date: point.t,
                openPrice: point.o,
                highPrice: point.h,
                lowPrice: point.l,
                closePrice: point.c,
                volume: point.v,
            })
        }
    }
    
}


function getAdamPortfolioSymbols() {
    return ["NLCP","PINE","ABR","AFCG","CTO","CTO","CSWC","BRK/B","BRK/B","MSFT","MSFT","AMT","AMT","TGT","TGT","NTST","NTST","CCI","CCI","ARE","ARE","PLD","PLD","ABBV","ABBV","AAPL","AAPL","DLR","DLR","IIPR","IIPR","SQ","SQ","NXRT","NXRT","ES","ES","BABA","AMD","AMD","WPC","WPC","THO","THO","UPST","UPST","O","O","ADC","ADC","OKE","OKE","SHAK","SHAK","JD","MO","MO","APO","APO","BROS","NNN","NNN","SAFE","SAFE","ENB","BTI","STAG","STAG","CHCT","CHCT","BEPC","BEPC","PECO","PECO","HTA","HTA","VICI","VICI","STOR","STOR","COLD","COLD","OHI","OHI","NEWT","CWH","CWH","EPRT","EPRT","ONON","BRT","BRT","LAZY","LAZY","DEA","DEA","GSBD","KMI","KMI","MPW","MPW","STAR","STAR","HTGC","CTRE","CTRE","ACRE","WHF","ONL","ONL","WSR","WSR","ORAN","PLTR","PLTR","LUMN","LUMN","BRMK","OCSL","PBPB","SOFI","SACH","HIMS","CRMD","CRMD","ZVIA","ZVIA","CLOV","SENS","SENS","STRPF","ARCC","NMAI","NMAI","PDO","PDO","MMD","MMD","RMM","RMM","PMF","PMF","SPY","SPY","DIA","DIA","QQQ","QQQ","VTI","VTI","VIS","VIS","VPU","VPU","VIG","VIG","VNQ","VNQ","VDE","VDE","IAK","IAK","SCHD","SCHD","TQQQ","TQQQ","XBI","XBI","JEPI","JEPI","VXUS","VXUS","DGRO","DGRO","MCHI","MCHI","SPHD","SPHD","BME","BME","ULST","ULST","BST","BST","BTO","BTO","UTG","UTG","AMZA","AMZA","UTF","UTF","EVT","EVT","KWEB","KWEB","EWG","EWG","HTD","HTD","QQQX","QQQX","SCHY","SCHY","BSTZ","BSTZ","PDI","PDI","PFFA","PFFA","EWH","EWH","ASGI","ASGI","ETG","ETG","BMEZ","BMEZ","RQI","RQI","PDT","PDT","PTY","PTY","ETV","ETV","RIET","RIET","NAD","NAD","MYD","MYD","SDIV","SDIV","PFL","PFL","BDJ","BDJ","BIGZ","BIGZ","USA","USA","SRRTF"]
}
