<template>
    <div>
        <input 
            class="symbolInput"
            type="text"
            :value="symbol"
            @keyup="event => $emit('change', (event.target as any).value)"
            @change="event => {
                $emit('change', (event.target as any).value)
                $emit('commit')
            }"
            list="symbols">

        <datalist id="symbols">
            <option 
                v-for="({ symbol, company }) of matchedSymbols"
                :key="symbol"
                :value="symbol">{{ company.companyName }}</option>
        </datalist>
    </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent, PropType, watch } from 'vue'
import Fuse from 'fuse.js'
import { fetchSymbolToStockMeta } from '@/client/api'

export default defineComponent({
    props: {
        symbol: { type: String as PropType<string>, required: true },
    },
    emits: {
        change: (newSymbol: string) => true,
        commit: () => true
    },
    setup(props) {
        
        const symbols = ref([])
        // const validSymbolSet = computed(() => new Set(symbols.value.map(s => s.symbol)))

        
        fetchSymbolToStockMeta()
            .then(v => symbols.value = Object.values(v))

        const matchedSymbols = computed(() => {
            var a = new Fuse(
                symbols.value,
                {
                    keys: [
                        {
                            name: 'symbol',
                            weight: 4,
                        },
                        'company.companyName',
                    ]
                }

            ).search(props.symbol)
            .slice(0, 10)
            .map(v => v.item)

            return a
        })

    
        return {
            matchedSymbols,
        }
    }
})
</script>

<style lang="scss" scoped>
.symbolInput {
    max-width: 160px;
}
</style>