<template>
    <table class="overview-table">
        <thead>
            <tr>
                <th
                    v-for="column of table.columns"
                    :key="column.header"
                    class="header grid"
                    :class="{
                        alignRight: column.annotations.includes('alignRight'),
                        alignLeft: column.annotations.includes('alignLeft'),
                    }"
                    @click="updateHeadlessTable(table.actions.sort(column))">
                    <span v-if="column === table.sort.column" class="sortIndicator">{{ table.sort.direction === -1 ? '▼' : '▲' }}</span>{{ column.header }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr 
                v-for="cefMeta of table.sort.values"
                :key="cefMeta.symbol">
                <td 
                    v-for="(column, idx) of table.columns"
                    :key="idx"
                    :class="{
                        grid: true,
                        alignRight: column.annotations.includes('alignRight'),
                        alignLeft: column.annotations.includes('alignLeft'),
                    }">
                    {{ column.getDisplayValue(column.accessor(cefMeta)) }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
// import HeadlessTable, { HeadlessColumn } from '@/client/components/HeadlessTable.vue'
import { CefMeta } from '@/common/financials/models'
import { HeadlessTable, HeadlessTableSort, HeadlessTableState } from '@/client/components/HeadlessTable'


// https://media.warriortrading.com/2019/07/webull-screen.png


const formatNumber = new Intl.NumberFormat('en-US', { style: 'decimal',  maximumSignificantDigits: 4 }).format
const formatSignedNumber = new Intl.NumberFormat('en-US', { style: 'decimal',  maximumSignificantDigits: 4, signDisplay: 'always' }).format
const formatPercent = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format
const formatVolume = new Intl.NumberFormat('en-US', { style: 'decimal', notation: 'compact' }).format

export default defineComponent({
    components: {
        HeadlessTable,
    },
    props: {
        cefMetas: { type: Array as () => CefMeta[], required: true}
    },
    setup(props) {
                
        const columns = [
                {
                    header: 'Symbol',
                    accessor: v => v.symbol,
                    getDisplayValue: v => v,
                    annotations: [
                        'alignLeft'
                    ],
                },
                {
                    header: 'Name',
                    accessor: v => v.name,
                    getDisplayValue: v => v,
                    annotations: [
                        'alignLeft'
                    ],
                },
                // {
                //     header: 'Yield',
                //     accessor: v => v.yield,
                //     sortAccessor: (a, b) => a - b, // TODO: Move 0?undefined to end
                //     getDisplayValue: v => v ? formatPercent(v) : '–',
                //     annotations: [
                //         'alignRight',
                //         'yieldColored',
                //     ],
                // },
                {
                    header: 'Price',
                    accessor: v => v.price,
                    getDisplayValue: formatNumber,
                    annotations: [
                        'alignRight',
                        'priceColored',
                    ],
                },
                {
                    header: 'NAV/share',
                    accessor: v => v.navPrice,
                    getDisplayValue: formatNumber,
                    annotations: [
                        'alignRight',
                        'priceColored',
                    ],
                },
                // {
                //     header: 'Change',
                //     accessor: v => v.price - v.closePrice,
                //     sortAccessor: (a, b) => Math.abs(a) - Math.abs(b),
                //     getDisplayValue: v => v ? formatSignedNumber(v) : '–',
                //     annotations: [
                //         'alignRight',
                //         'priceColored'
                //     ],
                // },
                // {
                //     header: '% Change',
                //     accessor: v => (v.price - v.closePrice) / v.closePrice,
                //     sortAccessor: (a, b) => Math.abs(a) - Math.abs(b),
                //     getDisplayValue: formatPercent, 
                //     annotations: [
                //         'alignRight',
                //         'priceColored',
                //     ]
                // },
                // {
                //     header: 'Prev Close',
                //     accessor: v => v.closePrice,
                //     getDisplayValue: formatNumber, 
                //     annotations: [
                //         'alignRight',
                //     ],
                // },
                // {
                //     header: 'High',
                //     accessor: v => v.highPrice,
                //     getDisplayValue: formatNumber,
                //     annotations: [
                //         'alignRight',
                //     ],
                // },
                // {
                //     header: 'Low',
                //     accessor: v => v.lowPrice,
                //     getDisplayValue: formatNumber,
                //     annotations: [
                //         'alignRight',
                //         'priceColored',
                //     ],
                // },
                // {
                //     header: 'Volume',
                //     accessor: v => v.volume,
                //     getDisplayValue: formatVolume,
                //     annotations: [
                //         'alignRight',
                //     ],
                // },
            ]

        const table = ref<HeadlessTableState<CefMeta>>()

        updateHeadlessTable({
            direction: -1,
            column: columns.find(c => c.header === 'Symbol'),
        })

        return {
            table,
            updateHeadlessTable,

            isGreen,
            isNeutral,
            isRed,
        }

        function updateHeadlessTable(sort: HeadlessTableSort) {
            table.value = createHeadlessTable(sort)
            // console.log(table.value.sort.direction, table.value.sort.values[0].name)
        }

        function createHeadlessTable(sort: HeadlessTableSort) {
            return HeadlessTable<CefMeta>({
                values: props.cefMetas,
                sort,
                columns,
            })
        }

        function isGreen(column, stock) {
            if (column.annotations.includes('priceColored')) {
                return stock.price > stock.closePrice
            }
            else if (column.annotations.includes('yieldColored')) {
                return stock.yield > stock.closeYield
            }
        }

        function isNeutral(column, stock) {
            if (column.annotations.includes('priceColored')) {
                return stock.price === stock.closePrice
            }
            else if (column.annotations.includes('yieldColored')) {
                return stock.yield === stock.closeYield
            }
        }

        function isRed(column, stock) {
            if (column.annotations.includes('priceColored')) {
                return stock.price < stock.closePrice
            }
            else if (column.annotations.includes('yieldColored')) {
                return stock.yield < stock.closeYield
            }
        }
    },
})





</script>

<style lang="scss" scoped>
/* TODO: Make standard */
.header {
    color: #696b6e;
    cursor: pointer;
    /* color: #4c4c49; */
}

.grid {
    // border: 1px solid #171717;
}

.green {
    color: #7cc67c;
    /* TODO: Do on tick though */
    animation: upBackground 1s linear;
}

.neutral {
    color: white;
}

.red {
    color: #a32d30;
    animation: downBackground 1s linear;
}

@keyframes upBackground {
  0%   { background: #223839; }
  100% { background: none; }
}

@keyframes downBackground {
  0%   { background: #2f0f0b; }
  100% { background: none; }
}


.overview-table {
    width: 100%;
    border-collapse: collapse;
    td, th {
        padding: 5px 10px;
    }
}

.alignRight {
    text-align: right;
}

.alignLeft {
    text-align: left;
}

.name {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sortIndicator {
    vertical-align: text-bottom;
}
</style>
