
import { scaleQuantize } from 'd3-scale'

const LightTheme = {
    backgroundColor: 'white',
    axisSeparatorColor: '#E1E1E3',
    gridColor: '#F2F3F3',
    textColor: '#131722',
    crossHairBarColor: '#9698A1',
    crossHairColor: 'black',
    upColor: '#69A49A',
    downColor: '#CC5A52',
    lightRegionColor: '#1C2136',
    regionColor: '#272422',
    overlayColor: '#383A45',
    colorScale: scaleQuantize<string>()
    .domain([0, 6])
    .range([
        '#99FF36',
        '#ff7e4d',
        '#DE415A',
        '#FFB542',
        '#09AAA5',
        '#0EC278',
        '#7CD654'
    ])
}

const DarkTheme = {
    backgroundColor: '#191B26',
    textColor: '#B3B5BE',
    gridColor: '#252732',
    axisSeparatorColor: '#30323C',
    crossHairBarColor: '#9698A1',
    crossHairColor: 'white',
    upColor: '#69A49A',
    downColor: '#CC5A52',
    lightRegionColor: '#1C2136',
    regionColor: '#272422',
    overlayColor: '#383A45',
    colorScale: scaleQuantize<string>()
    .domain([0, 6])
    .range([
        '#99FF36',
        '#ff7e4d',
        '#DE415A',
        '#FFB542',
        '#09AAA5',
        '#0EC278',
        '#7CD654'
    ])
}

export default {
    theme: DarkTheme,
    red: '#CC5A52',
    green: '#69A49A',
    greenOther: '#99FF36',
    lightPurple: '#463385',
    purple: '#3A2A6D',
    darkPurple: '#1E1639',
    xAxisHeight: 18,
    yAxisWidth: 35,
    latestValueHeight: 12,
    hoveredValueHeight: 14,
    axisTickWidth: 3,
    numAxisTicks: 8, // TODO: allow passing
    axisFont: '8px Trebuchet MS,roboto,ubuntu,sans-serif',
    font: '14px Trebuchet MS,roboto,ubuntu,sans-serif',
    latestValueFont: '8px Trebuchet MS,roboto,ubuntu,sans-serif',
    dateFont: '8px Trebuchet MS,roboto,ubuntu,sans-serif',
    annotationFont: '10px Trebuchet,MS,roboto,ubuntu,sans-serif',
}
