<template>
    <CustomChart 
        :width="width"
        :height="height"
        :seriesName="seriesName"
        :series="series"
        :tickSize="tickSize" 
    />
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';
// import * as rt from '../render/renderTree'
import CustomChart, { Series, TickSize } from '@/client/charts/CustomChart.vue'
import mockData from './mockOilPrice'

export default defineComponent({
    components: {
        CustomChart,
    },
    data() {
        return {
            width: 800,
            height: 400,
            seriesName: 'Crude Oil',
            series: [
                {
                    type: 'line',
                    unit: '',
                    ticks: mockData.map(v => ({
                        value: v.Close,
                        // open: v.Close,
                        // high: v.Close,
                        // low: v.Close,
                        // close: v.Close,
                        timestamp: DateTime.fromISO(v.Date).toMillis(),
                    }))
                }
            ] as Series[],
            // TODO: Need to support dynamic
            tickSize: '1m' as TickSize,
        }
    },
    mounted() {
    }
})

</script>
