import fs from "fs";

let isDataPathValid;
export { getAppDataPath, tryParseJsonResponse };

function getAppDataPath() {
  const appDataPath = process.env.DATA_DIR;
  if (!appDataPath) {
    throw new Error("process.env.DATA_DIR has not been defined!");
  }

  isDataPathValid = isDataPathValid || fs.existsSync(process.env.DATA_DIR);
  if (!isDataPathValid) {
    throw new Error(
      `process.env.DATA_DIR is pointing to an invalid location! (${process.env.DATA_DIR})`
    );
  }
  // TODO: Throw if dir doesn't exist
  return appDataPath;
}

async function tryParseJsonResponse(response: Response) {
  if (response.status !== 200) {
    // 429 is too many requests
    throw new Error(
      `${response.status} - ${response.statusText} - ${
        new URL(response.url).pathname
      }`
    );
  }

  const rawResponse = await response.text();

  try {
    return JSON.parse(rawResponse);
  } catch (e) {
    throw new Error(`Failed to parse JSON: ${rawResponse}`);
  }
}
