<template>
  <div class="code-editor"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import * as monaco from 'monaco-editor'

export default defineComponent({
  name: 'CodeEditor',
  components: {},
  props: {
      code: { type: String, required: true },
      onCodeChanged: { type: Function, required: true },
  },
  mounted() {

      // TODO: Fix intellisense https://github.com/microsoft/monaco-editor/issues/1001
    // monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    //     target: monaco.languages.typescript.ScriptTarget.ESNext,
    //     lib: ['es6'] // these are the default libraries for the ES5 target
    // });

    //     monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
    //     target: monaco.languages.typescript.ScriptTarget.ESNext,
    //     lib: ['esnext'] // these are the default libraries for the ES5 target
    // });

    // monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    //     noLib: true,
    //     allowNonTsExtensions: true
    // });

    monaco.languages.typescript.typescriptDefaults.addExtraLib(
      `
      interface YieldPoint {
          date: string // "2021-04-30",
          volume: number // 12356

          closePrice: number // 17.68,
          highPrice: number // 17.68,
          lowPrice: number // 17.68,
          openPrice: number // 17.68,
          
          closeYield: number // 0.0746606334841629
          highYield: number // 0.0746606334841629
          lowYield: number // 0.0746606334841629
          openYield: number // 0.0746606334841629


          // Computed using closeYield
          yieldOnCost: number // 0.0746606334841629
      }

      interface TickSnapshot {
          stock: Stock
          value: YieldPoint
      }
      interface ShareLot {
        symbol: string // AAPL
        date: string // 2020-01-01
        shares: number // 100
        costBasis: number // 19.51
      }
      interface Trade {
        type: 'BUY' | 'SELL'
        symbol: string // AAPL
        date: string // 2020-01-01
        shares: number // 100 // TODO: eventually move to shares
        price: number // 19.67
        yield: number // .04
    }

      interface Annotation {
        date: string
        value: number // For placing on graph
        description: string
        descriptionShort: string
    }

      interface TradeResults {
          startingBalance: number
          currentBalance: number
          endingBalance: number
          totalReturn: number
          annualizedReturn: number
          holdings: ShareLot[]
          trades: Trade[]
          annotations: Annotation[]
      }

      interface TransactionParams {
          symbol: string
          shares: number
      }

      interface Strategy {
        description: string
        startingBalance: number
        onTick: (params: {
          date: string
          index: number
          points: TickSnapshot[]
          symbolToPoint: { [symbol: string]: TickSnapshot }
          state: TradeResults
          technicals: TickTechnicals
          buy: (params: TransactionParams) => void
          sell: (params: TransactionParams) => void
          buyAll: (params: { symbol: string }) => void
          sellAll: (params?: { symbol: string }) => void
          allocate: (symbolToTargetAllocation: { [symbol: string]: number }) => void
          allocateEvenly: (symbols: string[]) => void
          annotate: (params: { 
              value: number; 
              description: string; 
              descriptionShort: string; 
          }) => void
        })
      }

      interface TickTechnicals {
          // TODO: What if multiple desired?
          sma: (params: { window: number; getValue: (value: TickSnapshot) => number }) => number[]
          bollinger: (params: { window: number; stdDev?: number; getValue: (value: TickSnapshot) => number }) => BollingerBands[]
      }

      interface BollingerBands {
          low: number
          high: number
      }

      declare var backtest: (params: {
          symbols: string[]
          dates: [string, string],
          strategy: Strategy
      }) => Promise<void>
      `,
      'global.d.ts'
      //, 'node_modules/@types/external/index.d.ts'
    )

      const editor = monaco.editor.create(this.$el, {
            value: this.code,
            language: 'typescript',
            theme: 'vs-dark',
            minimap: { enabled: false },
            fontSize: 11,
        })
    
        
    editor.getModel().onDidChangeContent(event => {
        this.onCodeChanged(editor.getValue())
    });

   
  }
})
</script>

<style>
</style>
