// GOOD for data
// https://markets.businessinsider.com/commodities/oil-price?type=wti

const mockOilPrice: OilPrice[] = [
    {
        "Close": 78.84,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:12"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:14"
    },
    {
        "Close": 78.79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:18"
    },
    {
        "Close": 78.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:20"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:24"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:27"
    },
    {
        "Close": 78.81,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:29"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:31"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:36"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:39"
    },
    {
        "Close": 78.76,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:42"
    },
    {
        "Close": 78.75,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:45"
    },
    {
        "Close": 78.77,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:48"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:51"
    },
    {
        "Close": 78.75,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:54"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 18:57"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:00"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:03"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:09"
    },
    {
        "Close": 78.89,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:12"
    },
    {
        "Close": 78.88,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:15"
    },
    {
        "Close": 78.86,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:17"
    },
    {
        "Close": 78.84,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:21"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:27"
    },
    {
        "Close": 78.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:28"
    },
    {
        "Close": 78.79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:33"
    },
    {
        "Close": 78.81,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:36"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:39"
    },
    {
        "Close": 78.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:40"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:45"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:48"
    },
    {
        "Close": 78.84,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:51"
    },
    {
        "Close": 78.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:54"
    },
    {
        "Close": 78.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 19:57"
    },
    {
        "Close": 78.81,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:00"
    },
    {
        "Close": 78.71,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:03"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:06"
    },
    {
        "Close": 78.79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:09"
    },
    {
        "Close": 78.71,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:12"
    },
    {
        "Close": 78.68,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:15"
    },
    {
        "Close": 78.63,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:18"
    },
    {
        "Close": 78.71,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:21"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:24"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:27"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:30"
    },
    {
        "Close": 78.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:33"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:36"
    },
    {
        "Close": 78.88,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:39"
    },
    {
        "Close": 78.93,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:42"
    },
    {
        "Close": 78.88,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:45"
    },
    {
        "Close": 78.92,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:47"
    },
    {
        "Close": 78.98,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:51"
    },
    {
        "Close": 78.97,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:54"
    },
    {
        "Close": 78.88,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 20:57"
    },
    {
        "Close": 79.06,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:00"
    },
    {
        "Close": 79.06,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:03"
    },
    {
        "Close": 79.07,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:06"
    },
    {
        "Close": 79.08,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:09"
    },
    {
        "Close": 79.19,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:12"
    },
    {
        "Close": 79.28,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:15"
    },
    {
        "Close": 79.29,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:18"
    },
    {
        "Close": 79.15,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:21"
    },
    {
        "Close": 79.09,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:24"
    },
    {
        "Close": 79.09,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:27"
    },
    {
        "Close": 79.13,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:30"
    },
    {
        "Close": 79.12,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:33"
    },
    {
        "Close": 78.97,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:36"
    },
    {
        "Close": 78.97,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:39"
    },
    {
        "Close": 79.1,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:42"
    },
    {
        "Close": 79.07,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:45"
    },
    {
        "Close": 79.11,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:48"
    },
    {
        "Close": 79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:51"
    },
    {
        "Close": 78.95,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:54"
    },
    {
        "Close": 79.06,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 21:57"
    },
    {
        "Close": 79.02,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:00"
    },
    {
        "Close": 78.96,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:03"
    },
    {
        "Close": 78.95,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:06"
    },
    {
        "Close": 78.96,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:09"
    },
    {
        "Close": 79.08,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:12"
    },
    {
        "Close": 79.06,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:15"
    },
    {
        "Close": 79.07,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:18"
    },
    {
        "Close": 79.17,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:21"
    },
    {
        "Close": 79.16,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:24"
    },
    {
        "Close": 79.26,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:27"
    },
    {
        "Close": 79.39,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:30"
    },
    {
        "Close": 79.41,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:33"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:36"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:39"
    },
    {
        "Close": 79.46,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:42"
    },
    {
        "Close": 79.42,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:45"
    },
    {
        "Close": 79.34,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:48"
    },
    {
        "Close": 79.38,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:51"
    },
    {
        "Close": 79.43,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:54"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 22:57"
    },
    {
        "Close": 79.43,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:00"
    },
    {
        "Close": 79.39,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:03"
    },
    {
        "Close": 79.42,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:05"
    },
    {
        "Close": 79.41,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:09"
    },
    {
        "Close": 79.43,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:15"
    },
    {
        "Close": 79.4,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:18"
    },
    {
        "Close": 79.43,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:21"
    },
    {
        "Close": 79.39,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:24"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:27"
    },
    {
        "Close": 79.29,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:30"
    },
    {
        "Close": 79.25,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:33"
    },
    {
        "Close": 79.24,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:36"
    },
    {
        "Close": 79.23,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:39"
    },
    {
        "Close": 79.23,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:41"
    },
    {
        "Close": 79.24,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:45"
    },
    {
        "Close": 79.23,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:48"
    },
    {
        "Close": 79.21,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:51"
    },
    {
        "Close": 79.17,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:54"
    },
    {
        "Close": 79.2,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-07 23:57"
    },
    {
        "Close": 79.23,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:00"
    },
    {
        "Close": 79.24,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:03"
    },
    {
        "Close": 79.29,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:06"
    },
    {
        "Close": 79.28,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:09"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:15"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:18"
    },
    {
        "Close": 79.31,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:20"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:23"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:26"
    },
    {
        "Close": 79.27,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:30"
    },
    {
        "Close": 79.29,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:33"
    },
    {
        "Close": 79.29,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:35"
    },
    {
        "Close": 79.27,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:39"
    },
    {
        "Close": 79.29,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:42"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:45"
    },
    {
        "Close": 79.34,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:47"
    },
    {
        "Close": 79.34,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:51"
    },
    {
        "Close": 79.34,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:53"
    },
    {
        "Close": 79.28,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 00:57"
    },
    {
        "Close": 79.32,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:00"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:02"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:06"
    },
    {
        "Close": 79.38,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:09"
    },
    {
        "Close": 79.38,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:12"
    },
    {
        "Close": 79.39,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:15"
    },
    {
        "Close": 79.4,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:18"
    },
    {
        "Close": 79.43,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:20"
    },
    {
        "Close": 79.44,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:24"
    },
    {
        "Close": 79.42,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:27"
    },
    {
        "Close": 79.47,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:30"
    },
    {
        "Close": 79.39,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:33"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:36"
    },
    {
        "Close": 79.49,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:39"
    },
    {
        "Close": 79.6,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:42"
    },
    {
        "Close": 79.52,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:45"
    },
    {
        "Close": 79.51,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:48"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:51"
    },
    {
        "Close": 79.52,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:54"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 01:57"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:00"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:03"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:06"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:09"
    },
    {
        "Close": 79.54,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:12"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:15"
    },
    {
        "Close": 79.53,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:18"
    },
    {
        "Close": 79.51,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:21"
    },
    {
        "Close": 79.46,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:24"
    },
    {
        "Close": 79.41,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:27"
    },
    {
        "Close": 79.44,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:30"
    },
    {
        "Close": 79.46,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:33"
    },
    {
        "Close": 79.41,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:36"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:39"
    },
    {
        "Close": 79.4,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:42"
    },
    {
        "Close": 79.4,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:45"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:48"
    },
    {
        "Close": 79.35,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:51"
    },
    {
        "Close": 79.42,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:54"
    },
    {
        "Close": 79.47,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 02:57"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:00"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:03"
    },
    {
        "Close": 79.37,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:06"
    },
    {
        "Close": 79.37,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:09"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:12"
    },
    {
        "Close": 79.23,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:15"
    },
    {
        "Close": 79.21,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:18"
    },
    {
        "Close": 79.23,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:21"
    },
    {
        "Close": 79.25,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:23"
    },
    {
        "Close": 79.34,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:27"
    },
    {
        "Close": 79.25,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:30"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:33"
    },
    {
        "Close": 79.25,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:36"
    },
    {
        "Close": 79.27,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:38"
    },
    {
        "Close": 79.34,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:42"
    },
    {
        "Close": 79.35,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:45"
    },
    {
        "Close": 79.4,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:48"
    },
    {
        "Close": 79.4,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:51"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:54"
    },
    {
        "Close": 79.44,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 03:57"
    },
    {
        "Close": 79.49,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:00"
    },
    {
        "Close": 79.42,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:03"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:05"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:09"
    },
    {
        "Close": 79.53,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:12"
    },
    {
        "Close": 79.44,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:15"
    },
    {
        "Close": 79.47,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:18"
    },
    {
        "Close": 79.47,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:21"
    },
    {
        "Close": 79.49,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:24"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:27"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:30"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:33"
    },
    {
        "Close": 79.51,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:36"
    },
    {
        "Close": 79.54,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:39"
    },
    {
        "Close": 79.51,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:41"
    },
    {
        "Close": 79.49,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:45"
    },
    {
        "Close": 79.52,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:48"
    },
    {
        "Close": 79.57,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:51"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:54"
    },
    {
        "Close": 79.47,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 04:57"
    },
    {
        "Close": 79.57,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:00"
    },
    {
        "Close": 79.52,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:03"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:06"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:09"
    },
    {
        "Close": 79.38,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:12"
    },
    {
        "Close": 79.24,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:15"
    },
    {
        "Close": 79.2,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:18"
    },
    {
        "Close": 79.22,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:21"
    },
    {
        "Close": 79.28,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:24"
    },
    {
        "Close": 79.19,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:27"
    },
    {
        "Close": 79.06,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:30"
    },
    {
        "Close": 79.07,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:33"
    },
    {
        "Close": 79.05,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:36"
    },
    {
        "Close": 79.03,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:39"
    },
    {
        "Close": 79.12,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:42"
    },
    {
        "Close": 79.05,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:45"
    },
    {
        "Close": 78.95,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:48"
    },
    {
        "Close": 78.95,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:51"
    },
    {
        "Close": 78.93,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:54"
    },
    {
        "Close": 78.95,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 05:57"
    },
    {
        "Close": 79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:00"
    },
    {
        "Close": 78.99,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:03"
    },
    {
        "Close": 79.1,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:06"
    },
    {
        "Close": 79.01,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:09"
    },
    {
        "Close": 79.05,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:12"
    },
    {
        "Close": 79.03,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:15"
    },
    {
        "Close": 79.04,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:17"
    },
    {
        "Close": 79.05,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:21"
    },
    {
        "Close": 79.07,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:24"
    },
    {
        "Close": 79.05,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:27"
    },
    {
        "Close": 79.07,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:30"
    },
    {
        "Close": 79.06,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:32"
    },
    {
        "Close": 79.06,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:36"
    },
    {
        "Close": 79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:39"
    },
    {
        "Close": 78.96,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:42"
    },
    {
        "Close": 78.93,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:45"
    },
    {
        "Close": 78.89,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:48"
    },
    {
        "Close": 78.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:51"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:54"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 06:57"
    },
    {
        "Close": 78.81,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:00"
    },
    {
        "Close": 78.84,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:03"
    },
    {
        "Close": 78.92,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:06"
    },
    {
        "Close": 78.91,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:09"
    },
    {
        "Close": 78.88,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:12"
    },
    {
        "Close": 78.87,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:15"
    },
    {
        "Close": 78.98,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:18"
    },
    {
        "Close": 78.89,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:21"
    },
    {
        "Close": 78.87,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:24"
    },
    {
        "Close": 78.89,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:26"
    },
    {
        "Close": 78.9,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:30"
    },
    {
        "Close": 78.88,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:33"
    },
    {
        "Close": 78.94,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:36"
    },
    {
        "Close": 78.87,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:39"
    },
    {
        "Close": 78.79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:42"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:45"
    },
    {
        "Close": 78.73,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:48"
    },
    {
        "Close": 78.79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:51"
    },
    {
        "Close": 78.75,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:54"
    },
    {
        "Close": 78.75,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 07:57"
    },
    {
        "Close": 78.77,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:00"
    },
    {
        "Close": 78.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:03"
    },
    {
        "Close": 78.77,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:06"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:09"
    },
    {
        "Close": 78.85,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:12"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:14"
    },
    {
        "Close": 78.85,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:18"
    },
    {
        "Close": 78.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:21"
    },
    {
        "Close": 78.85,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:24"
    },
    {
        "Close": 78.87,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:27"
    },
    {
        "Close": 78.9,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:30"
    },
    {
        "Close": 78.77,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:33"
    },
    {
        "Close": 79.02,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:39"
    },
    {
        "Close": 79.12,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:42"
    },
    {
        "Close": 79.19,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:45"
    },
    {
        "Close": 79.15,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:48"
    },
    {
        "Close": 79.24,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:51"
    },
    {
        "Close": 79.1,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:54"
    },
    {
        "Close": 79.21,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 08:57"
    },
    {
        "Close": 79.1,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:00"
    },
    {
        "Close": 79.15,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:03"
    },
    {
        "Close": 79.34,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:06"
    },
    {
        "Close": 79.39,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:09"
    },
    {
        "Close": 79.23,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:12"
    },
    {
        "Close": 79.31,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:15"
    },
    {
        "Close": 79.28,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:18"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:21"
    },
    {
        "Close": 79.38,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:24"
    },
    {
        "Close": 79.45,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:27"
    },
    {
        "Close": 79.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:30"
    },
    {
        "Close": 79.7,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:33"
    },
    {
        "Close": 79.56,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:36"
    },
    {
        "Close": 79.63,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:39"
    },
    {
        "Close": 79.6,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:42"
    },
    {
        "Close": 79.68,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:45"
    },
    {
        "Close": 79.68,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:48"
    },
    {
        "Close": 79.65,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:51"
    },
    {
        "Close": 79.64,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:54"
    },
    {
        "Close": 79.67,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 09:57"
    },
    {
        "Close": 79.72,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:00"
    },
    {
        "Close": 79.83,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:03"
    },
    {
        "Close": 79.98,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:06"
    },
    {
        "Close": 80.04,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:09"
    },
    {
        "Close": 79.84,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:12"
    },
    {
        "Close": 79.99,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:15"
    },
    {
        "Close": 80,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:18"
    },
    {
        "Close": 79.89,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:21"
    },
    {
        "Close": 79.89,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:24"
    },
    {
        "Close": 79.98,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:27"
    },
    {
        "Close": 80.07,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:30"
    },
    {
        "Close": 80.04,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:33"
    },
    {
        "Close": 80,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:36"
    },
    {
        "Close": 79.97,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:39"
    },
    {
        "Close": 79.91,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:42"
    },
    {
        "Close": 79.88,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:45"
    },
    {
        "Close": 79.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:48"
    },
    {
        "Close": 79.66,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:51"
    },
    {
        "Close": 79.76,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:54"
    },
    {
        "Close": 79.73,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 10:57"
    },
    {
        "Close": 79.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:00"
    },
    {
        "Close": 79.76,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:03"
    },
    {
        "Close": 79.77,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:06"
    },
    {
        "Close": 79.72,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:09"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:12"
    },
    {
        "Close": 79.66,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:15"
    },
    {
        "Close": 79.59,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:18"
    },
    {
        "Close": 79.68,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:21"
    },
    {
        "Close": 79.73,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:24"
    },
    {
        "Close": 79.69,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:27"
    },
    {
        "Close": 79.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:30"
    },
    {
        "Close": 79.65,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:33"
    },
    {
        "Close": 79.73,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:36"
    },
    {
        "Close": 79.77,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:39"
    },
    {
        "Close": 79.75,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:42"
    },
    {
        "Close": 79.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:45"
    },
    {
        "Close": 79.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:48"
    },
    {
        "Close": 79.82,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:51"
    },
    {
        "Close": 79.93,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:54"
    },
    {
        "Close": 79.79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 11:57"
    },
    {
        "Close": 79.65,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:00"
    },
    {
        "Close": 79.73,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:03"
    },
    {
        "Close": 79.56,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:06"
    },
    {
        "Close": 79.53,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:09"
    },
    {
        "Close": 79.54,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:12"
    },
    {
        "Close": 79.37,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:15"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:18"
    },
    {
        "Close": 79.34,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:21"
    },
    {
        "Close": 79.29,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:24"
    },
    {
        "Close": 79.31,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:27"
    },
    {
        "Close": 79.28,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:30"
    },
    {
        "Close": 79.19,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:33"
    },
    {
        "Close": 79.14,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:36"
    },
    {
        "Close": 79.14,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:39"
    },
    {
        "Close": 79.18,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:42"
    },
    {
        "Close": 79.11,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:45"
    },
    {
        "Close": 79.09,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:48"
    },
    {
        "Close": 79.12,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:51"
    },
    {
        "Close": 79.25,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:54"
    },
    {
        "Close": 79.28,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 12:57"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:00"
    },
    {
        "Close": 79.4,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:03"
    },
    {
        "Close": 79.44,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:06"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:09"
    },
    {
        "Close": 79.29,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:12"
    },
    {
        "Close": 79.39,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:15"
    },
    {
        "Close": 79.17,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:18"
    },
    {
        "Close": 79.09,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:21"
    },
    {
        "Close": 79.18,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:24"
    },
    {
        "Close": 79.05,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:27"
    },
    {
        "Close": 78.8,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:30"
    },
    {
        "Close": 78.78,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:33"
    },
    {
        "Close": 78.72,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:36"
    },
    {
        "Close": 78.91,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:39"
    },
    {
        "Close": 78.93,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:42"
    },
    {
        "Close": 78.91,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:45"
    },
    {
        "Close": 79,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:48"
    },
    {
        "Close": 79.16,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:51"
    },
    {
        "Close": 79.12,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:54"
    },
    {
        "Close": 79.23,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 13:57"
    },
    {
        "Close": 79.25,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:00"
    },
    {
        "Close": 79.35,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:03"
    },
    {
        "Close": 79.41,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:06"
    },
    {
        "Close": 79.36,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:09"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:12"
    },
    {
        "Close": 79.3,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:15"
    },
    {
        "Close": 79.37,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:18"
    },
    {
        "Close": 79.35,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:21"
    },
    {
        "Close": 79.42,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:24"
    },
    {
        "Close": 79.32,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:27"
    },
    {
        "Close": 79.38,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:30"
    },
    {
        "Close": 79.3,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:33"
    },
    {
        "Close": 79.22,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:36"
    },
    {
        "Close": 79.33,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:39"
    },
    {
        "Close": 79.3,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:42"
    },
    {
        "Close": 79.43,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:45"
    },
    {
        "Close": 79.43,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:48"
    },
    {
        "Close": 79.43,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:50"
    },
    {
        "Close": 79.44,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:54"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 14:57"
    },
    {
        "Close": 79.41,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:00"
    },
    {
        "Close": 79.42,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:02"
    },
    {
        "Close": 79.39,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:06"
    },
    {
        "Close": 79.44,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:09"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:11"
    },
    {
        "Close": 79.53,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:15"
    },
    {
        "Close": 79.52,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:18"
    },
    {
        "Close": 79.49,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:21"
    },
    {
        "Close": 79.47,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:24"
    },
    {
        "Close": 79.53,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:27"
    },
    {
        "Close": 79.61,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:30"
    },
    {
        "Close": 79.58,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:33"
    },
    {
        "Close": 79.59,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:36"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:39"
    },
    {
        "Close": 79.56,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:42"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:45"
    },
    {
        "Close": 79.61,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:48"
    },
    {
        "Close": 79.56,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:51"
    },
    {
        "Close": 79.54,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:54"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 15:57"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:00"
    },
    {
        "Close": 79.49,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:03"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:06"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:09"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:11"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:12"
    },
    {
        "Close": 79.49,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:17"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:20"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:24"
    },
    {
        "Close": 79.48,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:26"
    },
    {
        "Close": 79.5,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:30"
    },
    {
        "Close": 79.52,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:33"
    },
    {
        "Close": 79.53,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:36"
    },
    {
        "Close": 79.52,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:38"
    },
    {
        "Close": 79.51,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:45"
    },
    {
        "Close": 79.49,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:48"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:51"
    },
    {
        "Close": 79.55,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:54"
    },
    {
        "Close": 79.56,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:56"
    },
    {
        "Close": 79.62,
        "Open": 0,
        "High": 0,
        "Low": 0,
        "Volume": 0,
        "Estimate": 0,
        "Date": "2021-10-08 16:59"
    }
]

export default mockOilPrice.map(v => ({
    ...v,
    Date: toISO(v.Date),
}))

// temp
export interface OilPrice {
    "Close": number
    "Open": number
    "High": number
    "Low": number
    "Volume": number
    "Estimate": number
    "Date": string // "2021-10-07 18:12"
}


function toISO(oilPriceDate) {
    return oilPriceDate.split(' ').join('T')
}