import { clamp, round } from 'lodash'
import { DateTime, Duration } from 'luxon'
import {
    YieldPoint, 
    Stock,
} from '@/common/financials/models'

import {
    OnTickFn, 
    OnTickParams,
    TickSnapshot,
    TradeResults,
    TradeParams,
    TickTechnicals,
    Trade,
} from '@/common/backtest/models'

import {
    BollingerBands,
} from '@/common/technicals/models'

import { getSubset, getSum } from '@/common/math/utils';
import { createRollingSMAFn, createRollingBollingerFn } from '@/common/technicals/utils'

export {
    backtest,
    trade,
    executeStrategy,
    buy, 
    buyAll, 
    sell, 
    sellAll, 
    allocate, 
    allocateEvenly,
}

async function backtest({
    stocks,
    dates,
    strategies,
}: {
    stocks: Stock[]
    dates: [string, string],
    strategies: Strategy[]
}): Promise<BackTestResults> {

    if (!stocks.length) {
        throw new Error('Must supply some stocks!')
    }
    // TODO: Avoid supply symbols up front at all?
    const maxStocksAtOnce = 10
    if (stocks.length > maxStocksAtOnce) {
        throw new Error(`Only ${maxStocksAtOnce} stocks supported at a time!`)
    }
    if (dates.length < 2 || dates.length > 2) { // TODO: Support discontinuous ranges
        throw new Error('Must supply a valid date range!')
    }
    if (!strategies.length) {
        throw new Error('Must supply at least one strategy!')
    }

    stocks = stocks.map(stock => getSubsetOfStock(stock, dates))

    const strategyResults = strategies.map(strategy => (
        executeStrategy({
            stocks, 
            strategy, 
            dates
        })
    ))
    .sort((a, b) => b.totalReturn - a.totalReturn)

    return {
        bestStrategy: strategyResults[0],
        strategyResults,
    }
}

interface BackTestResults {
    bestStrategy: any
    strategyResults: any[] // TODO: add strategy result
}

// TODO: Optimize we can parallelize strategy execution
// Process same tick for multiple strategies
function executeStrategy({
    stocks, 
    strategy, 
    dates,
}: {
    stocks: Stock[]
    dates: [string, string]
    strategy: Strategy
}) {
    return {
        description: strategy.description,
        dates,
        ...trade({
            stocks,
            startingBalance: strategy.startingBalance,
            onTick: strategy.onTick,
        })
    } 
}


// TODO: perf of reversing
function getSubsetOfStock(stock: Stock, dates: [string, string]): Stock {
    return {
        ...stock,
        // TODO: Check for off by 1
        historicalPrices: getSubset<YieldPoint>({
            values: stock.historicalPrices.reverse(),
            range: dates.map(d => DateTime.fromISO(d).toMillis()),
            getComparable: v => DateTime.fromISO(v.date).toMillis(),
        }).reverse()
    }
}

interface Strategy {
    description: string
    startingBalance: number
    // onInit: OnInitFn
    onTick: OnTickFn
}


// TODO: Should allow exposure to client too? Then shouldn't live here
function trade({
    stocks,
    startingBalance,
    onTick,
}: TradeParams): TradeResults {
    
    const state: TradeResults = {
        startingBalance,
        currentBalance: startingBalance,
        endingBalance: null,
        totalReturn: null,
        annualizedReturn: null,
        holdings: [],
        trades: [],
        annotations: [],
    }

    
    // NOTE: assuming all dates are aligned
    const minDate = stocks[0].historicalPrices[0].date
    const maxDate = stocks[0].historicalPrices.at(-1).date
    const technicals = createInternalTechnicals()
    const dividends = stocks.map(s => s.dividends.map(d => ({ symbol: s.symbol, ...d, })))
                            .flat()
                            .sort((a, b) => DateTime.fromISO(a.exDate).toMillis() - DateTime.fromISO(b.exDate).toMillis())
                            .filter(d => DateTime.fromISO(d.exDate) >= DateTime.fromISO(minDate))

    const pendingDividends = []
    
    let symbolToPoint: OnTickParams['symbolToPoint'] = {}
    forEachTick(stocks, processTick)
    
    state.endingBalance = state.currentBalance + state.holdings.reduce((sum, h) => sum + (symbolToPoint[h.symbol].value.closePrice * h.shares), 0)
    state.totalReturn = (state.endingBalance / state.startingBalance) - 1
    const numYears = DateTime.fromISO(maxDate).diff(DateTime.fromISO(minDate), 'years').years
    
    // TODO: is this right?
    state.annualizedReturn = ((state.totalReturn + 1) ** (1/numYears)) - 1

    return state


    function processTick(points: TickSnapshot[], index: number) {
        const date = points[0].value.date
        
        // TODO: Add map to avoid O(n) lookup
        
        
        while (dividends.length && dividends[0].exDate === date) { // TODO: Use date comparison, not strig
            const pendingDividend = dividends.shift()
            
            const exDivStockLots = state.holdings.filter(h => h.symbol === pendingDividend.symbol)
            if (exDivStockLots.length) {
                // TODO: handle splits or whatever
                pendingDividends.push({
                    shares: getSum(exDivStockLots.map(l => l.shares)),
                    pendingDividend
                })
            }
        }

        while (pendingDividends.length && pendingDividends[0].pendingDividend.payDate === date) {
            const { pendingDividend, shares } = pendingDividends.shift()
            // TODO: is this logic right? Adjustment doesn't seem right
            state.currentBalance += shares * pendingDividend.adjustedAmount
        }

        symbolToPoint = Object.fromEntries(points.map(p => [p.stock.symbol, p]))
        
        // TODO: add dividend support. If holding on ex date, add on pay date
        onTick({
            date,
            index,
            points,
            symbolToPoint,
            state,
            technicals: createTechnicals({ 
                technicals, 
                points, 
                tickIndex: index 
            }),
            buy({ symbol, shares }) {
                return buy({
                    symbolToPoint,
                    symbol,
                    shares,
                    state,
                    date,
                })
            },
            buyAll({ symbol }) {
                return buyAll({
                    symbolToPoint,
                    symbol,
                    state,
                    date,
                })
            },
            sell({ symbol, shares }) {
                return sell({
                    symbolToPoint,
                    symbol,
                    shares,
                    state,
                    date,
                })
            },
            sellAll(params: { symbol?: string }) {
                return sellAll({
                    state,
                    symbolToPoint,
                    symbol: params ? params.symbol: null,
                    date,
                })
            },
            allocate(symbolToPercentage) {
                return allocate({
                    symbolToPercentage,
                    symbolToPoint,
                    state,
                    date,
                })
            },
            allocateEvenly(symbols) {
                return allocateEvenly({
                    symbols,
                    symbolToPoint,
                    state,
                    date,
                })
            },
            annotate,
        })
        
        function annotate({
            value,
            description,
            descriptionShort,
        }) {
            state.annotations.push({
                date,
                value,
                description,
                descriptionShort,
            })
        }

    }   
}




function createTechnicals({ 
    technicals,
    points,
    tickIndex 
}: { 
    technicals: InternalTechnicals
    points: TickSnapshot[]
    tickIndex: number 
}): TickTechnicals {
    return {
        sma: ({ window, getValue }) => {
            return technicals.sma({
                // TODO: Maybe should store raw points and only map
                // within rolling Fn. This way we can share memory and
                // only compute as needed. TODO: benchmark
                values: points.map(getValue),
                window,
                tickIndex,
            })
        },
        bollinger: ({ window, stdDev = 2, getValue }) => {
            return technicals.bollinger({
                values: points.map(getValue),
                stdDev,
                window,
                tickIndex,
            })
        }
    }
}


function createInternalTechnicals(): InternalTechnicals {
        
    let smaFns: ReturnType<typeof createRollingSMAFn>[] = []
    let bollingerFns: ReturnType<typeof createRollingBollingerFn>[] = []

    return {
        sma,
        bollinger,
    }

    function sma({ values, window, tickIndex }: CommonInternalTechnicalsParams): number[] {
        // TODO: Throw if not called on first day
        if (!smaFns.length) {
            const isFirstTick = tickIndex === 0
            if (!isFirstTick) {
                throw new Error('Technical Indicators must be called on the first day!')
            }

            smaFns = values.map(_ => createRollingSMAFn({ window }))
        }

        const results = smaFns.map((fn, i) => fn(values[i]))
        return results
    }


    // TODO: Remove redundancy
    function bollinger({ values, window, tickIndex, stdDev }): BollingerBands[] {
        // TODO: Throw if not called on first day
        if (!bollingerFns.length) {
            const isFirstTick = tickIndex === 0
            if (!isFirstTick) {
                throw new Error('Technical Indicators must be called on the first day!')
            }

            bollingerFns = values.map(_ => createRollingBollingerFn({ window, stdDev }))
        }

        const results = bollingerFns.map((fn, i) => fn(values[i]))
        return results
    }
}

interface InternalTechnicals {
    sma: (params: CommonInternalTechnicalsParams) => number[]
    bollinger: (params: { stdDev: number } & CommonInternalTechnicalsParams) => BollingerBands[]
}

interface CommonInternalTechnicalsParams {
    values: number[]
    window: number
    tickIndex: number
}

// Assumes ordered desc
function forEachTick(stocks: Stock[], fn: (tickValues: TickSnapshot[], index: number) => any) {
    const shortestLength = Math.min(...stocks.map(s => s.historicalPrices.length))
    // TODO: don't store in memory... handle via iterable
    const tickSnapshots = []
    for (let i = 0; i < shortestLength; i++) {
        const points: TickSnapshot[] = stocks.map(stock => ({
            stock,
            value: stock.historicalPrices[i],
        }))

        const distinctDates = new Set(points.map(({ value }) => value.date))
        const isDateUnique = distinctDates.size === 1
        if (!isDateUnique) {
            // String interpolation not working unless pulled out. Why?
            const str = `Date does not match in each point! Ensure date at each index of input stock data is aligned! Dates: ${[...distinctDates.values()].join(', ')}`
            throw new Error(str)
        }

        const isPastEndOfOneDataset = points.some(p => !p.value)
        if (isPastEndOfOneDataset) {
            break
        }

        tickSnapshots.push(points)
    }

    let i = 0;
    for (const tickSnapshot of tickSnapshots) {
        fn(tickSnapshot, i++)
    }
}


function tradeBuyAndHold({
    stock,
    startingBalance,
}: {
    stock: Stock
    startingBalance: number
}) {
    return {
        description: `${stock.symbol} - Buy and Hold`,
        ...trade({
            stocks: [stock],
            startingBalance,
            onTick({ state, points, buyAll }) {
                if (!state.holdings.length || state.currentBalance >= points[0].value.closePrice) {
                    buyAll({ symbol: stock.symbol })
                }
            },
        })
    }
}


// TODO: Need to be able to get data for dead companies?
// Otherwise backtest is biased
// async function tradeBiotechDates() {
//     // NOTE: top 100 at time pulled (2021). May cause weirdness
//     const top100BiotechByCapSet = new Set(['JNJ', 'RHHBY', 'PFE', 'TMO', 'LLY', 'MRK', 'ABBV', 'AZN', 'NVS', 'BMY', 'AMGN', 'SNY', 'GSK', 'CSL.AX', 'ZTS', 'GILD', '2359.HK', 'WXXWY', 'ILMN', 'BNTX', 'REGN', '4519.T', 'LONN.SW', 'BAYZF', 'DXCM', 'TAK', '600276.SS', '207940.KS', 'A', 'VRTX', 'BIIB', 'SUNPHARMA.NS', 'RPRX', 'CRL', 'VTRS', 'TXG', 'NVCR', 'TEVA', 'CIPLA.NS', 'NTLA', 'NBIX', 'CVAC', 'CRSP', 'GLAND.NS', '1548.HK', 'JAZZ', 'PRGO', 'ABBOTINDIA.NS', 'NVTA', 'HCM', 'NEO', 'LUPIN.NS', 'BIOCON.NS', 'BEAM', 'FATE', 'CERT', 'TLRY', 'ALKS', 'MRVI', 'BION.SW', 'ABCL', 'ADPT', 'VIR', 'CERE', 'AMRS', 'RETA', 'NK', 'PRTA', 'KYMR', 'TBIO', 'RLAY', 'EDIT', 'RCUS', 'FLGT', 'MYGN', 'BCRX', 'SAVA', 'VCEL', 'CCCC', 'SEER', 'AMRN', 'JUBILANT.NS', 'MORF', 'DVAX', 'QTRX', 'TRIL', 'BLFS', 'LMNX', 'ORGO', 'RGNX', 'CDXS', 'RUBY', 'RUBY', 'DRNA', 'KDMN', 'VALN', 'BNGO', 'SUPN', 'OCGN', 'AVXL'])
//     const deadSymbols = new Set([
//         'VRX', 
//         'AAAP', 
//         'CELG', 
//         'MDCO', 
//         'NEOS', 
//         'IPCI'
//     ])
    
//     // Calendar could have random dates. Need to build complete calendar
//     const biotechCalendar = await readBiotechCalendar()
//     const pdufaSymbols = [...new Set(biotechCalendar.values.filter(v => v.type === 'PDUFA').map(v => v.symbol)).values()]
//                         .filter(s => !top100BiotechByCapSet.has(s))
//                         .filter(s => !deadSymbols.has(s))
//                         // .slice(0, 5) // 10 symbols to start
//                         // .slice(2, 10)
//     return backtest({
//         symbols: pdufaSymbols, // NOTE: Huge amount of data
//         dates: [
//             biotechCalendar.values[0].date,
//             biotechCalendar.values.at(-1).date,
//         ],
//         strategies: [{
//             description: 'Buy smallcap biotech prior to pdufa',
//             startingBalance: 10_000,
//             onTick({ date, symbolToPoint, state, allocateEvenly, index }) {
                
//                 const today = DateTime.fromISO(date)

//                 // Create projected holdings. Reallocate as needed

//                 const projectedHoldings = []
//                 for (const value of biotechCalendar.values) {
//                     const calendarDate = DateTime.fromISO(value.date)
//                     const daysInFuture = calendarDate.diff(today, 'days').days
//                     const buyWhenDaysOut = 120
//                     const sellWhenDaysOut = 30

//                     const isInThePast = daysInFuture < 0
//                     const isPastBuyThreshold = daysInFuture > buyWhenDaysOut
//                     const isInSellThreshold = daysInFuture < sellWhenDaysOut
//                     const isInBuyThreshold = daysInFuture <= buyWhenDaysOut

//                     if (isInThePast) {
//                         continue
//                     }
//                     else if (isPastBuyThreshold) {
//                         break
//                     }
//                     else if (isInSellThreshold) {
//                         // sellAll({ symbol: value.symbol })
//                     }
//                     else if (isInBuyThreshold && symbolToPoint[value.symbol]) {
//                         projectedHoldings.push(value.symbol)
//                     }
//                 }

//                 // NOTE: This will do minor rebalancings based on price fluctuation
//                 // Is that desired? 
//                 // Rebalance
//                 allocateEvenly(projectedHoldings)
//             }
//         }]
//     })
// }

function tradeYieldSpreadBollinger({
    stocks,
    startingAmount,
    bollingerBands,
}: {
    stocks: Stock[]
    bollingerBands: { date: Date, low: number, high: number }[]
    startingAmount: number
}) {

    return trade({
        stocks,
        startingBalance: startingAmount,   
        onTick,
    })

    function onTick({ date, index, points, state, buyAll, sellAll, annotate }: OnTickParams) {
        const heldSymbol = state.holdings.length ? state.holdings[0].symbol : null
        const yieldSpread = points[0].value.closeYield - points[1].value.closeYield
        if (!heldSymbol) {
            buyAll({ symbol: stocks[0].symbol })

            annotate({
                value: yieldSpread,
                description: `Buy initial lots of ${stocks[0].symbol}`,
                descriptionShort: `BUY ${stocks[0].symbol}`,
            })
        }

        const heldPoint = heldSymbol ? points.find(p => p.stock.symbol === heldSymbol) : null
        const bands = bollingerBands[index]
        
        
        const hasPiercedHighBollinger = bands.high != null && yieldSpread >= bands.high
        const hasPiercedLowBollinger = bands.low != null && yieldSpread <= bands.low
        
        // TODO: Use single path
        const shouldTrade = hasPiercedHighBollinger || hasPiercedLowBollinger

        const isHoldingFirstPoint = heldPoint === points[0]
        if (hasPiercedHighBollinger && !isHoldingFirstPoint) {
            sellAll()
            buyAll({
                symbol: points[0].stock.symbol
            })
            annotate({
                value: bands.high,
                description: `Swap ${stocks[0].symbol} for ${stocks[1].symbol}`,
                descriptionShort: `BUY ${stocks[1].symbol}`,
            })
        }
        else if (hasPiercedLowBollinger && isHoldingFirstPoint) {
            sellAll()
            buyAll({
                symbol: points[1].stock.symbol,
            })
            annotate({
                value: bands.low,
                description: `Swap ${stocks[1].symbol} for ${stocks[0].symbol}`,
                descriptionShort: `BUY ${stocks[0].symbol}`,
            })
        }
    }

}


function tradeYieldSpread({
    stocks,
    startingAmount,
    swapThreshold,
}: {
    stocks: Stock[]
    startingAmount: number // 10_000
    swapThreshold: number // 0.1
}) {

    const yieldState = {
        yieldGainThroughSwapping: 0,
    }

    return trade({
        stocks,
        startingBalance: startingAmount,   
        onTick,
    })

    function toHighestYieldPoint(highestYieldPoint, point): TickSnapshot {
        const shouldSwap = !highestYieldPoint || (point.value.closeYield > highestYieldPoint.value.closeYield)
        return shouldSwap ? point : highestYieldPoint
    }

    function onTick({ date, points, state, buyAll, sellAll }: OnTickParams) {
        const heldSymbol = state.holdings.length ? state.holdings[0].symbol : null
        const heldPoint = heldSymbol ? points.find(p => p.stock.symbol === heldSymbol) : null
        
        const highestYieldPoint = points.reduce(toHighestYieldPoint, null)
        const isYieldSpreadAboveThreshold = !heldPoint || (highestYieldPoint.value.closeYield - heldPoint.value.closeYield) >= swapThreshold
        const pointToAcquire = isYieldSpreadAboveThreshold ? highestYieldPoint : null

        const shouldTrade = Boolean(pointToAcquire)
        if (!shouldTrade) {
            return
        }

        if (state.holdings.length) {
            sellAll({ 
                symbol: state.holdings[0].symbol,
            })
        }

        buyAll({
            symbol: pointToAcquire.stock.symbol,
        })

        const yieldSpread = heldPoint ? (pointToAcquire.value.closeYield - heldPoint.value.closeYield) : 0
        yieldState.yieldGainThroughSwapping += yieldSpread
    }

}

interface DailyTrades {
    date: string
    buys: string[]
    sells: string[]
}

function getDailyTrades(trades: Trade[]): DailyTrades[] {
    const dailyTrades = []
    let activeTradesForDay
    for (const trade of trades) {
        if (!activeTradesForDay || activeTradesForDay.date !== trade.date) {
            if (activeTradesForDay) {
                dailyTrades.push(activeTradesForDay)
            }
            activeTradesForDay = {
                date: trade.date,
                buys: [], // TODO: Ensure unique
                sells: [],
            }
        }

        activeTradesForDay[trade.type === 'BUY' ? 'buys' : 'sells'].push(trade.symbol)
    }

    dailyTrades.push(activeTradesForDay)

    return dailyTrades
  }



  
function allocateEvenly({
    symbols,
    symbolToPoint,
    state,
    date,
}: {
    symbols: string[],
    symbolToPoint: OnTickParams['symbolToPoint']
    state: TradeResults
    date: string
}) {
    return allocate({
        symbolToPercentage: Object.fromEntries(
            symbols.map(s => [s, 1 / symbols.length])
        ),
        symbolToPoint,
        state,
        date,
    })
}

// TODO: Support uniform rebalance?
/** 
    Re-allocate all funds to specified percentages.
    Will sell positions as needed to meet allocation targets.
    If percentages don't add up to 1, remaining amount moves to cash.
*/
function allocate({
    symbolToPercentage,
    symbolToPoint,
    state,
    date,
}: {
    symbolToPercentage: {
        [symbol: string]: number
    }
    symbolToPoint: OnTickParams['symbolToPoint']
    state: TradeResults
    date: string
}) {
    const totalPercentage = Object.values(symbolToPercentage).reduce((a, b) => a + b, 0)
    if (totalPercentage > 1) {
        throw new Error(`Cannot allocate greater than 100% of funds! Attempting to allocation: ${totalPercentage}`)
    }
    else if (totalPercentage < 0) { // TODO: Treat as 0?
        throw new Error(`Cannot allocate below 0% of funds! Attempting to allocation: ${totalPercentage}`)
    }
    const totalValue = state.currentBalance + state.holdings.reduce((sum, h) => sum + (symbolToPoint[h.symbol].value.closePrice * h.shares), 0)
    
    // Divest any holdings that aren't in new target allocation
    const holdingsToDivest = new Set(state.holdings.filter(h => !symbolToPercentage[h.symbol]).map(v => v.symbol))
    for (const symbol of holdingsToDivest) {
        sellAll({ 
            symbolToPoint,
            symbol,
            state,
            date,
        })
    }

    // TODO: Do sells first to free up cash
    // Adjust holdings as needed
    const symbolsToAdjust = []
    for (const [symbol, targetPercent] of Object.entries(symbolToPercentage)) {
        const point = symbolToPoint[symbol]
        const isTargetingInvalidSymbol = point == null
        
        if (isTargetingInvalidSymbol) {
            throw new Error(`Attempting to allocate to symbol: ${symbol}, but this symbol is not loaded in the backtest!`)
        }

        // Need to factor in all holdings!
        const currentlyHeldShares = state.holdings.filter(v => v.symbol === symbol).reduce((a, b) => a + b.shares, 0)

        const targetAllocation = targetPercent || 0
        const currentAllocation = (currentlyHeldShares * point.value.closePrice) / totalValue

        // Positive if buy, negative if sell
        const allocationGap = targetAllocation - currentAllocation // Precision loss? Can we do cleaner?
        const valueToAdjust = totalValue * allocationGap
        const rawSharesToAdjust = valueToAdjust / point.value.closePrice
        
        symbolsToAdjust.push({
            symbol,
            sharesToAdjust: clamp( // If uneven share boundary, prefer to sell 1 extra and buy 1 less
                Math.floor(rawSharesToAdjust), 
                currentlyHeldShares ? -currentlyHeldShares : -Infinity, 
                currentlyHeldShares ? currentlyHeldShares : Infinity
            )
        })
    }

    // Sell first to free up cash
    symbolsToAdjust.sort((a, b) => Math.sign(a.sharesToAdjust) - Math.sign(b.sharesToAdjust))
    
    for (const { symbol, sharesToAdjust } of symbolsToAdjust) {
        const isAlreadyAtTargetAllocation = sharesToAdjust === 0
        
        if (!isAlreadyAtTargetAllocation) {
            const isBuy = sharesToAdjust > 0
            const action = isBuy ? buy : sell

            action({ 
                symbolToPoint,
                symbol,
                shares: Math.abs(sharesToAdjust),
                state,
                date,
            })
        }

        
    }
}

function buyAll({ 
    symbolToPoint,
    symbol,
    state,
    date,
}: {
    symbolToPoint: { [ticker: string]: TickSnapshot }
    symbol: string
    state: TradeResults
    date: string
}) {
    const point = symbolToPoint[symbol]
    if (!point) {
        throw new Error(`Attempting to buy ${symbol}, which isn't available in the input data!`)
    }
    buy({ 
        state,
        shares: Math.floor(state.currentBalance / point.value.closePrice),
        symbolToPoint,
        symbol,
        date,
    })
}

function buy({ 
    symbolToPoint,
    symbol,
    shares, 
    state,
    date,
}: {
    symbolToPoint: { [ticker: string]: TickSnapshot }
    symbol: string
    shares: number
    state: TradeResults
    date: string
}) {
    const point = symbolToPoint[symbol]
    if (!point) {
        throw new Error(`Attempting to buy ${symbol}, which isn't available in the input data!`)
    }

    if (!Number.isInteger(shares)) {
        throw new Error('Attempting to buy a non-integer number of shares! Fractional shares not currently supported...')
    }

    const costToPurchase = point.value.closePrice * shares
    if (costToPurchase > state.currentBalance) {
        const msg = `Attempting to buy ${shares} shares of ${point.stock.symbol}, but not enough balance! Cost: ${costToPurchase}, Balance: ${state.currentBalance}`
        throw new Error(msg)
    }

    // Round to preserve precision
    state.currentBalance = round(state.currentBalance - costToPurchase, 3)
    
    state.holdings.push({
        date,
        symbol: point.stock.symbol,
        shares,
        costBasis: point.value.closePrice,
    })

    state.trades.push({
        type: 'BUY',
        symbol: point.stock.symbol,
        date,
        shares,
        price: point.value.closePrice,
        yield: point.value.closeYield,
    })
}

function sellAll({
    symbolToPoint,
    symbol,
    state,
    date,
}: { 
    symbolToPoint: { [ticker: string]: TickSnapshot }
    symbol?: string
    state: TradeResults
    date: string
}) {

    const symbolToSharesOwned: { [symbol: string]: number } = state.holdings
                                    .reduce((map, h) => {
                                        map[h.symbol] = map[h.symbol] || 0
                                        map[h.symbol] += h.shares
                                        return map
                                    }, {})

    for (const [ownedSymbol, sharesOwned] of Object.entries(symbolToSharesOwned)) {
        if (symbol && ownedSymbol !== symbol) {
            continue
        }

        sell({
            symbol: ownedSymbol,
            symbolToPoint,
            shares: sharesOwned,
            state,
            date,
        })
    }
}

function sell({ 
    symbolToPoint,
    symbol,
    shares, 
    state,
    date,
}: {
    symbolToPoint: { [ticker: string]: TickSnapshot }
    symbol: string
    shares: number
    state: TradeResults
    date: string
}) {
    const point = symbolToPoint[symbol] 
    if (!point) {
        throw new Error(`Attempting to sell ${symbol}, which isn't available in the input data!`)
    }

    const symbolHoldings = state.holdings.filter(h => h.symbol === symbol)
    if (!symbolHoldings.length) {
        throw new Error(`Attempting to sell ${symbol}, which isn't currently held!`)
    }

    const sharesHeld = symbolHoldings.reduce((sum, s) => sum + s.shares, 0)
    if (sharesHeld < shares) {
        throw new Error(`Attempting to sell ${shares} shares of ${symbol}, but only ${sharesHeld} are currently held!`)
    }

    // For now, modify holdings in FIFO
    // TODO: Support different mechanisms
    let sharesRemaining = shares
    const holdings = []
    for (const lot of state.holdings) {
        if (sharesRemaining <= 0 || lot.symbol !== symbol) {
            holdings.push(lot)
            continue
        }

        if (lot.shares > sharesRemaining) {
            holdings.push({
                ...lot,
                shares: lot.shares - sharesRemaining,
            })
        }

        sharesRemaining -= lot.shares
    }
    state.holdings = holdings
    // Round to preserve precision
    state.currentBalance = round(state.currentBalance + (shares * point.value.closePrice), 3)

    state.trades.push({
        type: 'SELL',
        symbol,
        date,
        shares,
        price: point.value.closePrice,
        yield: point.value.closeYield,
    })
}