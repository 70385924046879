import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import App from './App.vue'

import OverviewPage from './pages/OverviewPage/OverviewPage.vue'
import ChartsPage from './pages/ChartsPage/ChartsPage.vue'
import CommodityPage from './pages/CommodityPage/CommodityPage.vue'
import BacktestPage from './pages/BacktestPage/BacktestPage.vue'
import CefPage from './pages/CefPage/CefPage.vue'
import FuturesPage from './pages/FuturesPage/FuturesPage.vue'


// Import to include in bundle
import { auth } from './api'

import BootstrapVue3 from 'bootstrap-vue-3'
import './styles.scss'

createApp(App)
    .use(
        BootstrapVue3
    )
    .use(
        createPinia()
    )
    .use(
        createRouter({
            history: createWebHistory(),
            routes: [
                { path: '/', component: OverviewPage },
                { path: '/charts', component: ChartsPage },
                { path: '/commodities', component: CommodityPage },
                { path: '/backtest', component: BacktestPage },
                { path: '/cef', component: CefPage },
                { path: '/futures', component: FuturesPage },
            ]
        })
    )
    .mount('#app')
