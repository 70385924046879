<template>
    <div class="app h-100">
        <div class="row" v-if="stock">
            <div> 
                <div>Symbol: {{ stock.symbol }}</div>
                <div>Market Price: {{ stock.historicalPrices.at(-1).closePrice }}</div>
                <div>NAV: {{ stock.cefMeta.navPrice }} (as of {{stock.cefMeta.navUpdatedDate}})</div>
            </div>
        </div>

        <div class="row h-50 mt-2">
            <CefTable 
                :cefMetas="cefMetas"
            />
        </div>

        <div class="row h-50">
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted, shallowRef } from 'vue'
import { fetchStock, fetchSymbolToCefMeta } from '@/client/api'
import { CefMeta, Stock } from '@/common/financials/models'
import CefTable from '@/client/pages/CefPage/CefTable.vue'
// import { useVueTable, ColumnDef, SortingState, getCoreRowModel, flexRender } from '@tanstack/vue-table'

export default defineComponent({
    components: {
        CefTable
    },
    setup() {
        const stock = ref<Stock>()
        const cefMetas = ref<CefMeta[]>([])
        // const cefSymbols = computed<string[]>(() => Object.keys(symbolToCefMeta.value))
        // const cefMetas = computed<CefMeta[]>(() => Object.values(symbolToCefMeta.value || {}))
        // const sortState = ref<SortingState>()

        fetchSymbolToCefMeta()
            .then(v => {
                cefMetas.value = Object.values(v)
                console.log(cefMetas)
            })
            

        fetchStock({ symbol: 'EVT' })
        .then(v => stock.value = v)

        
        return {
            stock,
            cefMetas,
        }
    },
})
</script>

<style>

</style>
