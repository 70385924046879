
// export default {
//     OneMinute: 60 * 1000,
//     TwoMinutes: 2 * 60 * 1000,
//     FiveMinutes: 5 * 60 * 1000,
//     TenMinutes: 10 * 60 * 1000,
//     FifteenMinutes: 15 * 60 * 1000,
//     ThirtyMinutes: 30 * 60 * 1000,
//     OneHour: 60 * 60 * 1000,
//     TwoHours: 2 * 60 * 60 * 1000,
//     OneDay: 24 * 60 * 60 * 1000,
// }

export default {
    seconds,
    minutes,
    hours,
    days,
}

function seconds(number: number) {
    return number * 1000
}

function minutes(number: number) {
    return number * 60 * 1000
}

function hours(number: number) {
    return number * 60 * 60 * 1000
}

function days(number: number) {
    return number * 24 * 60 * 60 * 1000
}
