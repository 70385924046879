<template>
    <div>
        <HeadlessTable
            :columns="columns"
            :values="store.stocks"
        >
        <template 
        #default="{
            columns,
            values,
        }">
        <table class="overview-table">
            <thead>
                <tr>
                    <th
                        v-for="column of columns"
                        :key="column.header"
                        class="header grid"
                        @click="sort(column)">
                        <span v-if="column === sortedColumn" class="sortIndicator">{{ sortDirection === -1 ? '▼': '▲' }}</span>{{ column.header }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr 
                    v-for="stock of sortedStocks"
                    :key="stock.symbol">
                    <td 
                        v-for="(column, idx) of columns"
                        :key="idx"
                        :class="{
                            grid: true,
                            alignRight: column.annotations.includes('alignRight'),
                            alignLeft: column.annotations.includes('alignLeft'),

                            green: isGreen(column, stock),
                            neutral: isNeutral(column, stock),
                            red: isRed(column, stock),
                        }">
                        {{ column.getDisplayValue(column.accessor(stock)) }}
                    </td>
                </tr>
            </tbody>
        </table>
        </template>
        </HeadlessTable>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/client/state/store'
import HeadlessTable, { HeadlessColumn } from '@/client/components/HeadlessTable.vue'

// https://media.warriortrading.com/2019/07/webull-screen.png


const formatNumber = new Intl.NumberFormat('en-US', { style: 'decimal',  maximumSignificantDigits: 4 }).format
const formatSignedNumber = new Intl.NumberFormat('en-US', { style: 'decimal',  maximumSignificantDigits: 4, signDisplay: 'always' }).format
const formatPercent = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 }).format
const formatVolume = new Intl.NumberFormat('en-US', { style: 'decimal', notation: 'compact' }).format

export default defineComponent({
    components: {
        HeadlessTable,
    },
    setup() {
        const store = useStore()

        
        const percentChangeColumn = {
                header: '% Change',
                accessor: v => (v.price - v.closePrice) / v.closePrice,
                sortAccessor: (a, b) => Math.abs(a) - Math.abs(b),
                getDisplayValue: formatPercent, 
                annotations: [
                    'alignRight',
                    'priceColored',
                ]
            }

        const sortedColumn = ref(percentChangeColumn)
        const sortDirection = ref(-1)
        const sortedStocks = computed(() => {
            // console.log(stocks)
            return store.stocks.slice().sort((a, b) => {
                if (sortDirection.value === -1) {
                    [a, b] = [b, a]
                }
                
                return sortedColumn.value.sortAccessor  ? sortedColumn.value.sortAccessor(sortedColumn.value.accessor(a), sortedColumn.value.accessor(b))
                                                        : sortedColumn.value.accessor(a) - sortedColumn.value.accessor(b)
            })
        })

        function sort(column) {
            if (sortedColumn.value === column) {
                sortDirection.value *= -1
            }
            else {
                sortDirection.value = -1
                sortedColumn.value = column
            }
        }

        return {
            store,
            sort,
            sortedStocks,
            sortedColumn,
            sortDirection,

            isGreen,
            isNeutral,
            isRed,
            
            columns: [
                {
                    header: 'Symbol',
                    accessor: v => v.symbol,
                    getDisplayValue: v => v,
                    annotations: [
                        'alignLeft'
                    ],
                },
                {
                    header: 'Name',
                    accessor: v => v.name,
                    getDisplayValue: v => v,
                    annotations: [
                        'alignLeft'
                    ],
                },
                {
                    header: 'Yield',
                    accessor: v => v.yield,
                    sortAccessor: (a, b) => a - b, // TODO: Move 0?undefined to end
                    getDisplayValue: v => v ? formatPercent(v) : '–',
                    annotations: [
                        'alignRight',
                        'yieldColored',
                    ],
                },
                {
                    header: 'Price',
                    accessor: v => v.price,
                    getDisplayValue: formatNumber,
                    annotations: [
                        'alignRight',
                        'priceColored',
                    ],
                },
                {
                    header: 'Change',
                    accessor: v => v.price - v.closePrice,
                    sortAccessor: (a, b) => Math.abs(a) - Math.abs(b),
                    getDisplayValue: v => v ? formatSignedNumber(v) : '–',
                    annotations: [
                        'alignRight',
                        'priceColored'
                    ],
                },
                percentChangeColumn,
                {
                    header: 'Prev Close',
                    accessor: v => v.closePrice,
                    getDisplayValue: formatNumber, 
                    annotations: [
                        'alignRight',
                    ],
                },
                {
                    header: 'High',
                    accessor: v => v.highPrice,
                    getDisplayValue: formatNumber,
                    annotations: [
                        'alignRight',
                    ],
                },
                {
                    header: 'Low',
                    accessor: v => v.lowPrice,
                    getDisplayValue: formatNumber,
                    annotations: [
                        'alignRight',
                        'priceColored',
                    ],
                },
                {
                    header: 'Volume',
                    accessor: v => v.volume,
                    getDisplayValue: formatVolume,
                    annotations: [
                        'alignRight',
                    ],
                },
            ] as HeadlessColumn[],
        }

        function isGreen(column, stock) {
            if (column.annotations.includes('priceColored')) {
                return stock.price > stock.closePrice
            }
            else if (column.annotations.includes('yieldColored')) {
                return stock.yield > stock.closeYield
            }
        }

        function isNeutral(column, stock) {
            if (column.annotations.includes('priceColored')) {
                return stock.price === stock.closePrice
            }
            else if (column.annotations.includes('yieldColored')) {
                return stock.yield === stock.closeYield
            }
        }

        function isRed(column, stock) {
            if (column.annotations.includes('priceColored')) {
                return stock.price < stock.closePrice
            }
            else if (column.annotations.includes('yieldColored')) {
                return stock.yield < stock.closeYield
            }
        }
    },
})





</script>

<style lang="scss" scoped>
/* TODO: Make standard */
.header {
    color: #696b6e;
    cursor: pointer;
    /* color: #4c4c49; */
}

.grid {
    // border: 1px solid #171717;
}

.green {
    color: #7cc67c;
    /* TODO: Do on tick though */
    animation: upBackground 1s linear;
}

.neutral {
    color: white;
}

.red {
    color: #a32d30;
    animation: downBackground 1s linear;
}

@keyframes upBackground {
  0%   { background: #223839; }
  100% { background: none; }
}

@keyframes downBackground {
  0%   { background: #2f0f0b; }
  100% { background: none; }
}


.overview-table {
    width: 100%;
    border-collapse: collapse;
    td, th {
        padding: 5px 10px;
    }
}

.alignRight {
    text-align: right;
}

.alignLeft {
    text-align: left;
}

.name {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sortIndicator {
    vertical-align: text-bottom;
}
</style>
