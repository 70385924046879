import { CanvasEvent, getMouseCoordinates, RenderContext, Vector } from "./utils"

export default setupCanvasEventHandlers

function setupCanvasEventHandlers(context, hitContext) {
    
    return attachEventHandlers(context, {
        mousemove: onMouseMove,
        mousedown: onMouseDown,
        mouseleave: onMouseLeave,
        mouseup: onMouseUp,
    })

    function onMouseLeave(event) {
        const previouslyHoveredNodeId = context.__render.hoveredNodeId
        const didLeaveHoveredNode = previouslyHoveredNodeId
        if (didLeaveHoveredNode) {
            const previouslyHoveredNode = context.__render.nodeIdToNode.get(previouslyHoveredNodeId)
            previouslyHoveredNode.events.mouseleave(
                convertMouseToCanvasEvent(event, context),
                previouslyHoveredNode
            )
        }
    }
    
    // TODO: Add tests and clean up
    function onMouseMove(event: MouseEvent) {
        const doesHaveEventNodes = Boolean(context.__render.nodeIdToNode.size)
        if (!doesHaveEventNodes) {
            return // TODO: Should prob remove event handler instead
        }
    
        const mousePosition = getMouseCoordinates(event, context.canvas)
        const nodeAtCursor = getNodeAtPoint(mousePosition)
        
        // TODO: Will it work on re-render with static mouse? Need to process mouse on any redraw
        const previouslyHoveredNodeId = context.__render.hoveredNodeId
    
        const didLeaveHoveredNode = previouslyHoveredNodeId && (!nodeAtCursor || (previouslyHoveredNodeId !== nodeAtCursor.id))
        if (didLeaveHoveredNode) {
            const previouslyHoveredNode = context.__render.nodeIdToNode.get(previouslyHoveredNodeId)
            if (previouslyHoveredNode?.events?.mouseleave) {
                previouslyHoveredNode.events.mouseleave(
                    convertMouseToCanvasEvent(event, context),
                    previouslyHoveredNode
                )
            }
        }
    
        // TODO: Throttle drag for perf?
        if (context.__render.eventState.draggingState) {
            context.__render.eventState.draggingState.hasMoved = true
            const draggedNode = context.__render.nodeIdToNode.get(context.__render.eventState.draggingState.nodeId)
            if (draggedNode.events.drag) {
                draggedNode.events.drag({
                        startPosition: context.__render.eventState.draggingState.startPosition,
                        ...convertMouseToCanvasEvent(event, context),
                    },
                    draggedNode
                )
            }
        }
        
        // TODO: What if node no longer exists due to redraw?
        if (!nodeAtCursor) {
            context.__render.hoveredNodeId = undefined
            return
        }
        else {
            context.__render.hoveredNodeId = nodeAtCursor.id // Update to new hoveredNode
        }
        
        const isNewHoveredNode = !previouslyHoveredNodeId || nodeAtCursor.id !== previouslyHoveredNodeId
        if (isNewHoveredNode && nodeAtCursor.events && nodeAtCursor.events.mouseenter) {
            nodeAtCursor.events.mouseenter(
                convertMouseToCanvasEvent(event, context),
                nodeAtCursor
            )
        }
    
        if (nodeAtCursor.events && nodeAtCursor.events.mousemove) {
            nodeAtCursor.events.mousemove(
                convertMouseToCanvasEvent(event, context),
                nodeAtCursor
            )
        }
    }
    
    function onMouseDown(event: MouseEvent) {
        
        // Clear any previous tracked mousedown
        context.__render.eventState.lastMousedownNodeId = null
    
        const doesHaveEventNodes = Boolean(context.__render.nodeIdToNode.size)
        if (!doesHaveEventNodes) {
            return // TODO: Should prob remove event handler instead
        }
    
        const mousePosition = getMouseCoordinates(event, context.canvas)
        const nodeAtCursor = getNodeAtPoint(mousePosition)
        if (!nodeAtCursor) {
            return
        }
    
        // Track latest mousedown element for telling if click
        context.__render.eventState.lastMousedownNodeId = nodeAtCursor.id
    
        if (nodeAtCursor.events.mousedown) {
            nodeAtCursor.events.mousedown(
                convertMouseToCanvasEvent(event, context), 
                nodeAtCursor
            )
        }
    
        context.__render.eventState.draggingState = {
            nodeId: nodeAtCursor.id,
            startPosition: convertMouseToCanvasEvent(event, context),
            hasMoved: false,
        }
    
        // TODO: May want to only dragStart on first mousemove
        if (nodeAtCursor.events.dragStart) {
            nodeAtCursor.events.dragStart(
                context.__render.eventState.draggingState.startPosition, 
                nodeAtCursor
            )
        }
    }
    
    // TODO: Handle mouseup outside canvas etc
    function onMouseUp(event: MouseEvent) {
        const doesHaveEventNodes = Boolean(context.__render.nodeIdToNode.size)
    
        if (!doesHaveEventNodes) {
            return // TODO: Should prob remove event handler instead
        }
    
        const mousePosition = getMouseCoordinates(event, context.canvas)
        const nodeAtCursor = getNodeAtPoint(mousePosition)
    
        const canvasEvent = convertMouseToCanvasEvent(event, context)
    
        if (context.__render.eventState.draggingState) {
            const draggedNode = context.__render.nodeIdToNode.get(context.__render.eventState.draggingState.nodeId)
            if (draggedNode.events.dragEnd) {
                draggedNode.events.dragEnd({
                        startPosition: context.__render.eventState.draggingState.startPosition,
                        ...canvasEvent,
                    },
                    draggedNode
                )
            }
            context.__render.eventState.draggingState = null
        }
    
        if (!nodeAtCursor) {
            return    
        }
    
        if (nodeAtCursor.events.mouseup) {
            nodeAtCursor.events.mouseup(
                canvasEvent,
                nodeAtCursor
            )
        }
    
        const isClick = nodeAtCursor.id === context.__render.eventState.lastMousedownNodeId
        if (isClick && nodeAtCursor.events.click) {
            nodeAtCursor.events.click(
                canvasEvent,
                nodeAtCursor
            )
        }
    }
    
    
    function getNodeAtPoint(point: Vector) {
        point = getProjectedPoint(hitContext.getTransform(), point)
        const [r, g, b] = hitContext.getImageData(point.x, point.y, 1, 1).data
    
        const colorAtCursor = rgbToHex(r, g, b) // NOTE: Will fail because background is white if random color white too
        const nodeId = context.__render.colorToNodeId.get(colorAtCursor)
        return context.__render.nodeIdToNode.get(nodeId)
    }    
}

function convertMouseToCanvasEvent(event: MouseEvent, context: RenderContext): CanvasEvent {
    const { x, y } = getMouseCoordinates(event, context.canvas)
    return {
        x,
        y,
        draggingState: context.__render.eventState.draggingState,
    }
}

function rgbToHex(r, g, b) {
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

function componentToHex(component: number) {
    const hex = component.toString(16);
    return hex.length === 1 ? `0${hex}` : hex
}


function attachEventHandlers(context: CanvasRenderingContext2D, eventNameToHandler: {[eventName: string]: (event: any) => any }) {
    for (const [eventName, handler] of Object.entries(eventNameToHandler)) {
        context.canvas.addEventListener(eventName, handler)
    }

    return () => {
        for (const [eventName, handler] of Object.entries(eventNameToHandler)) {
            context.canvas.removeEventListener(eventName, handler)
        }   
    }
}


function getProjectedPoint(matrix: DOMMatrix, point: Vector) {
    const {
        a: horizontalScaling,
        b: verticalSkewing,
        c: horizontalSkewing,
        d: verticalScaling,
        e: horizontalTranslation,
        f: verticalTranslation,
    } = matrix
    return {
        x: (horizontalScaling * point.x + 
            horizontalSkewing * point.y + 
            horizontalTranslation),
        
        y: (verticalSkewing * point.x + 
            verticalScaling * point.y + 
            verticalTranslation),
    }
}
