import { getSma, getStandardDeviation, getSum } from '@/common/math/utils'
import {
    RollingSmaFn,
    RollingBollingerFn,
    BollingerBands,
} from '@/common/technicals/models'

export {
    createRollingSMAFn,
    createRollingBollingerFn,
    createRollingWindowFn,
}


function createRollingSMAFn({ 
    window 
}: { 
    window: number 
}): RollingSmaFn {
    const sma = createRollingWindowFn({ window })
    return function rollingSMAFn(value: number) {
        return sma(value, windowedValues => getSum(windowedValues) / window)
    }
}


function createRollingBollingerFn({ 
    window,
    // TODO: Make this more flexible
    stdDev = 2
}: { 
    window: number
    stdDev: number
}): RollingBollingerFn {
    
    const bollinger = createRollingWindowFn<BollingerBands>({ window })
    return function rollingBollingerFn(value: number) {
        return bollinger(value, windowedValues => {
            const bandOffset = stdDev * getStandardDeviation(windowedValues)
            const sma = getSma(windowedValues)
            // Array instead?
            return {
                low: sma - bandOffset,
                high: sma + bandOffset,
            }
        })
    }
}

function createRollingWindowFn<T = number>({
    window,
}: {
    window: number
}): ((value: number, computeFn: any) => T) {

    const windowedValues = []
    
    // TODO: Buffer results?
    // const result = []

    return function rollingWindowFn(value, computeFn) {
        windowedValues.push(value)
        
        // Don't compute until enough windowed values
        // TODO: maybe should allow partial computations?
        if (windowedValues.length < window) {
            return null
        }

        const invocationIndex = windowedValues.length - 1
        const result = computeFn(windowedValues, invocationIndex)
        
        // Don't store more than window size
        windowedValues.shift()

        return result
    }

    // let index = 0
    // for (const value of values) {
    //     windowedValues.push(value)

    //     if (windowedValues.length < windowSize) {
    //         result.push(null)
    //     }
    //     else {
    //         result.push(
    //             computeFn(windowedValues, index) // TODO: Not performant, will lead to n^2 problem. Optimize later
    //         )
    //         windowedValues.shift()
    //     }
    //     yield result
    //     index++
    // }

    // return result
}
