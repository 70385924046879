<template>
    <div class="container h-100">
        <div class="row">
            <span class="col">
                <span>DIA <span class="downColor ms-1">-1.23%</span></span>
                <span class="ms-4">SPY <span class="downColor ms-1">-1.09%</span></span>
                <span class="ms-4">QQQ <span class="downColor ms-1">-1.19%</span></span>
            </span>
        </div>
        <div class="row">

            <div class="col nav">
                <div>
                    <router-link class="nav-button" to="/">Overview</router-link>
                    <router-link class="nav-button" to="/charts">Charts</router-link>
                    <router-link class="nav-button" to="/commodities">Commodities</router-link>
                    <router-link class="nav-button" to="/backtest">Backtest</router-link>
                    <router-link class="nav-button" to="/cef">CEF</router-link>
                    <router-link class="nav-button" to="/futures">Futures</router-link>
                </div>
            </div>
            
            <div class="col controlsContainer">
                <div class="controls">
                    <b-dropdown 
                        id="profileDropdown"
                        ref="profileDropdown"
                        :no-caret="true"
                        @hidden="isAddingSymbols = false">

                        <template #button-content>
                            <div class="userIcon"></div>    
                        </template>

                        <template v-if="store.user">
                            <b-dropdown-item v-if="!store.broker" @click="store.connectUserToPlaid">Connect Broker</b-dropdown-item>
                            <b-dropdown-item v-else disabled>
                                <img
                                    v-if="store.broker?.logo" 
                                    alt="Broker Logo"
                                    :src="'data:image/gif;base64,' + store.broker?.logo" 
                                    width="24" 
                                    height="24">
                                {{ store.broker?.name || 'Broker Connected' }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="store.logout">Logout</b-dropdown-item>
                        </template>

                        <template v-else>
                            <b-dropdown-item @click="store.login">Login</b-dropdown-item>
                            <b-dropdown-item>
                                <span 
                                    v-if="!isAddingSymbols" 
                                    @click.capture.stop="isAddingSymbols = true">
                                        {{ store.userSymbols?.length ? 'Update Symbols (CSV)' : 'Add Symbols (CSV)' }}
                                    </span>
                                <span v-else>
                                    <b-form-input
                                        type="text"
                                        :modelValue="store.symbolsCsv"
                                        @keyup.enter="updateSymbolsCsv"
                                        placeholder="AAPL, MSFT, GOOG..."></b-form-input>
                                </span>
                            </b-dropdown-item>
                        </template>

                    </b-dropdown>
                </div>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from '@/client/state/store'

export default defineComponent({
    setup() {
        
        const isAddingSymbols = ref(false)
        const profileDropdown = ref(null)
        const store = useStore()
        
        
        onMounted(async () => {
            await store.initialize()
        })

        return {
            store,
            isAddingSymbols,
            profileDropdown,
            updateSymbolsCsv,
        }
    
        function updateSymbolsCsv(event) {
            store.updateSymbolsCsv(event.target.value)
            isAddingSymbols.value = false
            profileDropdown.value.hide(true)
        }
    },
})
</script>

<style lang="scss">

:root {
  // TODO: Add colors here
//   --bs-body-color: red !important;
    --bs-body-bg: black !important;

    --down-color: #a32d30;
    // TODO: work on theming later
    // --bs-color-primary: #09213d !important;
    // --bs-color-secondary: #2aa5e2 !important;

    // color: #2aa5e2;
    // background: #09213d;
}

.downColor {
    color: var(--down-color);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

body {
  background: black;
  margin: 0;
/* background: #2B2A31 !important; */
/* background: rgb(43, 42, 49) !important; */

/* looks good background: #1D1C21 !important; */

}

html, body, #app {
    width: 100%;
    height: 100%;
    overflow: auto;
}

html * {
    box-sizing: border-box;
}

.nav {
    padding: 20px 0;
    display: grid;
    justify-content: center;
    grid-auto-flow: column;
}

.nav-button {
    color: #999da8;
    text-decoration: none;
    padding: 4px 8px;
    margin: 0 5px;
    border-radius: 5px;

    &:hover {
        color: #2aa5e2;
        background: #09213d;
        transition: 100ms;
    }
    
    &.router-link-active {
        color: #2aa5e2;
        background: #09213d;
    }
}


#login {
    background: none;
    border: none;
}

#login, #logout {
    cursor: pointer;
    padding: 5px;
}

.menu  {
    display: grid; 
    grid-auto-flow: column;
}

.controlsContainer {
    display: grid;
    justify-content: end;
    align-content: center;
}

.controls {
    margin-top: 10px;
    margin-right: 20px;
}

.userIcon {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg version='1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' width='26' height='26'%3E%3Cpath fill='%23FFF' d='M13 0C5.8 0 0 5.8 0 13c0 3.3 1.3 6.4 3.3 8.7C5.7 24.3 9.2 26 13 26c3.9 0 7.4-1.7 9.8-4.4 2-2.3 3.2-5.3 3.2-8.6 0-7.2-5.8-13-13-13zm9.1 19.8c-.8-.7-2.5-1.2-4.1-1.9-2-.8-2.4-1.1-2.4-1.1v-1.9s.7-.6 1-2.3c.5.1.9-.7 1-1.1 0-.4-.1-1.6-.6-1.5-.1-.8 0-1.6 0-2-.1-1.5-1.6-3.1-3.9-3.1S9.3 6.5 9.2 8c0 .4 0 1.2.2 2.1-.6-.1-.6 1-.6 1.5 0 .4.5 1.2 1 1.1.2 1.8 1 2.3 1 2.3v1.9s-.5.3-2.4 1.1c-1.7.7-3.4 1.2-4.2 1.9C2.5 18 1.6 15.6 1.6 13 1.6 6.7 6.7 1.6 13 1.6S24.4 6.7 24.4 13c0 2.6-.9 4.9-2.3 6.8z'/%3E%3C/svg%3E")
}


#profileDropdown {
    background: none;
    border: none;
    padding: 5px;
}

.dropdown-item { 
    cursor: pointer;
}
</style>
