import Duration from './Time'

// NOTE: This defines gaps between ticks

const padding = 0.2
export default {
    OneMinute: {
        label: '1m',
        defaultWindowSize: Duration.hours(3), 
        zoomStep: Duration.minutes(5),
        size: Duration.minutes(1) - (Duration.minutes(1) * padding),
        dateWidth: 70,
        dateFormat: `d MMM ’kk T`,
        unitSize: Duration.minutes(1),
    },
    OneHour: {
        label: '1h',
        defaultWindowSize: Duration.days(7),
        zoomStep: Duration.hours(1),
        size: Duration.hours(1) - (Duration.hours(1) * padding),
        dateWidth: 70,
        dateFormat: `d MMM ’kk T`,
        unitSize: Duration.hours(1),
    },
    OneDay: {
        label: '1d',
        defaultWindowSize: Duration.days(180),
        zoomStep: Duration.days(1),
        size: Duration.days(1) - (Duration.days(1) * padding),
        dateWidth: 70,
        dateFormat: `d MMM ’kk T`,
        unitSize: Duration.days(1),
    },
}
