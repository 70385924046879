<template>
    <div class="oilPrice">
        <div class="cardsContainer">
            <CommodityCard 
                v-for="commodity of commodities"
                :key="commodity.type"
                :commodity="commodity"
                :selected="commodity.selected" />
        </div>
        <div class="focusedChart">
            <CommodityChart />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CommodityCard from './CommodityCard.vue'
import CommodityChart from './CommodityChart.vue'

export default defineComponent({
    components: {
        CommodityCard,
        CommodityChart,
    },
    data() {
        return {
            commodities: [
                {
                    type: 'Crude Oil',
                    details: 'Brent',
                    selected: true,
                },
                {
                    type: 'Nat Gas',
                    details: 'USA',
                    selected: false,
                }
            ],
        }
    },
    mounted() {
    }
})
</script>

<style lang="scss" scoped>
.oilPrice {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    grid-auto-flow: row;
    align-content: start;
    grid-gap: 20px;
    margin-top: 40px;
}

.cardsContainer {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content;
    grid-gap: 20px;
}
</style>