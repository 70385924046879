<template>
    <div class="tickerCard container">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col-3">
                        <div 
                            class="bg-positive rounded-circle text-center"
                            :style="{ width: `24px` }">{{ $props.symbol[0] }}</div>
                    </div>
                    <div class="col">
                        <div>{{ $props.symbol }}</div>
                        <div class="text-muted">{{ $props.name }}</div>
                    </div>
                </div>
            </div>

            <div class="col-5">
                <div 
                    class="rounded text-end pe-1"
                    :class="{
                        'bg-positive': priceChange > 0,
                        'bg-negative': priceChange < 0,
                    }"
                    :title="lastUpdatedTime"
                >{{ $props.price || $props.prevPrice }}</div>
                <div>
                    <div 
                        class="col text-end rounded"
                        :class="{
                            'text-positive': priceChange > 0,
                            'text-negative': priceChange < 0,
                            'animate-bg-positive': shouldAnimate && priceChange > 0,
                            'animate-bg-negative': shouldAnimate && priceChange < 0,
                        }"
                        @animationend="shouldAnimate = false">
                            {{ hasValidPriceChange ? `${priceChange} ${priceChange > 0 ? '+' : ''}${priceChangePercent.toFixed(2)}%` : 'N/A' }}
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, watch, ref } from 'vue'
import { Temporal } from '@js-temporal/polyfill';

export default defineComponent({
    props: {
        symbol: { type: String as PropType<string>, required: true },
        name: { type: String as PropType<string>, required: true },
        price: { type: Number as PropType<number>, required: true },
        prevPrice: { type: Number as PropType<number>, required: true },
        lastUpdated: { type: String as PropType<string>, required: true },
    },
    setup(props) {
        
        const hasValidPriceChange = computed(() => props.price != null && props.prevPrice != null)
        const priceChange = computed(() => hasValidPriceChange.value ? props.price - props.prevPrice : null)
        const priceChangePercent = computed(() => hasValidPriceChange ? (100 * (props.price - props.prevPrice) / props.price) : null)

        const lastUpdatedTime = computed(() => Intl.DateTimeFormat(undefined, {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        }).format(Date.parse(`${props.lastUpdated}-05:00`))) // TODO: Note raw form is in central time zone. Why we need?

        const shouldAnimate = ref(true)
        watch(priceChange, () => {
            shouldAnimate.value = true
        })



        return {
            hasValidPriceChange,
            priceChange,
            priceChangePercent,
            shouldAnimate,
            lastUpdatedTime,
        }
    },
})
</script>

