import { CanvasContext, RenderContext, RenderNode, ShapeEvents, Tween, Vector, CanvasEvent } from './utils';
import isDeepEquals from 'fast-deep-equal'
import setupCanvasEventHandlers from './setupCanvasEventHandlers';

// TODO: Support Offscreen Canvas
// https://developers.google.com/web/updates/2018/08/offscreen-canvas
// http://man.hubwiz.com/docset/JavaScript.docset/Contents/Resources/Documents/developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas.html
// https://html5rocks.com/en/tutorials/canvas/performance/
// https://stackoverflow.com/questions/39675336/is-recommended-to-avoid-save-restore-javascript
const typeToDrawFn: {
    [key: string]: (params: { 
        node: RenderNode
        context: CanvasContext
    }) => void
} = {
    container: () => {},
    path: drawPath,
    path2d: drawPath2d,
    line: drawLine,
    bezierCurve: drawBezierCurve,
    basisSpline: drawBasisSpline,
    rect: drawRect,
    text: drawText,
    circle: drawCircle,
}

const isDebugging = false // TODO: Allow as arg
const isOffscreenCanvasSupported = 'OffscreenCanvas' in window
const createUniqueId = createUniqueIdGenerator()
export {
    container,
    rect,
    circle,
    line,
    path, // TODO: rename
    bezierCurve,
    basisSpline,
    text,
    path2d,

    draw,
    createDeviceScaledCanvas,
}

export type {
    RenderNode,
    CircleNode,
    LineParams,
    CanvasEvent,
}



function createDeviceScaledCanvas({ width, height }: { width: number; height: number }) {
    const devicePixelRatio = window.devicePixelRatio || 1
    const canvas = document.createElement('canvas')
    
    canvas.width = Math.floor(width * devicePixelRatio)
    canvas.height = Math.floor(height * devicePixelRatio)
    
    canvas.style.width = `${width}px`
    canvas.style.height = `${height}px`

    canvas.getContext('2d').scale(
        devicePixelRatio, // Horizontal scaling
        devicePixelRatio, // Vertical scaling
    )
    return canvas
}

//@ts-ignore
function drawPath({ node: { points, area }, context }: {
    node: PathNode
    context: CanvasRenderingContext2D
}) {    
    if (points.length < 2) { // TODO: support circle
        return
    }

    context.moveTo(
        points[0].x, 
        points[0].y
    )
    
    for (let i = 1; i < points.length; i++) {
        context.lineTo(
            points[i].x, 
            points[i].y
        )
    }
    
    // TODO: Move to dedicated area renderer. figure out best way to model
    // TODO: Do totally differently
    // TODO:
    if (area) {
        context.stroke()
        // const or = context.strokeStyle
        // context.strokeStyle = 'black' // TODO: This is needed to prevent side of area from stroke
        
        // @ts-ignore
        context.lineTo(points[points.length - 1].x, area.bottom)
        context.lineTo(points[0].x, area.bottom)
        context.fill()
        context.beginPath() 
        // context.stroke()
        
        // context.strokeStyle = or
    }
}

function drawPath2d({ 
    node, 
    context,
}: {
    node: Path2dNode
    context: CanvasRenderingContext2D
}) {    

    // TODO: fix
    const path = new Path2D(node.d)
    context.stroke(path)
}

// https://stackoverflow.com/questions/16653905/html5-canvas-performance-very-poor-using-rect



function drawLine({ node: { from, to }, context }: {
        node: LineNode
        context: CanvasRenderingContext2D
    }) {    
    context.moveTo(
        from.x, 
        from.y
    )
    context.lineTo(
        to.x, 
        to.y
    )
}


function drawBasisSpline({ node, context}: {
    node: BasisSplineNode
    context: CanvasRenderingContext2D
}) {

    if (node.controlPoints.length < 1) {
        throw new Error('Not enough points!')
    }
    
    context.moveTo(
       node.from.x, 
       node.from.y
    )

    for (let i = 0; i < node.controlPoints.length; i++) {
        const nextControlPoint = node.controlPoints[i + 1] || node.to
        context.quadraticCurveTo(
            node.controlPoints[i].x, 
            node.controlPoints[i].y, 
            // x control point
            (node.controlPoints[i].x + nextControlPoint.x) / 2, 
            
            // y control point
            (node.controlPoints[i].y + nextControlPoint.y) / 2,
        )
    }

    // curve through the last two points
    context.quadraticCurveTo(
        node.controlPoints[node.controlPoints.length - 1].x, 
        node.controlPoints[node.controlPoints.length - 1].y, 
        node.to.x,
        node.to.y,
    )
}

// TODO: Make flexible
// TODO: Combine with line?
function drawBezierCurve({ node, context }: {
    node: BezierCurveNode
    context: CanvasRenderingContext2D
}) {
    context.moveTo(node.from.x, node.from.y)
    context.bezierCurveTo(
        node.fromControlPoint.x,
        node.fromControlPoint.y,
        node.toControlPoint.x,
        node.toControlPoint.y,
        node.to.x,
        node.to.y,
    )
}

// TODO: Should default to top left as origin?
function drawRect({ node, context }: {
    node: RectNode
    context: CanvasRenderingContext2D
}) {
    if (isDebugging && !node.style.fillStyle && !node.style.strokeStyle) {
        console.warn(`Attempting to draw rect (id: ${node.id}) without fill or stroke styling`)
    }
    
    // NOTE: Only use transforms if necessary, like rotation.
    // Why? Because it's performance heavy.
    const isUsingTransform = !!node.angle
 
    const dimensions = node.angle ? {
        width: Math.sin(node.angle) * node.width + Math.cos(node.angle) * node.height,
        height: Math.cos(node.angle) * node.width + Math.sin(node.angle) * node.height,
    } : node

    // const isUsingTransform = 
    // ADAMTODO: Offset thing is not working
    // const drawingOrigin = isRotated ? {
    //     x: node.x,// - (dimensions.width / 2), // TODO: If rotated, need to offset x
    //     // x: node.x - (dimensions.width / 2), // TODO: If rotated, need to offset x
    //     y: node.y - (dimensions.height / 2)
    // } : node;

    const drawingOrigin = isUsingTransform ? {
        // Offset by half to compensate for translation below
        x: -(node.width / 2),
        y: -(node.height / 2),
    } : node;


    if (isUsingTransform) {
        context.save()
        // TODO: Use setTransform for better perf?
        context.translate(
            node.x + (node.width / 2),
            node.y + (node.height / 2),
        )
        
        if (node.angle) {
            context.rotate(node.angle)
        }
    }
    
    if (node.radius) {
        
        // TODO: Why the math?
        const radiusY = Math.min(node.height / 2, node.radius)
        const radiusX = Math.min(node.width / 2, node.radius)

        context.moveTo(
            drawingOrigin.x, 
            drawingOrigin.y
        )
        context.arcTo(
            node.width + drawingOrigin.x,
            drawingOrigin.y,
            node.width + drawingOrigin.x,
            node.height + drawingOrigin.y, 
            radiusY,
        )
        context.arcTo(
            node.width + drawingOrigin.x, 
            node.height + drawingOrigin.y, 
            drawingOrigin.x, 
            node.height + drawingOrigin.y, 
            radiusX,
        )
        context.arcTo(
            drawingOrigin.x, 
            node.height + drawingOrigin.y, 
            drawingOrigin.x, 
            drawingOrigin.y, 
            radiusY,
        )
        context.arcTo(
            drawingOrigin.x, 
            drawingOrigin.y, 
            node.radius + drawingOrigin.x, 
            drawingOrigin.y, 
            radiusX,
        )
    }
    else {
        context.rect(
            drawingOrigin.x, 
            drawingOrigin.y,
            node.width, 
            node.height,
        )
    }

    if (isUsingTransform) {
        context.restore()
    }
    
    // resetTransform(context)
}


function drawCircle({ 
    node, 
    context 
}: { 
    node: CircleNode, 
    context: CanvasRenderingContext2D 
}) {

    if (!node.style.fillStyle && !node.style.strokeStyle) {
        console.warn(`Attempting to draw circle (id: ${node.id}) without fill or stroke styling`)
    }
    
    context.moveTo(
        node.x + node.radius,
        node.y,
    )
    context.arc(
        node.x,
        node.y,
        node.radius,
        0, // startAngle,
        Math.PI * 2, // endAngle,
    )
}

function drawImage({ 
    node,
    spriteCanvas, 
    context 
}: { 
    node: any
    spriteCanvas: HTMLCanvasElement
    context: CanvasRenderingContext2D 
}) {

    // TODO: Make generic property, remove special conditional
    const isAnchoredAtCenter = node.type === 'circle'
    const origin = isAnchoredAtCenter ? {
        // x: node.x - (node.radius /2),
        // y: node.y - (node.radius /2),

        x: node.x - node.radius,
        y: node.y - node.radius,

        // x: node.x,
        // y: node.y,
    } : node

    // TODO: Fix, hardcoded to circle
    context.drawImage(
        spriteCanvas,
        origin.x,
        origin.y,
        spriteCanvas.width / devicePixelRatio,
        spriteCanvas.height / devicePixelRatio,
    )
}


function drawText({ node, context }: {
    node: TextNode
    context: CanvasRenderingContext2D
}) {
    context.fillText(
        node.text, 
        node.x, 
        node.y
    )
}

function draw(node: RenderNode, context: RenderContext) {
    const isFirstTimeSeeingCanvas = !context.__render       
    if (isFirstTimeSeeingCanvas) {
        const hitContext = createHitContext(context)
        context.__render = {
            hitContext,
            
            // TODO: Perf, may be creating big thing
            // spriteSheetContext: createSpriteSheetContext(context),

            // TODO: Perf, will grow over
            colorToNodeId: new Map(),
            nodeIdToNode: new Map(), // TODO: Memory leak... will grow over time... need to clear map on each draw
            
            eventState: {
                lastMousedownNodeId: null,
            },
            // ADAMTODO: Don't add event handlers if no nodes require
            removeEventHandlers: setupCanvasEventHandlers(context, hitContext)
        }
    }
    else {
        // ADAMTODO: Should we really explicitly clear?
        context.clearRect(0, 0, context.canvas.width, context.canvas.height)

        const hitContext = context.__render.hitContext
        const doesHitCanvasSizeNeedAdjusting = hitContext && (
            context.canvas.width !== hitContext.canvas.width ||
            context.canvas.height !== hitContext.canvas.height
        )
        // TODO: Why doesn't hitcanvas redraw?
        if (doesHitCanvasSizeNeedAdjusting) {
            // Does this resize twice?
            hitContext.canvas.width = context.canvas.width
            hitContext.canvas.height = context.canvas.height
            if (hitContext.canvas instanceof HTMLCanvasElement) {
                hitContext.canvas.style.width = context.canvas.style.width
                hitContext.canvas.style.height = context.canvas.style.height
            }
        }
    }


    const hitContext = context.__render.hitContext
    if (hitContext) {
        context.__render.hitContext = hitContext
        // Clear canvas for new hit drawing
        hitContext.fillStyle = 'white'
        hitContext.fillRect(
            0, 
            0,
            hitContext.canvas.width,
            hitContext.canvas.height
        )
    }

    // Render DFS order
    dfs(node, { context, hitContext }) // TODO: Format in more readable ways

    if (isDebugging) {
        // @ts-ignore
        context.canvas.parentNode.append(context.__render.hitContext.canvas)
    }

    
}

function dfs(node: RenderNode, { 
    context, 
    hitContext,
}: { 
    context: RenderContext
    hitContext: CanvasContext
}) {

    const nodeStack = [node]

    const seenIds = new Set()
    
    let activeStyle = null
    let restoreStyle = () => {}
    
    // NOTE: Temp just for demoing perf
    const typeToTexture = {}
    
    

    const debugInfo = {
        fillCalls: 0,
        strokeCalls: 0,
        hitCanvasFillCalls: 0,
        hitCanvasStrokeCalls: 0,
        totalCalls: 0,
        nodeOrder: [],
    }
    
    while (nodeStack.length) {
        innerDfs()
    }

    // Flush final queued Draw
    if (activeStyle && activeStyle.fillStyle) {
        context.fill()
        debugInfo.fillCalls++
        debugInfo.totalCalls++
    }
    if (activeStyle && activeStyle.strokeStyle) {
        context.stroke() // TODO: Why does this call trigger the area call?
        debugInfo.strokeCalls++
        debugInfo.totalCalls++
    }
    restoreStyle()

    if (isDebugging) {
        console.log(debugInfo)
        context.canvas.parentNode.querySelectorAll('.sprite-canvas').forEach(e => e.remove())
        for (const spriteCanvas of Object.values(typeToTexture)) {
            // @ts-ignore
            context.canvas.parentNode.append(spriteCanvas)
        }

    }

    // TODO: Max
    function innerDfs() {
        // const node = nodeStack.shift() as RenderNode
        const node = nodeStack.pop() as RenderNode
        
        if (node.type === 'container') {
            // TODO: Perf
            // nodeStack.unshift(...(node.children || []))
            for (let i = 0; i < node.children.length; i++) {
                // Add backwards for perf. push vs unshift
                nodeStack.push(node.children[node.children.length - i - 1])
            }
            return
        }

        // Add metadata to the node
        if (node.events && !node.__render) { // TODO: Might break due to events check. What if had events before but not now?
            const prevNodeReference = context.__render.nodeIdToNode.get(node.id)
            node.id = node.id || createUniqueId()
            node.__render = {
                hitDetectionColor: prevNodeReference ? prevNodeReference.__render.hitDetectionColor : (node.events ? generateNonConflictingHex(context.__render.colorToNodeId) : undefined),
            }
            
            // TODO: Should we run this every draw? maybe can do only once
            if (node.__render.hitDetectionColor) {
                context.__render.colorToNodeId.set(node.__render.hitDetectionColor, node.id)
            }
        }

        // TODO: Do we need in mainline?
        if (isDebugging) {
            // TODO: Do we really need ids?
            const isAlreadyEncounteredId = seenIds.has(node.id)
            if (isAlreadyEncounteredId) {
                throw new Error(`ID: "${node.id}" has already been seen. Do not pass non-unique IDs in render tree`)
            }
            else {
                seenIds.add(node.id)
            }
        }


        

        // TODO: Deep equals performance hit?
        // TODO: More sophisticated deep equal? Ignore null/undefined
        const doesNodeMatchActiveStyle = isDeepEquals(node.style, activeStyle)
        // const doesNodeMatchActiveStyle = node.style === activeStyle
        const shouldDrawQueuedShapes = activeStyle && !doesNodeMatchActiveStyle
        if (shouldDrawQueuedShapes) {
            if (activeStyle.fillStyle) {
                context.fill()
                debugInfo.fillCalls++
                debugInfo.totalCalls++
            }
            if (activeStyle.strokeStyle) {
                context.stroke()
                debugInfo.strokeCalls++
                debugInfo.totalCalls++
            }
        }
        
        if (!activeStyle || !doesNodeMatchActiveStyle) {
            restoreStyle()
            activeStyle = node.style
            context.beginPath()
            restoreStyle = applyStyle(node.style, context)
        }

        const renderFn = typeToDrawFn[node.type]
        // TODO: Node offset is because radius is different between sprites
        // Yet we choose to use a single sprite anyway, because wrong implementations
        // if (node.spriteType) {
        //     if (isFirstTime && node.spriteType === 'ghost') {
        //         console.log({ ...node })
        //         isFirstTime = false
        //     }
        //     const spriteCanvas = typeToTexture[node.spriteType] = typeToTexture[node.spriteType] || createTexture({
        //         node,
        //         renderFn,
        //     })
        //     drawImage({
        //         node,
        //         spriteCanvas,
        //         context,
        //     })
        // }
        // else {
            renderFn({
                node,
                context
            })
        // }

        // TODO: Support container events, may need same color for all node
        // TODO: Perf of separate draw call for each unique item? May be poor
        if (node.events) {

            // Only track event nodes
            context.__render.nodeIdToNode.set(node.id, node)
            
            // TODO: What is performance of hidden canvas drawing
            const hitNode = node.hitArea || node
            const hitRenderNode = {
                ...hitNode,
                style: {
                    // @ts-ignore
                    ...hitNode.style,
                    fillStyle: node.__render.hitDetectionColor,
                    strokeStyle: node.__render.hitDetectionColor,
                    alpha: 1,
                }
            }
            const restoreStyle = applyStyle(hitRenderNode.style, hitContext)
            hitContext.beginPath()
            renderFn({
                node: hitRenderNode,
                context: hitContext
            })
            
            // TODO: Support separate regions considered same object
            if (node.style.fillStyle) {
                hitContext.fill()
                debugInfo.hitCanvasFillCalls++
                debugInfo.totalCalls++
            }
            if (node.style.strokeStyle) {
                hitContext.stroke()
                debugInfo.hitCanvasStrokeCalls++
                debugInfo.totalCalls++
            }
            restoreStyle()
        }

        if (isDebugging) {
            debugInfo.nodeOrder.push(node)
        }

    }
}

function container(children: RenderNode[]) {
    return {
        type: 'container',
        children
    }
}

function line(params: LineParams): LineNode {
    // @ts-ignore
    const line: LineNode = {
        type: 'line',
        ...params,
    }

    if (params.transition) {
        line.transition = params.transition(line)
    }

    if (params.hitSizeMultiplier) {
        line.hitArea = {
            ...line,
            style: {
                ...line.hitArea,
                lineWidth: line.style.lineWidth * params.hitSizeMultiplier,
            }
        }
    }

    return line
}

function path(params: PathParams): PathNode {
    // @ts-ignore
    const path: PathNode = {
        type: 'path',
        ...params,
    }
    
    if (params.transition) {
        path.transition = params.transition(path)
    }

    return path
}

function path2d(params): Path2dNode {
    // @ts-ignore
    const path2d: Path2dNode = {
        type: 'path2d',
        ...params,
    }

    if (params.transition) {
        path2d.transition = params.transition(path)
    }

    return path2d
}

function bezierCurve(params: BezierCurveParams): RenderNode {
    // @ts-ignore
    const bezierCurve: BezierCurveNode = {
        type: 'bezierCurve',
        ...params,
    }

    if (params.transition) {
        bezierCurve.transition = params.transition(bezierCurve)
    }

    if (params.hitSizeMultiplier) {
        bezierCurve.hitArea = {
            ...bezierCurve,
            style: {
                ...bezierCurve.hitArea,
                lineWidth: bezierCurve.style.lineWidth * params.hitSizeMultiplier,
            }
        }
    }

    return bezierCurve
}

function basisSpline(params: BasisSplineParams): BasisSplineNode {
    // @ts-ignore
    const basisSpline: BasisSplineNode = {
        type: 'basisSpline',
        ...params,
    }

    if (params.transition) {
        basisSpline.transition = params.transition(basisSpline)
    }

    if (params.hitSizeMultiplier) {
        basisSpline.hitArea = {
            ...basisSpline,
            style: {
                ...basisSpline.hitArea,
                lineWidth: basisSpline.style.lineWidth * params.hitSizeMultiplier,
            }
        }
    }

    return basisSpline
}

// NOTE: Mutable for perf reasons (GC) - Assess whether this is best
// TODO: Make rest mutable too
function rect(params: RectParams): RenderNode {
    // @ts-ignore
    const rect: RectNode = {
        type: 'rect',
        ...params,
    }
    

    if (params.transition) {
        rect.transition = params.transition(rect)
    }
    
    // Expand uniformly
    if (params.hitSizeMultiplier) {
        rect.hitArea = {
            ...rect,
            // @ts-ignore
            x: rect.x,
            y: rect.y,
            width: rect.width * params.hitSizeMultiplier,
            height: rect.height * params.hitSizeMultiplier,
        }
    }

    return rect;
}

function circle(params: CircleParams): RenderNode {
    // @ts-ignore
    const circle: CircleNode = {
        type: 'circle',
        ...params,
    }

    if (params.transition) {
        circle.transition = params.transition(circle)
    }

    // Expand uniformly
    if (params.hitSizeMultiplier) {
        circle.hitArea = {
            ...circle,
            // @ts-ignore
            radius: circle.radius * params.hitSizeMultiplier,
        }
    }

    return circle
}

function text(params: TextParams): RenderNode {
    // @ts-ignore
    const text: TextNode = {
        type: 'text',
        ...params,
    }

    if (params.transition) {
        text.transition = params.transition(text)
    }
    
    return text
}

// To avoid performance hit of save/restore
function applyStyle(style: { [key: string]: any }, context: CanvasContext) {

    const originalStyle: any = {};
    for (const [styleName, styleValue] of Object.entries(style)) {
        
        // TODO: Clean up redundant check below
        if (styleName === 'lineDash') {
            originalStyle.lineDash = context.getLineDash()
            context.setLineDash(styleValue)
        }
        // TODO: Ensure we're using alpha optimizations
        else if (styleName === 'alpha') { // TODO: Rename opacity?
            originalStyle.alpha = context.globalAlpha
            context.globalAlpha = styleValue
        }
        else {
            originalStyle[styleName] = context[styleName];
            context[styleName] = styleValue;
        }
        
    }   

    return restoreOriginalStyle;

    function restoreOriginalStyle() {
        for (const [styleName, styleValue] of Object.entries(originalStyle)) {
            
            if (styleName === 'lineDash') {
                // @ts-ignore
                context.setLineDash(styleValue)
            }
            else if (styleName === 'alpha') {
                context.globalAlpha = styleValue as number
            }
            else {
                context[styleName] = styleValue
            }
        }
    }
}


interface RectParams {
    id?: string
    x: number 
    y: number
    width: number
    height: number
    radius?: number // For reounded rects
    hitSizeMultiplier?: number
    angle?: number // Expressed in Radians
    events?: ShapeEvents<RectNode>
    style: RectNode['style']
    transition?: (node: RectNode) => Tween
}

interface CircleParams {
    id?: string
    x: number 
    y: number
    radius: number
    hitSizeMultiplier?: number
    events?: ShapeEvents<CircleNode>
    style: CircleNode['style']
    transition?: (node: CircleNode) => Tween
}


interface LineParams {
    id?: string
    from: Vector
    to: Vector
    hitSizeMultiplier?: number
    events?: ShapeEvents<LineNode>
    style: LineNode['style']
    transition?: (node: LineNode) => Tween
}

interface PathParams {
    id?: string
    points: Vector[] // TODO: support accessors
    // TODO: Do this better
    // pointStyle?: { type: 'circle'; radius: number }
    hitSizeMultiplier?: number
    events?: ShapeEvents<PathNode>
    style: PathNode['style']
    transition?: (node: PathNode) => Tween
}

interface BezierCurveParams {
    id?: string
    from: Vector
    to: Vector
    fromControlPoint: Vector
    toControlPoint: Vector
    hitSizeMultiplier?: number
    events?: ShapeEvents<BezierCurveNode>
    style: BezierCurveNode['style']
    transition?: (node: BezierCurveNode) => Tween
}

interface BasisSplineParams {
    id?: string
    from: Vector
    to: Vector
    controlPoints: Vector[]
    hitSizeMultiplier?: number
    events?: ShapeEvents<BasisSplineNode>
    style: BasisSplineNode['style']
    transition?: (node: BasisSplineNode) => Tween
}

interface TextParams {
    id?: string
    text: string
    x: number
    y: number
    // TODO: Default to rect backing
    // hitSizeMultiplier?: number
    angle?: number // Expressed in Radians
    events?: ShapeEvents<TextNode>
    style: TextNode['style']
    transition?: (node: TextNode) => Tween
}

function createHitContext(context: CanvasRenderingContext2D): CanvasContext {
    const canvasClone = context.canvas.cloneNode() as HTMLCanvasElement
    // @ts-ignore
    const hitCanvas = (isOffscreenCanvasSupported && !isDebugging) ? canvasClone.transferControlToOffscreen() : canvasClone
    const hitContext = hitCanvas.getContext('2d', { 
        alpha: false, 
         // TODO: Useful?
        // willReadFrequently: true,
    })
    
    // TODO: Ensure these transforms are synced
    hitContext.setTransform(context.getTransform())

    // @ts-ignore
    hitContext.isHitContext = true
    return hitContext
}

function generateNonConflictingHex(existingHexToValue: Map<string, string>): string {
    while (true) {
        const newHex = generateRandomHex()
        if (!existingHexToValue.has(newHex)) {
            return newHex
        }
    }
}

function generateRandomHex() {
    return `#${Math.floor(Math.random()*16777215).toString(16).padStart(6, '0')}`
}

// TODO: Actually implement 100% robust unique id generator. This can produce
// conflicting IDs if user happens to mimic the id structure here
function createUniqueIdGenerator() {
    let id = 1
    return () => `${id++}_GENERATED`
}



interface ContainerNode extends RenderNode {
    type: 'container'
    children: RenderNode[]
}

interface RectNode extends RenderNode {
    type: 'rect'
    x: number
    y: number
    width: number
    height: number
    radius?: number
    style: { 
        fillStyle?: string
        strokeStyle?: string
        alpha?: number
    }
}


interface CircleNode extends RenderNode {
    type: 'circle'
    x: number
    y: number
    radius: number
    style: { 
        fillStyle?: string
        strokeStyle?: string
        lineDash?: Iterable<number>
        alpha?: number
    }
}

interface TextNode extends RenderNode {
    type: 'text'
    text: string
    x: number
    y: number
    style: {
        font: string
        textAlign: 'left' | 'right' | 'center' | 'start' | 'end'
        textBaseline: 'top' | 'hanging' | 'middle' | 'alphabetic' | 'ideographic' | 'bottom'
        fillStyle: string
        alpha?: number
    }
}

interface LineNode extends RenderNode {
    from: Vector
    to: Vector
    style: {
        strokeStyle?: string
        lineWidth?: number
        lineDash?: Iterable<number>
        alpha?: number
    }
}

interface PathNode extends RenderNode {
    points: Vector[]
    style: {
        strokeStyle?: string
        lineWidth?: number
        lineDash?: Iterable<number>
        alpha?: number
    }
}

interface Path2dNode extends RenderNode {
    d: string
    style: {
        strokeStyle?: string
        lineWidth?: number
        lineDash?: Iterable<number>
        alpha?: number
    }
}

interface BezierCurveNode extends RenderNode {
    from: Vector
    to: Vector
    fromControlPoint: Vector
    toControlPoint: Vector
    style: {
        strokeStyle?: string
        lineWidth?: number
        lineDash?: Iterable<number>
        alpha?: number
    }
}

interface BasisSplineNode extends RenderNode {
    from: Vector
    to: Vector
    controlPoints: Vector[]
    style: {
        strokeStyle?: string
        lineWidth?: number
        lineDash?: Iterable<number>
        alpha?: number
    }
}


// Used over save/restore as it's more performant
// function resetTransform(context: CanvasRenderingContext2D) {
//     context.setTransform(
//         window.devicePixelRatio,
//         0,
//         0,
//         window.devicePixelRatio,
//         0,
//         0
//     )
// }

// function createTexture({ node, renderFn, }) {
         
//     // Assuming node is circle for now TODO: fix
//     // Leaving room for stroke: TODO: Use actual clean code
//     const width = (node.radius * 2)// + 3
//     const height = node.radius * 2// + 3

//     const textureCanvas = createDeviceScaledCanvas({
//         width,
//         height,
//     })
//     textureCanvas.className = `sprite-canvas ${node.spriteType}`
    
//     const textureContext = textureCanvas.getContext('2d')
//     applyStyle(node.style, textureContext)
    
//     renderFn({
//         node: {
//             ...node,
//             // Render at origin
//             // TODO: Change this for circle vs square
//             x: width / 2,
//             y: height / 2,
//         },
//         context: textureContext,
//     })

//     if (node.style.fillStyle) {
//         textureContext.fill()
//         // debugInfo.fillCalls++
//         // debugInfo.totalCalls++
//     }

//     if (node.style.strokeStyle) {
//         textureContext.stroke()
//         // debugInfo.strokeCalls++
//         // debugInfo.totalCalls++
//     }
    
//     return textureCanvas
// }
