<template>
    <div 
        class="card"
        :class="{
            selected,
        }">
        <div 
            class="cardChartContainer"
            ref="cardChartContainer">
        </div>
        <div class="cardText">
            <div>
                <span>{{ commodity.type }}</span> 
                <span 
                    class="priceChange"
                    :class="mostRecentPriceChangeStatus">
                    <span class="priceChangeIcon">
                        {{ (mostRecentPriceChange > 0) ? '⏶' : '⏷' }}
                        <!-- {{ (mostRecentPriceChange > 0) ? '▲' : '▼' }} -->
                    </span>
                    <span>{{ mostRecentPriceChange.toFixed(2) }}</span>
                </span>
            </div>
            <!-- 🞁 🞃-->
            <!-- ▲ ▼  -->
            <!-- ⏶ ⏷ -->
            <!-- ▴ ▾   -->
            <div class="cardDetails">{{ commodity.details }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import { defineComponent, PropType } from 'vue';
import * as rt from '@/client/render/renderTree'
import oilPrices, { OilPrice } from './mockOilPrice'

// TODO: Provide simple mechanism to make scales/axes easier
import { scaleLinear, ScaleLinear, scaleTime, ScaleTime } from 'd3-scale'

interface Commodity {
    type: string
    details: string
}

export default defineComponent({
    props: {
        commodity: { type: Object as PropType<Commodity>, required: true },
        selected: { type: Boolean as PropType<boolean>, required: true },
    },
    data(): {
        cardChart: HTMLCanvasElement
        chart: {
            width: number
            height: number
        }
    } {
        return {
            cardChart: null,
            chart: {
                width: 120,
                height: 60,
            }
        }
    },
    computed: {
        cardChartContainer(): HTMLDivElement {
            // @ts-ignore
            return this.$refs.cardChartContainer
        },
        mostRecentPriceChange() {
            return oilPrices.at(-1).Close - oilPrices.at(-2).Close
        },
        mostRecentPriceChangeStatus() {
            const { mostRecentPriceChange } = this
            if (mostRecentPriceChange === 0) {
                return 'neutral'
            }

            return mostRecentPriceChange > 0 ? 'positive' : 'negative'
        }
    },
    mounted() {
        
        const cardChart = rt.createDeviceScaledCanvas({
            width: this.chart.width,
            height: this.chart.height,
        })

        // @ts-ignore
        this.cardChartContainer.append(cardChart)
        this.cardChart = cardChart

        const prices = oilPrices.slice(-25)
        const xScale = this.xScale(prices)
        const yScale = this.yScale(prices)
        const points = prices.map(v => ({
                        x: xScale(DateTime.fromISO(v.Date).toMillis()),
                        y: yScale(v.Close),
                    }))
        
        rt.draw(
            rt.container([
                // Line
                rt.path({
                    points,
                    style: {
                        // strokeStyle: '#74C12B',
                        strokeStyle: '#99FF36',
                    }
                })
            ]),
            cardChart.getContext('2d', { alpha: false })
        )
    },
    methods: {
        xScale(oilPrices: OilPrice[]): ScaleTime<number, number> {
            return scaleTime()
                .domain([
                    DateTime.fromISO(oilPrices[0].Date).toMillis(),
                    DateTime.fromISO(oilPrices.at(-1).Date).toMillis(),
                ])
                .range([
                    0,
                    this.chart.width,
                ])
        },
        yScale(oilPrices: OilPrice[]): ScaleLinear<number, number> {
            const padding = 10
            return scaleLinear()
                .domain([
                    Math.min(...oilPrices.map(o => o.Close)),
                    Math.max(...oilPrices.map(o => o.Close)),
                ])
                .range([
                    this.chart.height - padding,
                    0 + padding,
                ])
        },
    }
})
</script>

<style lang="scss" scoped>
.oilPrice {
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
}

.card {
    width: 150px;
    height: 150px;
    padding: 15px;
    padding-top: 0px;
    display: grid;
    grid-template-rows: 1fr max-content;

    background: #26262C;
    border-radius: 3%;

    &.selected, &:not(.selected):hover {
        filter: brightness(115%)
    }

    &:not(.selected) {
        cursor: pointer; 
    }
}

.cardDetails {
    font-size: 10px;
    color: #73737B;
}

.cardChartContainer {
    display: grid;
    align-content: center;
}

.priceChange {
    font-size: 14px;
    margin-left: 5px;

    .priceChangeIcon {
        font-size: 18px;
        margin-right: -1px;
    }

    &.positive {
        color: #99FF36;
    }
    &.negative {
        color: #FF7718;
    }
 }
</style>