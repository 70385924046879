import { defineStore } from 'pinia'
import Cookies from 'js-cookie'
import { User } from '@auth0/auth0-spa-js'
import { Institution, InvestmentsHoldingsGetResponse } from 'plaid'
import { getAdamPortfolioSymbols, watchSymbols } from '@/client/utils'
import { graphql } from '@/client/graphql'

import { 
    fetchBroker, 
    fetchInvestments, 
    fetchPlaidAccessToken, 
    fetchPlaidLinkToken, 
    fetchSymbolToStockMeta, 
    fetchUser, 
    updateUser,
    auth,
} from '@/client/api'

import {
    AppUser as AppSpecificUser,
 } from '@/common/application/models'

import {
    PricePoint as InnerPricePoint,
    SymbolToStockMeta,
} from '@/common/financials/models'

interface Stock {
    symbol: string
    name: string
    price: number
    yield: number | null
    changePercent: number
    
    openPrice: number
    highPrice: number
    lowPrice: number
    closePrice: number
    closeYield: number
    volume: number
}

type PricePoint = InnerPricePoint & { symbol: string }
type AppUser = (AppSpecificUser & User)
interface AppState {
    user: AppUser | null
    symbolsCsv: string | null
    broker: Institution | null
    investments: InvestmentsHoldingsGetResponse | null
    symbolToStockMeta: SymbolToStockMeta | null
    symbolToLatestPoint: Record<string, PricePoint>
}

export const useStore = defineStore('main', {
    state: (): AppState => ({
        user: null,
        symbolsCsv: 'AAPL, GOOG, MSFT, AMZN',
        broker: null,
        investments: null,
        symbolToStockMeta: null,
        symbolToLatestPoint: {},
    }),
    getters: {
        userSymbols(state): string[] {
            return (
                state?.investments?.securities.map(s => s.ticker_symbol) ||
                (state?.symbolsCsv || '').split(',').map(s => s.trim().toUpperCase()).filter(v => v.length <= 6) // TODO: Make check more robust
            );
        },
        watchedSymbolSet(): Set<string> {
            return new Set(this.userSymbols)
        },
        validWatchedPoints(state): PricePoint[] {
            const { symbolToLatestPoint, symbolToStockMeta } = state
            const watchedSymbolSet = new Set(this.userSymbols)
            
            return Object.values(symbolToLatestPoint)
                    // TODO: filter on backend
                .filter(({ symbol }) => (
                    watchedSymbolSet.has(symbol) && 
                    symbolToStockMeta[symbol] && 
                    symbolToStockMeta[symbol].latestPoint
                ))
        },
        // TODO: filter on backend
        stocks(state): Stock[] {
            const { symbolToStockMeta } = state
            const { validWatchedPoints } = this

            return validWatchedPoints.map(p => {
                const stockMeta = symbolToStockMeta[p.symbol]
                const inverseChangePercent = 1 + -((p.closePrice - stockMeta.latestPoint.closePrice) / stockMeta.latestPoint.closePrice)
                
                return {
                    symbol: p.symbol,
                    name: stockMeta.company.companyName,
                    price: p.closePrice,
                    yield: stockMeta.latestPoint.closeYield ? (stockMeta.latestPoint.closeYield * inverseChangePercent) : null,
                    changePercent: 1 + ((p.closePrice - stockMeta.latestPoint.closePrice) / stockMeta.latestPoint.closePrice),
                    
                    // TODO: These will be tick open... fix
                    openPrice: p.openPrice,
                    highPrice: p.highPrice,
                    lowPrice: p.lowPrice,
                    closePrice: stockMeta.latestPoint.closePrice,
                    closeYield: stockMeta.latestPoint.closeYield,
                    volume: p.volume,
                }
            })
        },
    },
    actions: {
        async initialize() {
            const user = this.user = await getUser();
            
            [
                this.broker,
                this.investments,
                this.symbolsCsv,
                this.symbolToStockMeta,
            ] = await Promise.all([
                getLinkedBroker({ user }),
                getInvestments({ user }),
                Cookies.get('Symbols') || getAdamPortfolioSymbols().join(','),
                fetchSymbolToStockMeta(),
            ])

            watchSymbols({
                // @ts-ignore
                // symbols: this.userSymbols || getAdamPortfolioSymbols(),
                onStockTick: point => {
                    // TODO: do in one move, to prevent multiple update
                    this.symbolToLatestPoint[point.symbol] = point
                }
            })
        },
        updateSymbolsCsv(newSymbolsCsv) {
            this.symbolsCsv = newSymbolsCsv
                                .split(',')
                                .map(s => s.trim().toUpperCase())
                                .filter(v => v.length <= 6)
                                .join(',')
            Cookies.set('Symbols', this.symbolsCsv)
        },
        async login() {
            await auth.loginWithPopup({
                redirect_uri: 'http://localhost:8080/' // TODO: make env specific
            })
            
            this.user = await getUser()
        },
        async logout() {
            await auth.logout({
                returnTo: window.location.origin,
            })
            this.user = null
        },
        async connectUserToPlaid() {
            const newUser = await connectUserToPlaid({
                user: this.user,
            })
            
            this.user = newUser
            
            // Update KV store - TODO: Update as side effect
            updateUser({
                user: newUser,
            }) 
        }
    }
})


async function getUser(): Promise<AppUser | null> {
    const auth0UserInfo = await auth.getUser()
    if (!auth0UserInfo) {
        return null
    }
    
    const userId = auth0UserInfo.sub
    const macroUserMeta = (await fetchUser({ userId })) || {}
    
    return {
        userId,
        ...auth0UserInfo,
        ...macroUserMeta,
    }
}

async function getInvestments({ user }) {
    const plaidAccessToken = user?.plaidAccessToken || null
    if (!plaidAccessToken) {
        return null // Or maybe defaults
    }
    return fetchInvestments({
        plaidAccessToken,
    })
}

async function getLinkedBroker({ user }) {
    if (!user?.plaidAccessToken) {
        return null
    }
    return fetchBroker({ 
        plaidAccessToken: user.plaidAccessToken 
    })
}


async function connectUserToPlaid({ user }): Promise<AppUser> {
    const plaidUserId = user?.userId || crypto.randomUUID()
    
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
        // @ts-ignore
        const handler = window.Plaid.create({
            token: await fetchPlaidLinkToken({ plaidUserId }),
            onSuccess: async (publicToken, metadata) => {
                // TODO: Should update user implicitly, probs
                const newUser = {
                    ...user,
                    plaidAccessToken: await fetchPlaidAccessToken({ 
                        publicToken, 
                        plaidUserId 
                    })
                }

                resolve(newUser)
            },
            
            onExit: (err, metadata) => {
                reject(err)
            },
        })
        
        handler.open()
    })
}
